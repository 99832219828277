<template>
  <div class="loading-spinner" :class="['loading-spinner--layout-' + layout]">
    <div class="loading-spinner__inner">
      <div class="loading-spinner__spinner" :style="style" />
    </div>
    <div v-if="text" class="loading-spinner__text text-2xs text-gray-500 text-center">
      {{ $t(text) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'Loader',
    props: {
      size: {
        type: Number,
        default: 32,
      },
      text: {
        type: String,
        default: '',
      },
      layout: {
        type: String,
        default: 'vertical',
      }
    },
    setup (props) {
      const style = computed(() => {
        return {
          height: props.size + 'px',
          width: props.size + 'px',
        }
      })
      return {
        style,
      }
    }
  })
</script>

<style lang="stylus" scoped>
.loading-spinner
  &--layout-horizontal
    display flex
    flex-direction row
    justify-content center
    align-items center

    .loading-spinner__text
      @apply mt-0 ml-3

  &__inner
    text-align center
    display flex
    justify-content center
    align-items center

  &__spinner
    @apply border border-2 border-gray-200
    border-top-color #9FB3C8
    border-left-color #9FB3C8
    width 32px
    height 32px
    border-radius 50%
    transform rotate(0deg)
    animation spinner 600ms linear infinite

  &__text
    @apply mt-4

@keyframes spinner
  to
    transform rotate(360deg)
</style>
