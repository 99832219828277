<script setup lang="ts">
  import { computed } from 'vue'
  import { useStore } from '@/store'
  import { config } from '@/bootstrap/config'

  const store = useStore()
  const license = computed(() => store.state.license.license)
  const user = computed(() => store.state.user.user)
</script>

<template>
  <footer class="my-8">
    <div class="container">
      <div class="text-center text-xs text-gray-500">
        CareSuite {{ config.version }} &middot;
        <span :class="{'text-white bg-red-500 px-2 py-1 rounded font-bold': license.id === 0}">{{ license.name }}</span>
        <span v-if="user"> &middot; </span>
        <span v-if="user">
          {{ user.name }}
        </span>
      </div>
    </div>
  </footer>
</template>
