import { Notification, NotificationActions, NotificationType } from '@/common/components/notification/Notification.api'

export interface NotifyInput {
  id?: string
  title: string;
  text: string;
  duration?: number;
  persist?: boolean;
  offset?: [number, number];
  actions?: NotificationActions;
  type?: NotificationType;
}

export interface NotificationState {
  notifications: Notification[];
}

const state: NotificationState = {
  notifications: []
}

const getters = {
  active (state: NotificationState) {
    return state.notifications.filter(i => !i.dismissed)
  },
}

const actions = {}

const mutations = {
  notify (state: NotificationState, input: NotifyInput) {
    const id = input.id ? input.id : String(+Date.now())

    // Remove any notification with the same ID from the stack.
    state.notifications = state.notifications.filter(i => i.id !== id)

    state.notifications.push({
      dismissed: false,
      persist: false,
      duration: 5000,
      type: 'none',
      ...input,
      id,
    })
  },
  dismiss (state: NotificationState, dismiss: Notification) {
    state.notifications = state.notifications.map(notification => {
      if (notification.id === dismiss.id) {
        notification.dismissed = true
      }
      return notification
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}