<script setup lang="ts">

  import { useElementSize } from '@vueuse/core'
  import { ref, watchEffect } from 'vue'

  const emit = defineEmits(['resize'])
  defineProps({
    name: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    },
    transform:{
      type: String,
      default: ''
    }
  })

  const wrapper = ref(null)

  const { width, height } = useElementSize(wrapper)

  watchEffect(() => {
    emit('resize', { width, height })
  })
</script>

<template>
  <div class="device">
    <div ref="wrapper" class="device-wrapper">
      <iframe :src="`/app/?storagePartition=${username}&demoClient=true&demoUser=${username}`" />
    </div>
  </div>
</template>

<style scoped>
  .device {
    display: flex;
    width: 100%;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
  }

  .device-wrapper {
    padding: 2rem;
    transform: v-bind(transform);
    transform-origin: 50% 28em;
  }

  .device iframe {
    aspect-ratio: 350 / 700;
    width: 350px;
    border: 2px solid theme('colors.gray.600');
    border-radius: 8px;
    background-color: white;
    @apply shadow;
  }
</style>