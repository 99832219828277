<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { useStore } from '@/store'
  import { useDrag } from 'vue-use-gesture'

  const props = defineProps({
    url: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  })

  const store = useStore()
  const closeVisible = ref(false)
  const streamVisible = ref(false)
  const frame = ref<HTMLElement | null>(null)

  // Show a loader before displaying the stream. The MJPEG stream will be empty while it laods
  // but we cannot use the img's onload event since the stream never finishes loading!
  let loadTimeout: number
  let hideTimeout: number

  onMounted(() => {
    loadTimeout = window.setTimeout(() => streamVisible.value = true, 1000)
    hideTimeout = window.setTimeout(() => closeStream(), 60 * 5 * 1000) // Hide the stream always after 5 minutes.
  })

  function closeStream () {
    clearTimeout(hideTimeout)
    clearTimeout(loadTimeout)
    store.commit('app/removeVideoStream', props.url)
  }

  const streamWidthSingle = 1000
  const streamWidthMultiple = 615

  // Allow the video to be dragged. Use a CSS transform while dragging, then set the left and top values once the drag action stops.
  const position = ref<Record<string, number | undefined>>({ left: undefined, top: undefined })
  const dragged = ref({ x: 0, y: 0 })
  const styles = computed(() => {
    // Keep the original transforms for the enter transition and only apply the values below once the stream is visible.
    let transform = `translate3d(${dragged.value.x}px, ${dragged.value.y}px, 0)`
    if (!streamVisible.value) {
      transform = ''
    }

    const gap = 16

    const width = props.multiple ? streamWidthMultiple : streamWidthSingle
    const right = props.index * (width + gap)

    return {
      transform,
      left: position.value.left !== undefined ? `${position.value.left}px` : 'auto',
      top: position.value.top !== undefined ? `${position.value.top}px` : 'auto',
      bottom: position.value.left === undefined ? '0' : 'auto',
      right: position.value.left === undefined ? `${right}px` : 'auto',
      width: `${width}px`,
      minHeight: props.multiple ? '400px' : '600px'
    }
  })
  // @ts-ignore
  const bind = useDrag(({ movement: [mx, my], active }) => {
    if (active) {
      dragged.value.x = mx
      dragged.value.y = my
    }
  })

  function onPointerUp () {
    // Drag stop.
    if (dragged.value.x !== 0 || dragged.value.y !== 0) {
      position.value.left = frame.value?.getBoundingClientRect().left
      position.value.top = frame.value?.getBoundingClientRect().top
      dragged.value.x = 0
      dragged.value.y = 0
      return
    }
    // Simple mouse up.
    closeVisible.value = true
  }
</script>

<template>
  <div
    ref="frame"
    class="video-stream fixed rounded shadow-lg mr-4 mb-4 md:mr-8 md:mb-8 z-50 bg-white overflow-hidden cursor-move select-none"
    :style="styles"
    @mouseleave="closeVisible = false"
    @mouseenter="closeVisible = true"
    @pointerup="onPointerUp"
    @pointerdown="bind().onPointerDown"
  >
    <div
      v-if="closeVisible"
      class="absolute top-0 right-0 mt-4 mr-4 bg-white bg-opacity-50 w-10 h-10 flex items-center justify-center text-4xl leading-[40px] rounded cursor-pointer z-50"
      @click="closeStream"
    >
      <span class="-mt-1">&times;</span>
    </div>
    <div class="absolute pointer-events-none inset-0 flex items-center justify-center z-0 h-full">
      <Loader />
    </div>
    <img v-if="streamVisible" class="block object-contain w-full pointer-events-none relative z-20" :src="url" alt="">
  </div>
</template>

<style lang="stylus" scoped>
  .video-stream
    touch-action none
    max-width calc(100vw - theme('spacing.16'))
</style>
