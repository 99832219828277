import { createLogger, createStore, Store, useStore as baseUseStore } from 'vuex'
import { InjectionKey } from 'vue'

import modalStack, { ModalState } from './modal.state'
import user, { UserState } from './user.state'
import notifications, { NotificationState } from './notification.state'
import app, { AppState } from './app.state'
import license, { LicenseState } from '@/store/license.state'
import formHistory, { FormHistoryState } from '@/store/formhistory.state'
import systemTree, { SystemTreeState } from '@/store/systemtree.state'
import call, { CallState } from '@/store/call.state'
import { config } from '@/bootstrap/config'

export interface State {
  modalStack: ModalState;
  user: UserState;
  notifications: NotificationState;
  license: LicenseState;
  app: AppState;
  systemTree: SystemTreeState;
  formHistory: FormHistoryState;
  call: CallState;
}

// Set to true to log all state mutations to the console.
const log = false

export const key: InjectionKey<Store<State>> = Symbol()

const plugins = []
if (log && config.environment !== 'production') {
  plugins.push(createLogger())
}

const store = createStore<State>({
  strict: config.environment !== 'production',
  plugins,
  modules: {
    user,
    modalStack,
    app,
    notifications,
    license,
    systemTree,
    formHistory,
    call,
  }
})

export default store

export function useStore (): Store<State> {
  return baseUseStore<State>(key)
}