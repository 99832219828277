<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { CallType, useDemoState } from '@/modules/demo/demo.state'
  import { CheckIcon } from '@heroicons/vue/solid'

  defineProps<{
    label: string,
  }>()

  const emit = defineEmits<{
    (event: 'change', args: { type: CallType, close?: boolean }): void
  }>()

  const { lastReset } = useDemoState()

  const active = ref(false)

  // Reset the call buttons when the demo is reset.
  watch(lastReset, () => {
    active.value = false
  })

  function onPress () {
    emit('change', { type: CallType.Alert })
    active.value = true
  }

  function onMute () {
    emit('change', { type: CallType.Alert, close: true })
    active.value = false
  }
</script>

<template>
  <div>
    <div class="relative p-2">
      <transition
        enterActiveClass="transition ease-out duration-300"
        enterFromClass="opacity-0"
        enterToClass="opacity-100"
        leaveActiveClass="transition ease-in duration-300"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <div
          v-if="active"
          class="absolute cursor-pointer flex items-center justify-center inset-0 z-10 bg-white/30 backdrop-blur-[2px]"
          @click="onMute"
        >
          <div class="rounded-full bg-white hover:bg-gray-100 w-14 h-14 border-2 shadow border-gray-600 flex items-center justify-center">
            <CheckIcon class="w-8 text-gray-700" />
          </div>
        </div>
      </transition>

      <svg
        class="cursor-pointer max-w-full w-60 mx-auto"
        :class="{'animate-shake': active}"
        width="111"
        height="136"
        viewBox="0 0 111 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="onPress"
      >
        <g clip-path="url(#clip0_1707_356)">
          <path d="M23.1242 18.3692C23.2017 21.5214 25.9895 24.0102 29.3512 23.928L66.2771 23.0236C69.6388 22.9408 72.3009 20.319 72.2238 17.1662L71.9385 5.56053C71.8614 2.40832 69.0735 -0.0804386 65.7117 0.00234795L28.7858 0.906166C25.4244 0.988953 22.762 3.61131 22.8393 6.76352L23.1242 18.3692Z" fill="#231F20" />
          <path d="M34.2155 130.864C34.5154 134.004 37.4722 136.29 40.8196 135.97L77.5897 132.465C80.9372 132.146 83.4072 129.343 83.1077 126.204L82.0033 114.647C81.7032 111.508 78.7467 109.222 75.3992 109.541L38.6291 113.046C35.2815 113.365 32.8111 116.169 33.111 119.308L34.2155 130.864Z" fill="#231F20" />
          <mask
            id="mask0_1707_356"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="14"
            width="111"
            height="111"
          >
            <path d="M14.4351 17.9587C10.6206 18.464 -3.28556 24.8101 0.715884 40.2257C4.71733 55.6413 8.0042 67.774 8.0042 75.6245C8.0042 83.475 6.57511 100.318 6.57511 104.172C6.57511 108.026 7.86129 124.012 24.2958 124.155C40.7303 124.298 93.0347 118.303 99.8943 115.163C106.754 112.022 113.756 108.882 109.898 89.8982C106.039 70.9142 100.895 49.9318 102.324 36.3718C103.753 22.8117 94.6067 15.8176 87.0325 15.2467C81.1744 14.8053 40.3015 14.533 14.4351 17.9587Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1707_356)">
            <path d="M14.4351 17.9587C10.6206 18.464 -3.28556 24.8101 0.715884 40.2257C4.71733 55.6413 8.0042 67.774 8.0042 75.6245C8.0042 83.475 6.57511 100.318 6.57511 104.172C6.57511 108.026 7.86129 124.012 24.2958 124.155C40.7303 124.298 93.0347 118.303 99.8943 115.163C106.754 112.022 113.756 108.882 109.898 89.8982C106.039 70.9142 100.895 49.9318 102.324 36.3718C103.753 22.8117 94.6067 15.8176 87.0325 15.2467C81.1744 14.8053 40.3015 14.533 14.4351 17.9587Z" fill="url(#paint0_linear_1707_356)" />
          </g>
          <mask
            id="mask1_1707_356"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="37"
            y="41"
            width="47"
            height="46"
          >
            <path d="M83.3169 64.0154C83.3169 76.655 73.0372 86.9006 60.3566 86.9006C47.6756 86.9006 37.3958 76.655 37.3958 64.0154C37.3958 51.3763 47.6756 41.13 60.3566 41.13C73.0372 41.13 83.3169 51.3763 83.3169 64.0154Z" fill="white" />
          </mask>
          <g mask="url(#mask1_1707_356)">
            <path d="M83.3169 64.0154C83.3169 76.655 73.0372 86.9006 60.3566 86.9006C47.6756 86.9006 37.3958 76.655 37.3958 64.0154C37.3958 51.3763 47.6756 41.13 60.3566 41.13C73.0372 41.13 83.3169 51.3763 83.3169 64.0154Z" fill="url(#paint1_linear_1707_356)" />
          </g>
          <mask
            id="mask2_1707_356"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="19"
            y="26"
            width="81"
            height="80"
          >
            <path d="M60.1657 86.6152C47.6955 86.6152 37.5863 76.5186 37.5863 64.0628C37.5863 51.6075 47.6955 41.5103 60.1657 41.5103C72.6365 41.5103 82.7453 51.6075 82.7453 64.0628C82.7453 76.5186 72.6365 86.6152 60.1657 86.6152ZM98.2749 81.6674C97.1316 79.5738 89.2242 67.203 87.7951 64.6337C86.366 62.0645 86.7467 60.5423 87.0325 59.6859C87.3183 58.8295 91.1294 47.9814 92.7489 43.3185C94.3689 38.6556 92.654 37.609 91.7011 36.7526C90.7481 35.8962 83.6027 29.3303 81.0304 27.4273C78.458 25.5238 75.9811 26.2854 74.9333 26.8563C73.8849 27.4273 61.7857 34.6589 59.785 36.1816C57.7843 37.7044 56.8314 37.3235 55.1165 36.7526C53.9038 36.3489 42.4452 32.0897 37.2051 29.5204C31.9653 26.9511 29.7739 29.0448 28.8213 30.0913C27.8685 31.1385 23.4859 36.0863 20.5326 39.7975C17.5791 43.5086 19.8656 46.2686 20.5326 47.3152C21.1994 48.3617 30.4409 61.5889 32.0604 63.3017C33.6802 65.0146 33.5849 66.3466 32.9179 68.1548C32.2511 69.963 27.2015 81.9528 25.1057 86.6152C23.0096 91.2783 25.0103 93.6573 25.9631 94.704C26.9157 95.7504 33.7753 101.46 37.0147 103.934C40.254 106.408 42.4452 104.981 43.6838 104.22C44.9222 103.458 58.7366 94.323 61.4044 92.3247C64.0722 90.3264 65.882 91.659 67.0253 92.1347C68.1686 92.6102 77.5057 96.9878 82.7453 99.4616C87.9854 101.936 89.6049 99.557 90.3674 98.5104C91.1294 97.4632 97.4174 89.6607 98.8465 87.4716C100.276 85.2833 99.4181 83.7605 98.2749 81.6674Z" fill="white" />
          </mask>
          <g mask="url(#mask2_1707_356)">
            <path d="M60.1657 86.6152C47.6955 86.6152 37.5863 76.5186 37.5863 64.0628C37.5863 51.6075 47.6955 41.5103 60.1657 41.5103C72.6365 41.5103 82.7453 51.6075 82.7453 64.0628C82.7453 76.5186 72.6365 86.6152 60.1657 86.6152ZM98.2749 81.6674C97.1316 79.5738 89.2242 67.203 87.7951 64.6337C86.366 62.0645 86.7467 60.5423 87.0325 59.6859C87.3183 58.8295 91.1294 47.9814 92.7489 43.3185C94.3689 38.6556 92.654 37.609 91.7011 36.7526C90.7481 35.8962 83.6027 29.3303 81.0304 27.4273C78.458 25.5238 75.9811 26.2854 74.9333 26.8563C73.8849 27.4273 61.7857 34.6589 59.785 36.1816C57.7843 37.7044 56.8314 37.3235 55.1165 36.7526C53.9038 36.3489 42.4452 32.0897 37.2051 29.5204C31.9653 26.9511 29.7739 29.0448 28.8213 30.0913C27.8685 31.1385 23.4859 36.0863 20.5326 39.7975C17.5791 43.5086 19.8656 46.2686 20.5326 47.3152C21.1994 48.3617 30.4409 61.5889 32.0604 63.3017C33.6802 65.0146 33.5849 66.3466 32.9179 68.1548C32.2511 69.963 27.2015 81.9528 25.1057 86.6152C23.0096 91.2783 25.0103 93.6573 25.9631 94.704C26.9157 95.7504 33.7753 101.46 37.0147 103.934C40.254 106.408 42.4452 104.981 43.6838 104.22C44.9222 103.458 58.7366 94.323 61.4044 92.3247C64.0722 90.3264 65.882 91.659 67.0253 92.1347C68.1686 92.6102 77.5057 96.9878 82.7453 99.4616C87.9854 101.936 89.6049 99.557 90.3674 98.5104C91.1294 97.4632 97.4174 89.6607 98.8465 87.4716C100.276 85.2833 99.4181 83.7605 98.2749 81.6674Z" fill="url(#paint2_linear_1707_356)" />
          </g>
          <mask
            id="mask3_1707_356"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="33"
            y="85"
            width="12"
            height="12"
          >
            <path d="M44.1601 90.8025C44.1601 93.8509 41.686 96.3213 38.6342 96.3213C35.5825 96.3213 33.1086 93.8509 33.1086 90.8025C33.1086 87.7541 35.5825 85.2833 38.6342 85.2833C41.686 85.2833 44.1601 87.7541 44.1601 90.8025Z" fill="white" />
          </mask>
          <g mask="url(#mask3_1707_356)">
            <path d="M44.1601 90.8025C44.1601 93.8509 41.686 96.3213 38.6342 96.3213C35.5825 96.3213 33.1086 93.8509 33.1086 90.8025C33.1086 87.7541 35.5825 85.2833 38.6342 85.2833C41.686 85.2833 44.1601 87.7541 44.1601 90.8025Z" fill="url(#paint3_linear_1707_356)" />
          </g>
          <path d="M42.2547 90.8025C42.2547 92.7997 40.6338 94.4185 38.6342 94.4185C36.6348 94.4185 35.0139 92.7997 35.0139 90.8025C35.0139 88.8053 36.6348 87.1861 38.6342 87.1861C40.6338 87.1861 42.2547 88.8053 42.2547 90.8025Z" fill="#A7A5A6" />
          <mask
            id="mask4_1707_356"
            style="mask-type:luminance"
            maskUnits="userSpaceOnUse"
            x="48"
            y="51"
            width="26"
            height="25"
          >
            <path d="M73.3133 63.7773C73.3133 70.5048 67.8536 75.9579 61.1186 75.9579C54.3834 75.9579 48.9236 70.5048 48.9236 63.7773C48.9236 57.0504 54.3834 51.5972 61.1186 51.5972C67.8536 51.5972 73.3133 57.0504 73.3133 63.7773Z" fill="white" />
          </mask>
          <g mask="url(#mask4_1707_356)">
            <path d="M73.3133 63.7773C73.3133 70.5048 67.8536 75.9579 61.1186 75.9579C54.3834 75.9579 48.9236 70.5048 48.9236 63.7773C48.9236 57.0504 54.3834 51.5972 61.1186 51.5972C67.8536 51.5972 73.3133 57.0504 73.3133 63.7773Z" fill="url(#paint4_radial_1707_356)" />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1707_356"
            x1="3.28454e-06"
            y1="69.5742"
            x2="110.998"
            y2="69.5742"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E9E9E9" />
            <stop offset="1" stop-color="#D3D2D2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1707_356"
            x1="41.8671"
            y1="75.1603"
            x2="81.4854"
            y2="51.2223"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4068BB" />
            <stop offset="0.489247" stop-color="#355AB1" />
            <stop offset="1" stop-color="#273C7E" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1707_356"
            x1="80.1032"
            y1="41.8272"
            x2="38.0481"
            y2="88.1243"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4068BB" />
            <stop offset="1" stop-color="#355AB1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1707_356"
            x1="33.4782"
            y1="93.1503"
            x2="43.192"
            y2="88.7166"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4068BB" />
            <stop offset="0.607527" stop-color="#355AB1" />
            <stop offset="1" stop-color="#273C7E" />
          </linearGradient>
          <radialGradient
            id="paint4_radial_1707_356"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(67.9782 63.0162) rotate(-174.263) scale(16.1821 16.1631)"
          >
            <stop stop-color="#4068BB" />
            <stop offset="1" stop-color="#355AB1" />
          </radialGradient>
          <clipPath id="clip0_1707_356">
            <rect width="111" height="136" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <p class="mt-2 text-center text-sm text-csBlue-800 font-semibold leading-none">
      {{ label }}
    </p>
  </div>
</template>

<style scoped>
  .animate-shake {
    animation: shake 0.75s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0) rotate(5deg);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0) rotate(-5deg);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>