<template>
  <transition name="popup">
    <Popup v-if="visible" :title="$t('changelog.popups.getsHTTPError.title')" @close="visible = false">
      <Callout type="error" :text="$t('changelog.popups.getsHTTPError.error')">
        <p>{{ $t('changelog.popups.getsHTTPError.do_sync') }}</p>
        <a
          v-if="!showError"
          class="mt-4 inline-block text-2xs border-b border-red-600 text-red-600 cursor-pointer"
          @click="showError = true"
        >
          {{ $t('common.errors.details_show') }}
        </a>
      </Callout>
      <div class="overflow-hidden" :class="{'max-h-0': !showError, 'mt-4': showError}">
        <pre
          class="font-mono bg-gray-100 shadow-inner p-2 border border-gray-300 mt-2 rounded text-2xs overflow-y-auto"
          style="max-height: 200px"
        >{{ info }}</pre>
      </div>
      <template #actions>
        <ActionButton @click="visible = false">
          {{ $t(`common.actions.ignore`) }}
        </ActionButton>
        <ActionButton class="submit" type="danger" @click="$router.push({name: 'sync'}); visible = false">
          {{ $t(`changelog.popups.getsHTTPError.actions.primary`) }}
        </ActionButton>
      </template>
    </Popup>
  </transition>
</template>

<script lang="ts">
  import Popup from '@/common/components/popup/Popup.vue'
  import GetsHttpError from '@/modules/device/graphql/SubscriptionGetsHTTPError.gql'
  import { useSubscription } from '@vue/apollo-composable'
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from '@/store'
  import Callout from '@/common/components/callout/Callout.vue'

  export default defineComponent({
    name: 'GetsHTTPErrorPopup',
    components: { Popup, Callout },
    setup () {
      const visible = ref(false)
      const showError = ref(false)
      const info = ref({ url: '', payload: '', checks: [], action: '' })
      const route = useRoute()
      const store = useStore()
      const user = computed(() => store.state.user.user)

      const { result } = useSubscription(GetsHttpError)
      watch(() => result.value, data => {
        // Do not show sync errors when the display route is active.
        if (route.name === 'display' || Number(user.value?.id) === 0) {
          visible.value = false
          return
        }
        info.value = data.getsHTTPError
        visible.value = true
      })

      watch(() => visible, (newV) => {
        if (newV.value === false) {
          showError.value = false
        }
      })

      return {
        info,
        visible,
        showError,
      }
    }
  })
</script>

<style lang="stylus">
.http-error-popup .loading-spinner
  margin 2rem 0 !important
</style>
