import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { GqlErrorResponse, useConditionalMutation, useConditionalQuery } from '@/common/graphql/graphql.api'
import { computed, ComputedRef, PropType, Ref, ref, watch, watchEffect } from 'vue'
import { DocumentNode } from 'graphql'
import { useStore } from '@/store'
import { logWarning, randomString } from '@/common/utils'
import { useI18n } from 'vue-i18n'
import { Maybe } from '@/common/graphql/types'
import { ApolloError, DataProxy, FetchPolicy, FetchResult } from '@apollo/client/core'

// NewRecordId is used to indicate that a new record is being created.
export const NewRecordId = -1

// FormError represents a validation error message.
export interface FormError {
  message: string;
  data?: Record<string, unknown>;
}

// FormErrorsCollection is an object that contains a FormError array for every
// field in a given form. It is keyed by the form field's name/id prop.
export interface FormErrorsCollection {
  [key: string]: FormError[];
}

// FormInputProps are the default props given to any form input.
export interface FormInputProps {
  // If a id is specified, it will be used for error retrieval and for the
  // label's "for" attributes. The name property is used as fallback value
  id: string;
  // value is the actual field value
  value: any;
  // label is the field's label text
  label: string;
  // containerClass is set on the surrounding div element of the input
  containerClass: string;
  // inputClass is set on the input itself
  inputClass: string;
  // The name attribute is required since it is used for the
  // "for/id" attributes as well as the error comment key
  name: string;
  // comment will be displayed below the input field
  comment: string;
  // type is the input elements type
  type: string;
  // help contains the help code for this input
  help: string;
  // disabled if field is disabled or active
  disabled: boolean;
  // indicates a loading field
  loading: boolean;
  // errors contains all current form validation errors
  errors: FormErrorsCollection;
}

export interface FormCheckboxProps extends FormInputProps {
  checked: any;
}

export interface FormDropdownProps extends FormInputProps {
  options: FormDropdownOption[];
  multiSelect: boolean;
  enableAutocomplete: boolean;
  placeholder: string;
}

export interface FormDropdownOption {
  value: string | number | null;
  label: string;
  meta?: Record<any, unknown>
}

// toDropdownOptions turns an array of values into a dropdown selection array.
export const toFormDropdownOptions = <T extends Record<string, any>> (values: ReadonlyArray<T>, opts: { value: keyof T; label: keyof T | ((item: T) => string); nullLabel?: string; nullValue?: any }): FormDropdownOption[] => {
  const options: FormDropdownOption[] = values.map(t => {
    let label = ''
    if (typeof opts.label === 'function') {
      label = opts.label(t)
    } else {
      label = t[opts.label]
    }
    return {
      value: t.hasOwnProperty(opts.value) ? t[opts.value] : '',
      label
    }
  })
  if (!opts.nullLabel) {
    return options
  }
  const nullValue: FormDropdownOption[] = [{
    label: opts.nullLabel,
    value: opts.nullValue,
  }]
  return nullValue.concat(options)
}

// defaultFormInputProps is the default base property definition for every
// form input. These props can be extended for input types that require
// additional properties.
export const defaultFormInputProps = {
  id: [String, Number],
  modelValue: { type: [String, Array, Number, Boolean] as PropType<string|number|boolean|undefined|Array<string|number>|Maybe<string|number>>, default: '' },
  label: String,
  containerClass: String,
  help: String,
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  name: { required: true, type: String },
  comment: { type: String, default: '' },
  errors: {
    type: Object as PropType<FormErrorsCollection>, default: () => {
      return {}
    }
  },
}

// the defaultFormProps are passed to Form components.
export function defaultFormProps<T> () {
  return {
    loading: {
      type: Object as PropType<FormLoadingState>,
      default: { any: false, query: false, deletion: false, update: false, relations: false },
    },
    errors: {
      type: Object as PropType<FormViewErrors>,
      default: () => ({}),
    },
    copying: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number] as PropType<string|number>,
      default: () => 0,
    },
    formData: {
      type: Object as PropType<Readonly<Partial<T>>>,
      required: true,
      default: () => ({})
    },
  }
}

// getError extracts an error message for a specific field from a FormErrorsCollection.
export const getError = (errors: FormErrorsCollection, field: string): string => {
  const locale = useI18n()
  if (!errors.hasOwnProperty(field)) {
    return ''
  }
  const item = errors[field][0]
  if (!item) {
    return ''
  }
  return locale.t(item.message, item.data as Record<string, unknown>)
}

// useFormProps handles the merging of css classes and overrides of
// comments and validation errors for any given form input.
export const useFormProps = (props: FormInputProps) => {
  // Use id if given, fall back to name.
  const realId = computed<string>(() => props.id ? props.id : props.name)
  // Get the first form error for this field.
  const error = computed<string>(() => getError(props.errors, realId.value))
  // Generates a unique ID for this input. This is useful if the same form element
  // is rendered twice on the same screen.
  const uniqueId = computed<string>(() => realId.value + '_' + randomString(3))
  // Use error as comment if present, otherwise fall back to provided comment.
  const realComment = computed<string>(() => error.value ? error.value : props.comment)
  // Set the danger class if an error is present. Also merge in all provided container classes.
  const mergedContainerClasses = computed(() => [{
    danger: error.value.length > 0,
    disabled: props.disabled,
    loading: props.loading
  }, props.containerClass])
  return {
    realId,
    uniqueId,
    realComment,
    mergedContainerClasses,
    error,
  }
}

// ToggleButtonOption is an option for a FormRadioToggle component.
export interface ToggleButtonOption {
  label: string;
  value: string;
  activeClasses?: string;
}

// cleanFormData removes any unwanted fields from form data (like __typename).
// This makes it easy to re-use graphql response data for the mutation.
export function cleanFormData (data: any, opts?: { exclude?: string[] }) {
  const cleanFn = (data: any) => {
    if (typeof data !== 'object' || data === null) {
      return data
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename: _, ...cleaned } = data
    for (const prop in cleaned) {
      opts?.exclude?.forEach(field => {
        delete cleaned[field]
      })
      if (Array.isArray(cleaned[prop])) {
        cleaned[prop] = cleaned[prop].map((d: any) => cleanFn(d))
      }
    }
    return cleaned
  }
  return cleanFn({ ...data })
}

export interface FormLoadingState {
  query: boolean;
  deletion: boolean;
  update: boolean;
  relations: boolean;
  any: boolean;
}

interface CustomProperties {
  [key: string]: any;
}

interface FormDataOpts<T> {
  exclude?: string[];
  defaults: Partial<T> & CustomProperties;
  transform?: (data: Partial<T> & CustomProperties) => Partial<T> & CustomProperties;
  loaded?: (data: Partial<T> & CustomProperties) => void;
}

// useFormData helps to handle form data for form components.
export const useFormData = <T> (formData: Ref<Partial<T>>, loading: Ref<FormLoadingState>, emit: CallableFunction|unknown, opts: FormDataOpts<T> = { defaults: {} }) => {
  // track the first loading cycle. During the first load we return an empty data set
  // so the form appears as empty while loading.
  const hasLoaded = ref(false)
  // carry is the data that is carried between subsequent form submits.
  // at first, it contains the initial form data, then it is overridden
  // with the modified form data.
  let carry: Partial<T> & CustomProperties = { ...formData.value }
  // data is the object that is provided to the form elements. Changes to
  // the form elements will change this data structure.
  const data = ref<Partial<T> & CustomProperties>({})
  // output is the cleaned up form data ready for submission.
  const output = computed(() => cleanFormData(data.value, { exclude: opts.exclude }))
  // emptyObject contains all keys from the defaults field but the value is set to null.
  const emptyObject = computed<Partial<T> & CustomProperties>(() => {
    const ret: any = {}
    Object.keys(opts.defaults).forEach((key: string) => ret[key] = null)
    return ret
  })
  // This watcher sets the current data value depending on the loading
  // state. While the data is loading it will be empty. Otherwise,
  // it uses the defaults and extends it using the current carry data.
  // This makes sure the form data remains the same even if the parent
  // component overrides is using the formData prop. Without this watcher
  // we would either not be notified about new data once it was
  // fetched from the server (from {} => {data}) or the form data
  // would permanently be overridden by the original server values
  // every time the component gets re-rendered.
  watchEffect(() => {
    // Don't update the data while the update query is sent.
    if (loading.value.update) {
      return
    }
    if (loading.value.query && !hasLoaded.value) {
      // @ts-ignore
      data.value = emptyObject.value
      return
    }
    if (!hasLoaded.value) {
      // @ts-ignore
      carry = { ...formData.value }
    }
    hasLoaded.value = true
    let newData = { ...opts.defaults, ...carry }
    if (opts.transform) {
      newData = opts.transform(newData)
    }
    if (opts.loaded) {
      opts.loaded(newData)
    }
    // @ts-ignore
    data.value = newData
    // @ts-ignore
    carry = data.value
  })

  // reset allows the form data to change after it has been initially loaded.
  function reset (setLoading = true) {
    hasLoaded.value = false
    loading.value.query = setLoading
  }

  function refetch () {
    reset()
    if (typeof emit === 'function') {
      emit('refetch')
    }
  }

  return {
    data,
    output,
    reset,
    refetch,
  }
}

interface FormViewOpts<T> {
  form: DocumentNode;
  formVars?: Record<string, any>
  fetchPolicy?: FetchPolicy;
  list: DocumentNode;
  listVariables?: Record<string, any>;
  listCache?: keyof T;
  create?: DocumentNode;
  update?: DocumentNode;
  delete?: DocumentNode;
  relations?: DocumentNode;
  relationsVars?: Record<string, any>;
  relationsFetchPolicy?: FetchPolicy;
  doneRoute?: RouteLocationRaw;
  keepId?: boolean; // keepId in the form data even for create requests (is used with CallTypes)
  onCreateCacheUpdateFn?: (cache: DataProxy, list: any, form: Record<string, any>, data: Record<string, any>, changed: any, opts: FormViewOpts<T>) => void;
  onUpdateCacheUpdateFn?: (cache: DataProxy, list: any, form: Record<string, any>, data: Record<string, any>, changed: any, opts: FormViewOpts<T>) => void;
  onDeleteCacheUpdateFn?: (cache: DataProxy, list: any, form: Record<string, any>, data: Record<string, any>, changed: any, opts: FormViewOpts<T>) => void;
  onDoneMutation?: (data: Record<string, unknown>, originalResult: FetchResult<unknown, Record<string, any>, Record<string, any>>) => void;
  onErrorMutation?: (graphQLErrors: Record<string, unknown>) => void;
  // skipCache skips all cache update functions
  skipCache?: boolean
}

// useFormView returns all required properties for a form view (query + mutation).
export const useFormView = <TFormQuery,
  TListQuery extends Record<string, Record<string, unknown>[] | string | undefined>,
  TRelationsQuery = Record<string, unknown>,
  TCreateMutation = any,
  TUpdateMutation = any,
  TDeleteMutation = any> (opts: FormViewOpts<TListQuery>) => {
  const router = useRouter()
  const store = useStore()
  const i18n = useI18n()
  const route = useRoute()
  const id = ref<number>(route.params?.id ? Number(route.params.id) : NewRecordId)
  const isUpdate = computed(() => id.value !== null && id.value !== undefined && id.value != NewRecordId)
  const copying = ref(false)
  const loading = computed<FormLoadingState>(() => ({
    query: queryLoading.value,
    update: updateLoading.value || createLoading.value,
    relations: relationsLoading.value,
    deletion: deleteLoading.value,
    any: queryLoading.value || createLoading.value || updateLoading.value || relationsLoading.value || deleteLoading.value
  }))
  const refFormVars = ref(opts.formVars ? opts.formVars : {})

  const originalRoute = route.name
  watch(() => route, newRoute => {
    // Only update the ID if we are still on the same form. Without this check
    // all open forms (in the modal stack) would always update to the same ID
    // of the form that has been opened last. This is not what we want!
    if (newRoute.name != originalRoute) {
      return
    }
    if (typeof newRoute.params?.id === 'string') {
      id.value = newRoute.params?.id ? Number(newRoute.params?.id) : 0
    }
  })

  //
  // Query (only run for edits)
  //
  const {
    result: queryResult,
    loading: queryLoading,
    error: queryError,
    refetch: queryRefetch,
    onResult: onResultQuery,
  } = useConditionalQuery<TFormQuery>(isUpdate.value ? opts.form : undefined, () => ({ id: id.value, ...refFormVars.value }), {
    enabled: isUpdate,
    fetchPolicy: opts.fetchPolicy ?? 'cache-and-network'
  })

  // reset any errors if a result was received.
  onResultQuery(() => {
    // @ts-ignore
    queryError.value = null
  })

  //
  // Relations
  //
  const {
    result: resultRelations,
    loading: relationsLoading,
    error: relationsError,
    refetch: refetchDataRelations,
    onResult: onResultRelations
  } = useConditionalQuery<TRelationsQuery>(opts.relations, opts.relationsVars ?? {}, {
    fetchPolicy: opts.relationsFetchPolicy ?? 'cache-first'
  })

  const result = computed<TFormQuery & TRelationsQuery>(() => {
    return { ...queryResult.value, ...resultRelations.value } as TFormQuery & TRelationsQuery
  })

  // reset any errors if a result was received.
  onResultRelations(() => {
    // @ts-ignore
    relationsError.value = null
  })

  // Try again to load the requested data.
  async function refetch () {
    // @ts-ignore
    queryError.value = null
    queryLoading.value = true
    // @ts-ignore
    relationsError.value = null
    relationsLoading.value = true

    await Promise.all([
      queryRefetch(),
      refetchDataRelations(),
    ])

    queryLoading.value = false
    relationsLoading.value = false
  }

  //
  // Create
  //
  const {
    mutate: createMutation,
    loading: createLoading,
    error: createError,
    onError: onCreateError,
    onDone: onCreateDone
  } = useConditionalMutation<TCreateMutation>(opts.create, {
    update: (cache: DataProxy, result: FetchResult<Record<string, any>>) => {
      if (opts.skipCache === true) {
        return
      }
      const input = result.data
      if (!input) {
        return
      }
      try {
        // For inserts: fetch the relevant data from the response and put it into the cache.
        const mutation = Object.keys(input)[0]
        const form = input[mutation]
        store.commit('formHistory/addCreated', form)

        const data = cache.readQuery<TListQuery>({ query: opts.list, variables: (opts.listVariables ?? {}) })
        if (!data || typeof data !== 'object' || !opts.listCache || !data.hasOwnProperty(opts.listCache)) {
          return
        }
        const list = data[opts.listCache]
        if (Array.isArray(list)) {
          if (opts.onCreateCacheUpdateFn) {
            opts.onCreateCacheUpdateFn(cache, list, form, data, input, opts)
          } else {
            const updatedList = [ ...list, form ]
            cache.writeQuery({ query: opts.list, data: { ...data, [opts.listCache]: updatedList } })
          }
        }
      } catch (e) {
        logWarning('failed to update cache on insert:', e)
      }
    },
  })
  onCreateError(mutationError)
  onCreateDone(result => done(result))

  //
  // Update
  //
  const {
    mutate: updateMutation,
    loading: updateLoading,
    error: updateError,
    onError: onUpdateError,
    onDone: onUpdateDone
  } = useConditionalMutation<TUpdateMutation>(opts.update, {
    update: (cache: DataProxy, result: FetchResult<Record<string, any>>) => {
      if (opts.skipCache === true) {
        return
      }
      const input = result.data
      if (!input) {
        return
      }
      try {
        const mutation = Object.keys(input)[0]
        const form = input[mutation]
        store.commit('formHistory/addUpdated', form)

        const data = cache.readQuery<TListQuery>({ query: opts.list, variables: (opts.listVariables ?? {}) })
        if (!data || typeof data !== 'object' || !opts.listCache || !data.hasOwnProperty(opts.listCache)) {
          return
        }
        const list = data[opts.listCache]
        if (Array.isArray(list) && opts.onUpdateCacheUpdateFn) {
          opts.onUpdateCacheUpdateFn(cache, list, form, data, input, opts)
        }
      } catch (e) {
        logWarning('failed to update cache on update:', e)
      }
    }
  })

  onUpdateError(mutationError)
  onUpdateDone(result => done(result))

  //
  // Delete
  //
  const confirmPopup = ref(false)
  const {
    mutate: deleteMutation,
    loading: deleteLoading,
    error: deleteError,
    onDone: onDeleteDone
  } = useConditionalMutation<TDeleteMutation>(opts.delete, {
    update: (cache: DataProxy, result: FetchResult<Record<string, any>>) => {
      if (opts.skipCache === true) {
        return
      }
      const input = result.data
      if (!input) {
        return
      }
      try {
        const mutation = Object.keys(input)[0]
        const form = input[mutation]
        store.commit('formHistory/addDeleted', form)

        const data = cache.readQuery<TListQuery>({ query: opts.list, variables: (opts.listVariables ?? {}) })
        if (typeof data !== 'object' || !opts.listCache) {
          return
        }
        if (!data || !data.hasOwnProperty(opts.listCache)) {
          return
        }
        const list = data[opts.listCache]
        if (Array.isArray(list)) {
          if (opts.onDeleteCacheUpdateFn) {
            opts.onDeleteCacheUpdateFn(cache, list, form, data, input, opts)
          } else {
            // Make a copy so we can modify it.
            let newList = [...list]

            // Remove the deleted items from the list.
            form.forEach((removed: any) => {
              // remove the element from the list
              newList = newList.filter((i: any) => i?.id !== removed?.id)
            })

            // Write the new list to the cache.
            cache.writeQuery({ query: opts.list, data: { ...data, [opts.listCache]: newList } })
          }
        }
      } catch (e) {
        logWarning('failed to update cache on delete:', e)
      }
    },
  })

  // onDeleteDone redirects the user back to the specified route.
  onDeleteDone(result => done(result))

  //
  // Utilities
  //

  // errors contains all validation, query and mutation errors.
  const errors = ref({
    form: {},
    query: computed(() => queryError.value || relationsError.value),
    mutation: computed(() => createError.value || updateError.value || deleteError.value),
  })

  // done is called once all processing was successful.
  async function done (result: FetchResult<unknown, Record<string, any>, Record<string, any>>) {
    errors.value.form = {}
    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.update.success.title'),
      text: i18n.t('common.notifications.update.success.text'),
      type: 'success'
    })
    if (opts.onDoneMutation) {
      let data: Record<string, unknown> = {}
      if (result.data !== null && typeof result.data === 'object') {
        type ReturnedData = Record<string, Record<string, unknown>>
        const returned = result.data as ReturnedData
        const firstKey: keyof ReturnedData = Object.keys(returned)?.[0]
        data = returned[firstKey]
      }
      opts.onDoneMutation(data, result)
    }
    copying.value = false
    if (opts.doneRoute) {
      router.push(opts.doneRoute)
    }
  }

  // mutationError handles errors for create and update mutations.
  function mutationError (error: any) {
    const { graphQLErrors } = error
    if (opts.onErrorMutation) {
      opts.onErrorMutation(graphQLErrors)
    }
    errors.value.form = transformErrors(graphQLErrors)
    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.update.error.title'),
      text: i18n.t('common.notifications.update.error.text'),
      type: 'error'
    })
  }

  // submit calls the mutation with the provided data.
  async function submit (data: any) {
    // Ignore form submit events here. They might be triggered
    // by Vue when pressing enter in a form field.
    if (data.target && data.type === 'submit') {
      return
    }

    if (copying.value || !isUpdate.value) {
      if (!opts.keepId) {
        delete data.id
      }
      // @ts-ignore
      await createMutation({ data })
    } else {
      // @ts-ignore
      await updateMutation({ data })
    }
  }

  // copy sets the entry's ID to 0 so the form switches to create mode.
  function copy () {
    id.value = NewRecordId
    copying.value = true
  }

  // remove calls the delete mutation.
  async function remove () {
    // @ts-ignore
    await deleteMutation({ id: [id.value] })
    confirmPopup.value = false
  }

  return {
    result,
    refetch,
    errors,
    loading,
    confirmPopup,
    remove,
    copy,
    copying,
    id,
    submit,
  }
}

// FormViewErrors is passed down a form component and contains all required error information
// in a single object (form validation, mutation and query)
export interface FormViewErrors {
  form: FormErrorsCollection;
  mutation: ComputedRef<ApolloError | null> | null | ApolloError;
  query: ComputedRef<ApolloError | null> | null | ApolloError;
}

// transformErrors takes in a GraphQL response object and extracts a FormErrorsCollection from it.
// The FormErrorsCollection can be passed to any form element to display validation errors.
export const transformErrors = (response: GqlErrorResponse[]): FormErrorsCollection => {
  const errors: FormErrorsCollection = {}
  response.forEach(error => {
    if (!error?.extensions?.validation) {
      return
    }
    const field = error?.extensions?.field
    const nextError = {
      message: error.message,
      data: error?.extensions?.data ? error?.extensions?.data : {},
    }
    if (!errors.hasOwnProperty(field)) {
      errors[field] = []
    }
    errors[field].push(nextError)
  })
  return errors
}
