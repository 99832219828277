<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    text: {
      type: String,
      required: true
    },
    highlight: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 50
    }
  })

  const segments = computed(() => {
    if (!props.text || !props.highlight) {
      return { start: props.text, match: '', end: '' }
    }

    const highlightPosition = props.text?.toLocaleLowerCase()?.indexOf(props.highlight.toLowerCase())
    if (highlightPosition === -1) {
      return { start: props.text, match: '', end: '' }
    }

    const match = props.text.slice(highlightPosition, highlightPosition + props.highlight.length)

    let start = props.text.slice(0, highlightPosition)
    let end = props.text.slice(highlightPosition + props.highlight.length)

    if (start.length + end.length > props.maxLength) {
      const half = props.maxLength / 2
      if (start.length > half) {
        start = '...' + start.slice(start.length - half, start.length)
      }
      if (end.length > half) {
        end = end.slice(0, half) + '...'
      }
    }

    return {
      start,
      match,
      end
    }
  })

</script>

<template>
  <span>
    <span>{{ segments.start }}</span>
    <mark v-if="segments.match">{{ segments.match }}</mark>
    <span v-if="segments.end">{{ segments.end }}</span>
  </span>
</template>