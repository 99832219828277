import { SystemTreeView } from '@/common/graphql/types'
import { User } from '@/modules/user/models/UserModel'

export interface ViewConfig {
  id: string
  is_enabled: boolean
  name: string
  is_system_view: boolean
  filters: Record<string, any>
  apply_permissions: boolean;
  can_edit: boolean;
  role_permissions: Record<string, string>
}

export interface SystemTreeState {
  views: ViewConfig[]
}

const state: SystemTreeState = {
  views: []
}

const getters = {
  getAvailableViews (state: SystemTreeState, rootGetters: any, rootState: any) {
    const user: User = rootState.user.user

    let available = state.views.filter(view => view.is_enabled)

    if (!user.is_superuser) {
      available = available.filter(view => {
        if (!view.apply_permissions) {
          return true
        }

        return user.roles.some(roleId => ['read', 'write'].includes(view.role_permissions[roleId] ?? 'write'))
      }).map(view => {
        return {
          ...view,
          can_edit: !view.apply_permissions || user.roles.some(roleId => ['write'].includes(view.role_permissions[roleId] ?? 'write'))
        }
      })
    }

    return available
  }
}
const actions = {}
const mutations = {
  setViews (state: SystemTreeState, views: SystemTreeView[]) {
    const hasEnabledView = views.some(view => view.is_enabled)
    state.views = views
      .map(view => {
        const filters: Record<string, any> = {}
        for (const filter of view.filters) {
          filters[filter.key] = filter.value
        }

        const rolePermissions: Record<string, string> = {}
        for (const rolePermission of view.role_permissions) {
          rolePermissions[rolePermission.role_id] = rolePermission.permission
        }

        // Make sure at least one view is enabled.
        let isEnabled = view.is_enabled
        if (!hasEnabledView && view.is_system_view) {
          isEnabled = true
        }

        return {
          ...view,
          is_enabled: isEnabled,
          filters: filters,
          role_permissions: rolePermissions,
          can_edit: true,
        }
      })
      .sort((a, b) => a.sort_order - b.sort_order)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}