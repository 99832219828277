<template>
  <header v-if="!isFullscreen" class="py-9" :class="classes">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center flex-col sm:flex-row">
      <div data-testid="page-title" class="flex-1">
        <h1 class="text-3xl font-bold text-white flex-1 text-center sm:text-left">
          {{ $t(title) }}
        </h1>
        <h2 v-if="subtitle" class="text-2xl font-bold text-white opacity-80 text-center sm:text-left">
          {{ $t(subtitle) }}
        </h2>
      </div>
      <div>
        <div class="flex justify-center items-center mt-6 sm:mt-0 sm:justify-end">
          <div class="flex items-center">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </header>

  <div :class="{'max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8' : !isFullscreen}">
    <slot />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onBeforeUnmount, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from '@/store'

  export default defineComponent({
    name: 'ViewScaffold',
    components: {},
    props: {
      title: {
        required: true,
        type: String,
      },
      subtitle: {
        type: String,
        default: '',
      },
      classes: {
        type: String,
        default: '',
      }
    },
    setup (props) {
      const i18n = useI18n()
      const store = useStore()
      const isFullscreen = computed(() => store.state.app.isFullscreen)

      function setTitle (title: string) {
        document.title = `${i18n.t(title)} · CareSuite`
      }

      watch(() => props.title, () => {
        setTitle(props.title)
      })

      onMounted(() => {
        if (props.title) {
          setTitle(props.title)
        }
      })

      onBeforeUnmount(() => {
        // Fallback in case the next view does not provide a custom title.
        document.title = 'CareSuite'
      })

      return {
        isFullscreen,
      }
    }
  })
</script>
