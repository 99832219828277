<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { CallType, useDemoState } from '@/modules/demo/demo.state'

  defineProps<{
    label: string,
  }>()

  const emit = defineEmits<{
    (event: 'change', args: { type: CallType; close?: boolean }): void
  }>()

  const { lastReset } = useDemoState()

  const redActive = ref(false)
  const greenActive = ref(false)

  const isActiveEmergency = computed(() => redActive.value && greenActive.value)

  // Reset the call buttons when the demo is reset.
  watch(lastReset, () => {
    redActive.value = false
    greenActive.value = false
  })

  function onRedPress () {
    if (isActiveEmergency.value) {
      return clearEmergency()
    }

    if (greenActive.value) {
      redActive.value = true
      emit('change', { type: CallType.Presence, close: true })
      return emit('change', { type: CallType.Emergency })
    }

    if (redActive.value) {
      emit('change', { type: CallType.Alert, close: true })
    } else {
      emit('change', { type: CallType.Alert })
    }

    redActive.value = !redActive.value
  }

  function onGreenPress () {
    if (isActiveEmergency.value) {
      return clearEmergency()
    }

    if (redActive.value) {
      emit('change', { type: CallType.Alert, close: true })
      redActive.value = false
    }

    if (greenActive.value) {
      emit('change', { type: CallType.Presence, close: true })
    } else {
      emit('change', { type: CallType.Presence })
    }

    greenActive.value = !greenActive.value
  }

  function clearEmergency () {
    redActive.value = false
    greenActive.value = false
    emit('change', { type: CallType.Emergency, close: true })
  }
</script>

<template>
  <div>
    <p class="mb-2 text-sm text-csBlue-800 font-semibold leading-none">
      {{ label }}
    </p>
    <div>
      <svg class="max-w-full w-60 mx-auto" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271 271">
        <!-- border -->
        <path fill="url(#a)" d="M0 0h271v271H0z" />
        <!-- bg -->
        <path fill="url(#b)" d="M5 5h261v261H5z" />
        <!-- green button border -->
        <path fill="url(#c)" d="M160 181h58v58h-58z" @click="onGreenPress" />
        <!-- green button -->
        <path class="pointer-events-none" fill="url(#d)" d="M164 185h50v50h-50z" />
        <!-- green button LED -->
        <circle cx="189.5" cy="148.5" r="6.5" fill="url(#e)" />
        <!-- green button LED glow -->
        <g v-if="greenActive" filter="url(#f)">
          <circle cx="189.5" cy="148.5" r="6.5" fill="url(#g)" />
        </g>
        <!-- red button border -->
        <path fill="url(#h)" d="M52 181h58v58H52z" @click="onRedPress" />
        <!-- red button -->
        <path class="pointer-events-none" fill="url(#i)" d="M56 185h50v50H56z" />
        <!-- red button LED -->
        <circle cx="81.5" cy="148.5" r="6.5" fill="url(#j)" />
        <!-- red button LED glow -->
        <g v-if="redActive" filter="url(#k)">
          <circle cx="81.5" cy="148.5" r="6.5" fill="url(#l)" />
        </g>

        <defs>
          <linearGradient
            id="a"
            x1="5.711"
            y1="4.153"
            x2="254.906"
            y2="265.289"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#CCC" />
            <stop offset="1" stop-color="#A8A8A8" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="10.5"
            y1="9"
            x2="250.5"
            y2="260.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E6E6E6" />
            <stop offset="1" stop-color="#D3D3D3" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="218"
            y1="239"
            x2="171.6"
            y2="181"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stop-color="#494949" />
          </linearGradient>
          <linearGradient
            id="d"
            x1="214"
            y1="235"
            x2="174"
            y2="185"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00B548" />
            <stop offset="1" stop-color="#00F16F" />
          </linearGradient>
          <linearGradient
            id="h"
            x1="110"
            y1="239"
            x2="63.6"
            y2="181"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stop-color="#494949" />
          </linearGradient>
          <linearGradient
            id="i"
            x1="106"
            y1="235"
            x2="66"
            y2="185"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#B12424" />
            <stop offset="1" stop-color="#F73636" />
          </linearGradient>
          <radialGradient
            id="e"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(5 7.5 -7.5 5 187 144.5)"
          >
            <stop stop-color="#8F8F8F" />
            <stop offset="1" stop-color="#676767" />
          </radialGradient>
          <radialGradient
            id="g"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(5 7.5 -7.5 5 187 144.5)"
          >
            <stop stop-color="#00DD62" />
            <stop offset="1" stop-color="#00C452" />
          </radialGradient>
          <radialGradient
            id="j"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(5 7.5 -7.5 5 79 144.5)"
          >
            <stop stop-color="#8F8F8F" />
            <stop offset="1" stop-color="#676767" />
          </radialGradient>
          <radialGradient
            id="l"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(5 7.5 -7.5 5 79 144.5)"
          >
            <stop stop-color="#FF3535" />
            <stop offset="1" stop-color="#BF0000" />
          </radialGradient>
          <filter
            id="f"
            x="173"
            y="132"
            width="33"
            height="33"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.807843 0 0 0 0 0.345098 0 0 0 1 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3_119" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_3_119" result="shape" />
          </filter>
          <filter
            id="k"
            x="65"
            y="132"
            width="33"
            height="33"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0.86 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3_119" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_3_119" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</template>