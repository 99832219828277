import { User } from '@/modules/user/models/UserModel'
import { UserCreator } from '@/modules/user/services/UserCreator'
import storage from '@/bootstrap/storage'
import { AuthUserQuery, Locale } from '@/common/graphql/types'
import { Permission } from '@/modules/user/types/User.types'

const UserKey = 'auth.user'

export interface UserState {
  user: User;
}

const state: UserState = {
  user: initUser()
}

const getters = {
  isLoggedIn (state: UserState) {
    return state.user ? state.user.id > 0 : false
  },
  can (state: UserState) {
    return (permission: Permission) => {
      if (!permission) {
        return true
      }

      return state.user.is_superuser || state.user.permissions.hasOwnProperty(permission)
    }
  },
  canAll (state: UserState) {
    return (permissions: Permission[]) => {
      if (!permissions || permissions.length === 0) {
        return true
      }

      return state.user.is_superuser || permissions.every(permission => state.user.permissions.hasOwnProperty(permission))
    }
  },
  canAny (state: UserState) {
    return (permissions: Permission[]) => {
      if (!permissions || permissions.length === 0) {
        return true
      }

      return state.user.is_superuser || permissions.some(permission => state.user.permissions.hasOwnProperty(permission))
    }
  },
}

const actions = {}

const mutations = {
  set (state: UserState, user: AuthUserQuery['authUser']) {
    if (!user) {
      user = { id: "0", name: "", permissions: [], __typename: "User", homepage: '', is_superuser: false, roles: [], locale: Locale.De, system_access: [] }
    }
    let permissions: string[] = []
    if (user.permissions) {
      permissions = user.permissions?.map((p: any) => p.code ?? '')?.filter((i: string) => i)
    }
    state.user = UserCreator.FromObject({
      id: Number(user.id),
      is_superuser: user.is_superuser,
      name: user.name,
      homepage: user.homepage,
      locale: user.locale,
      roles: user.roles,
      system_access: user.system_access ?? [],
      permissions
    })
    storage.setJSON(UserKey, state.user)
  },
  logout (state: UserState) {
    state.user = UserCreator.Null()
    storage.setJSON(UserKey, {})
  }
}

function initUser () {
  // Fetch the user data from localstorage.
  const data = storage.getJSON(UserKey)
  // If we received invalid data, return a null user object.
  if (data === null || !data.hasOwnProperty('id') || !data.hasOwnProperty('name')) {
    return UserCreator.Null()
  }
  return data as User
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
