<script setup lang="ts">
  import {
    buildShortLocale,
    differenceInSeconds,
    formatDistanceStrict,
    formatSecondsAsString,
    parseISO,
    useTime
  } from '@/common/services/time'
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  const props = defineProps({
    time: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'long'
    }
  })
  const now = useTime()
  const i18n = useI18n()
  const string = computed(() => {
    if (!props.time) {
      return ''
    }

    const seconds = differenceInSeconds(now.value.now, parseISO(props.time))

    if (seconds < 10) {
      return i18n.t('common.duration.just_now')
    }

    return props.mode === 'short'
      ? formatSecondsAsString(seconds, { locale: buildShortLocale(i18n) })
      : formatDistanceStrict(parseISO(props.time), now.value.now + 1000, { // +1000 to prevent rounding problems
        addSuffix: true,
        roundingMethod: 'round'
      })
  })
</script>

<template>
  <div class="time-since">
    {{ string }}
  </div>
</template>

<style scoped>
.time-since {
  font-variant-numeric: tabular-nums;
}
</style>
