<script setup lang="ts">
  import FormDropdown from '@/common/components/form/FormDropdown.vue'
  import SectionFormDisplayGroup from '@/modules/admin/section/forms/SectionFormDisplayGroup.vue'
  import {
    DisplayGroupConfiguration,
    DisplayGroupOptions,
    useDisplayGroups
  } from '@/modules/admin/displaygroup/DisplayGroup.api'
  import { computed, PropType, toRefs } from 'vue'
  import { AppDisplayGroup, DisplayGroup, Schedule } from '@/common/graphql/types'
  import { useI18n } from 'vue-i18n'
  import { FormErrorsCollection } from '@/common/components/form/Forms.api'

  const i18n = useI18n()

  defineEmits(['update:modelValue', 'edit'])

  const props = defineProps({
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showEditActions: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object as PropType<FormErrorsCollection>,
      default: () => ({}),
    },
    optionsConfig: {
      type: Object as PropType<DisplayGroupOptions>,
      default: () => ({ add_app_defaults: false, add_gets_defaults: true, allow_schedule_from_sector: true }),
    },
    schedules: {
      type: Array as PropType<ReadonlyArray<Partial<Schedule>>>,
      default: () => []
    },
    displayGroups: {
      type: Array as PropType<ReadonlyArray<Partial<AppDisplayGroup | DisplayGroup>>>,
      default: () => []
    },
    condensed: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object as PropType<DisplayGroupConfiguration>,
      default: () => ({
        weekday_schedule_id: null,
        weekday_display_group_1_id: null,
        weekday_display_group_2_id: null,
        weekday_display_group_3_id: null,
        weekday_display_group_4_id: null,
        weekday_display_group_5_id: null,
        weekend_schedule_id: null,
        weekend_display_group_1_id: null,
        weekend_display_group_2_id: null,
        weekend_display_group_3_id: null,
        weekend_display_group_4_id: null,
        weekend_display_group_5_id: null,
      }),
    },
  })

  const { schedules, displayGroups, modelValue } = toRefs(props)

  const {
    weekdayScheduleRelevantTimes,
    weekendScheduleRelevantTimes,
    scheduleOptions,
    displayGroupOptions,
  } = useDisplayGroups(i18n, modelValue, schedules, displayGroups, props.optionsConfig)

  const hasWeekendSchedule = computed(() => props.modelValue.weekend_schedule_id !== null && props.modelValue.weekend_schedule_id !== undefined && props.modelValue.weekend_schedule_id !== 255)
  const hasWeekdaySchedule = computed(() => props.modelValue.weekday_schedule_id !== null && props.modelValue.weekday_schedule_id !== undefined && props.modelValue.weekday_schedule_id !== 255)
</script>

<template>
  <div :class="[condensed ? 'space-y-6' : 'space-y-12']">
    <div>
      <h2 v-if="!condensed" class="mb-4 pb-1 border-b">
        {{ $t('schedule.weekdays') }}
      </h2>

      <div class="flex flex-wrap -mx-2">
        <div
          class="flex-1 px-2 -mx-2"
          :class="{'pb-4': hasWeekdaySchedule}"
        >
          <div class="w-full px-2">
            <FormDropdown
              name="weekday_schedule_id"
              :label="condensed ? 'schedule.weekdays' : 'schedule.singular'"
              :placeholder="$t('displaygroup.all')"
              :modelValue="modelValue.weekday_schedule_id"
              :loading="loading"
              :errors="errors"
              :options="scheduleOptions"
              :disabled="disabled"
              @update:modelValue="$emit('update:modelValue', { ...modelValue, weekday_schedule_id: $event })"
            />
          </div>
        </div>
        <div v-if="hasWeekdaySchedule" class="w-full px-2 py-2">
          <SectionFormDisplayGroup
            :modelValue="modelValue.weekday_display_group_1_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="1"
            :times="[weekdayScheduleRelevantTimes[0], weekdayScheduleRelevantTimes[1]]"
            :displayGroups="displayGroupOptions"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekday_display_group_1_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekdayScheduleRelevantTimes.length > 2"
            :modelValue="modelValue.weekday_display_group_2_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="2"
            :displayGroups="displayGroupOptions"
            :times="[weekdayScheduleRelevantTimes[1], weekdayScheduleRelevantTimes[2]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekday_display_group_2_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekdayScheduleRelevantTimes.length > 3"
            :modelValue="modelValue.weekday_display_group_3_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="3"
            :displayGroups="displayGroupOptions"
            :times="[weekdayScheduleRelevantTimes[2], weekdayScheduleRelevantTimes[3]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekday_display_group_3_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekdayScheduleRelevantTimes.length > 4"
            :modelValue="modelValue.weekday_display_group_4_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="4"
            :displayGroups="displayGroupOptions"
            :times="[weekdayScheduleRelevantTimes[3], weekdayScheduleRelevantTimes[4]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekday_display_group_4_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekdayScheduleRelevantTimes.length > 5"
            :modelValue="modelValue.weekday_display_group_5_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="5"
            :displayGroups="displayGroupOptions"
            :times="[weekdayScheduleRelevantTimes[4], weekdayScheduleRelevantTimes[5]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekday_display_group_5_id: $event })"
          />
        </div>
      </div>
    </div>

    <div>
      <h2 v-if="!condensed" class="mb-4 pb-1 border-b">
        {{ $t('schedule.weekends') }}
      </h2>

      <div class="flex flex-wrap -mx-2">
        <div
          class="flex-1 border-gray-200 px-2 -mx-2"
          :class="{'pb-4 mb-4': hasWeekendSchedule}"
        >
          <div class="w-full px-2">
            <FormDropdown
              :modelValue="modelValue.weekend_schedule_id"
              :placeholder="$t('displaygroup.all')"
              name="weekend_schedule_id"
              :label="condensed ? 'schedule.weekends' : 'schedule.singular'"
              :loading="loading"
              :errors="errors"
              :disabled="disabled"
              :options="scheduleOptions"
              @update:modelValue="$emit('update:modelValue', { ...modelValue, weekend_schedule_id: $event })"
            />
          </div>
        </div>
        <div v-if="hasWeekendSchedule" class="w-full px-2 py-2">
          <SectionFormDisplayGroup
            :modelValue="modelValue.weekend_display_group_1_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="1"
            :times="[weekendScheduleRelevantTimes[0], weekendScheduleRelevantTimes[1]]"
            :displayGroups="displayGroupOptions"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekend_display_group_1_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekendScheduleRelevantTimes.length > 2"
            :modelValue="modelValue.weekend_display_group_2_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="2"
            :displayGroups="displayGroupOptions"
            :times="[weekendScheduleRelevantTimes[1], weekendScheduleRelevantTimes[2]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekend_display_group_2_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekendScheduleRelevantTimes.length > 3"
            :modelValue="modelValue.weekend_display_group_3_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="3"
            :displayGroups="displayGroupOptions"
            :times="[weekendScheduleRelevantTimes[2], weekendScheduleRelevantTimes[3]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekend_display_group_3_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekendScheduleRelevantTimes.length > 4"
            :modelValue="modelValue.weekend_display_group_4_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="4"
            :displayGroups="displayGroupOptions"
            :times="[weekendScheduleRelevantTimes[3], weekendScheduleRelevantTimes[4]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekend_display_group_4_id: $event })"
          />
          <SectionFormDisplayGroup
            v-if="weekendScheduleRelevantTimes.length > 5"
            :modelValue="modelValue.weekend_display_group_5_id"
            :showEditActions="showEditActions"
            :loading="loading"
            :disabled="disabled"
            :index="5"
            :displayGroups="displayGroupOptions"
            :times="[weekendScheduleRelevantTimes[4], weekendScheduleRelevantTimes[5]]"
            @edit="$emit('edit', $event)"
            @update:modelValue="$emit('update:modelValue', { ...modelValue, weekend_display_group_5_id: $event })"
          />
        </div>
      </div>
    </div>
  </div>
</template>