<template>
  <div class="text-center">
    <p class="font-semibold mb-4">
      {{ $t('setup.admin.title') }}
    </p>
    <p class="mx-auto max-w-md">
      {{ $t('setup.admin.subtitle') }}
    </p>
    <div v-if="state.user && state.user.id > 0" class="p-4 bg-green-50 border border-green-200 rounded shadow-sm mt-8">
      <i18n-t keypath="setup.admin.status" tag="p" class="leading-normal">
        <strong>{{ state.user.name }}</strong>
      </i18n-t>
    </div>
    <div v-else class="flex -mx-2 mt-8">
      <div class="w-2/5 px-2">
        <FormInput
          v-model="username"
          name="username"
          label="user.fields.username"
          :errors="errors"
        />
      </div>
      <div class="w-2/5 px-2">
        <FormInput
          v-model="password"
          name="password"
          label="user.fields.password"
          :errors="errors"
        />
      </div>
      <div class="w-1/5 px-2">
        <ActionButton :loading="loading" type="primary" class="mt-6" @click.prevent="create">
          {{ $t('common.actions.create') }}
        </ActionButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, reactive, ref, toRefs } from 'vue'
  import { useMutation } from '@vue/apollo-composable'
  import { transformErrors } from '@/common/components/form/Forms.api'
  import { CreateInitialUserMutation, CreateInitialUserMutationVariables } from '@/common/graphql/types'
  import { useStore } from '@/store'
  import { useNow } from '@/common/services/time'
  import { useI18n } from 'vue-i18n'

  import MutationCreateInitialUser from '../graphql/MutationCreateInitialUser.gql'
  import { SetupState } from '@/modules/setup/Setup.api'

  export default defineComponent({
    name: 'AdminUser',
    components: {},
    props: {
      state: {
        type: Object as PropType<SetupState>,
        default: () => ({})
      }
    },
    emits: ['setState'],
    setup (props, { emit }) {
      const store = useStore()
      const i18n = useI18n()
      const form = reactive({ username: 'admin', password: '' })
      const {
        mutate,
        loading,
        onError
      } = useMutation<CreateInitialUserMutation, CreateInitialUserMutationVariables>(MutationCreateInitialUser, {
        fetchPolicy: 'no-cache',
      })

      const errors = ref({})
      onError((response: any) => {
        if (response.graphQLErrors?.[0].message?.indexOf('already present') > -1) {
          errors.value = { username: [{ message: i18n.t('setup.admin.error') }] }
        } else {
          errors.value = transformErrors(response.graphQLErrors)
        }
      })

      async function create () {
        const response = await mutate({
          username: form.username,
          password: form.password,
          locale: props.state.locale,
        })
        if (response?.data?.createInitialUser) {
          emit('setState', ['user', response.data?.createInitialUser])
          store.commit('user/set', response.data?.createInitialUser)
          store.commit('app/setSetupTime', useNow())
          form.username = ''
          form.password = ''
          errors.value = {}
        }
      }

      return {
        errors,
        create,
        loading,
        ...toRefs(form),
      }
    }
  })
</script>
