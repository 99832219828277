<template>
  <svg viewBox="0 0 24 24" class="w-4">
    <circle cx="12" cy="12" r="10" class="fill-current text-gray-100" />
    <path
      class="fill-current text-gray-500"
      d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SystemTreeIconPerson',
  })
</script>
