export interface FormHistoryState {
  created: Record<string, any>[]
  updated: Record<string, any>[]
  deleted: Record<string, any>[]
}

const state: FormHistoryState = {
  created: [],
  updated: [],
  deleted: [],
}

const getters = {
  lastCreated (state: FormHistoryState) {
    return state.created.length > 0 ? state.created[state.created.length - 1] : null
  },
  lastUpdated (state: FormHistoryState) {
    return state.updated.length > 0 ? state.updated[state.updated.length - 1] : null
  },
  lastDeleted (state: FormHistoryState) {
    return state.deleted.length > 0 ? state.deleted[state.deleted.length - 1] : null
  },
  count (state: FormHistoryState) {
    return state.created.length + state.updated.length + state.deleted.length
  }
}

const actions = {}

const mutations = {
  addCreated (state: FormHistoryState, record: Record<string, any>) {
    state.created.push(record)
  },
  addUpdated (state: FormHistoryState, record: Record<string, any>) {
    state.updated.push(record)
  },
  addDeleted (state: FormHistoryState, record: Record<string, any>) {
    state.deleted.push(record)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}