export interface BrowserStorage {
  readonly length: number;

  clear (): void;

  getItem (key: string): string | null;

  key (index: number): string | null;

  removeItem (key: string): void;

  setItem (key: string, value: string): void;

  [name: string]: any;
}

export const Storage = Symbol('storage')

export class LocalStorage {
  /**
   * The storage implementation.
   */
  protected readonly storage: BrowserStorage

  /**
   * Construct the LocalStorage class.
   * @param storage
   */
  public constructor (storage: BrowserStorage) {
    this.storage = storage
  }

  /**
   * Store a value in localStorage.
   * @param key
   * @param data
   */
  public set (key: string, data: string): void {
    return this.storage.setItem(this.prefixKey(key), data)
  }

  /**
   * Store a JSON value in localStorage.
   * @param key
   * @param data
   */
  public setJSON (key: string, data: Record<string, any>): void {
    return this.storage.setItem(this.prefixKey(key), JSON.stringify(data))
  }

  /**
   * Return a string value from localStorage.
   * @param key
   */
  public get (key: string): string {
    const value = this.storage.getItem(this.prefixKey(key))
    if (value === null) {
      return ''
    }
    return value
  }

  /**
   * Return a json encoded string as Object.
   * @param key
   */
  public getJSON<T = Record<string, any>> (key: string): T | null {
    const value = this.storage.getItem(this.prefixKey(key))
    if (value === null) {
      return null
    }
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  /**
   * Remove a value from localStorage.
   * @param key
   */
  public remove (key: string): void {
    this.storage.removeItem(this.prefixKey(key))
  }

  /**
   * Add a prefix to the localStorage key.
   * @param key
   */
  protected prefixKey (key: string): string {
    return `cs.${key}`
  }
}
