/**
 * Represents a logged-in user.
 */
import { PermissionLookup } from '@/modules/user/types/User.types'

export class User {
  public id: number
  public name: string
  public locale: string
  public homepage: string
  public is_superuser = false
  public system_access: number[] = []
  public permissions: PermissionLookup = {}
  public roles: string[] = []

  /**
   * Build a new user.
   */
  public constructor (id: number, name: string, is_superuser = false, locale = 'de', homepage = '', permissions: PermissionLookup = {}, roles: string[] = [], system_access: number[] = []) {
    this.id = id
    this.name = name
    this.permissions = permissions
    this.homepage = homepage
    this.is_superuser = is_superuser
    this.locale = locale
    this.system_access = system_access
    this.roles = roles
  }
}
