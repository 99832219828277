<template>
  <RouterView />
  <ModalStack />
  <NotificationList />
  <GetsHTTPErrorPopup />
  <GetsCommitPopup />
  <UpdateNotifier />
</template>

<script lang="ts">
  import ModalStack from '@/common/components/modal/ModalStack.vue'
  import NotificationList from '@/common/components/notification/NotificationList.vue'
  import UpdateNotifier from '@/common/components/UpdateNotifier.vue'
  import SubscriptionSystemMessages from '@/common/graphql/SubscriptionSystemMessages.gql'
  import { SystemMessagesSubscription } from '@/common/graphql/types'
  import { useAppDataLoader } from '@/common/services/appdata/AppData.api'
  import GetsHTTPErrorPopup from '@/modules/device/components/sync/GetsHTTPErrorPopup.vue'
  import GetsCommitPopup from '@/modules/device/components/sync/GetsCommitPopup.vue'
  import { useSubscription } from '@vue/apollo-composable'
  import { computed, defineComponent, onBeforeUnmount, onMounted, watch } from 'vue'
  import { useStore } from '@/store'
  import { systemMessageHandler } from '@/common/services/messages/SystemMessageService'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    components: { ModalStack, GetsHTTPErrorPopup, NotificationList, UpdateNotifier, GetsCommitPopup },
    setup () {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const user = computed(() => store.state.user.user)
      const isCommittingConfig = computed(() => store.getters['app/isCommittingGetsConfig'])

      const { fetch } = useAppDataLoader(store)
      fetch()

      // Listen for incoming system commands
      const { result } = useSubscription<SystemMessagesSubscription>(SubscriptionSystemMessages)
      watch(() => result.value, data => {
        if (data?.systemMessages) {
          systemMessageHandler(data.systemMessages, store)
        }
      })

      // Block navigation while the GEC config is being committed.
      watch(() => route.name, newRoute => {
        if (!isCommittingConfig.value) {
          return
        }
        // Allow navigation to display and login routes.
        if (newRoute === 'display' || newRoute === 'sync' || newRoute === 'login' || Number(user.value?.id) === 0) {
          return
        }
        router.push({ name: 'sync' })
      })

      // Global keyboard shortcuts
      function globalKeyHandler (e: KeyboardEvent) {
        // Ctrl + K focuses the search field.
        if (e.key === 'k' && e.ctrlKey) {
          e.preventDefault()
          document.querySelector<HTMLInputElement>('#global-search')?.select()
          document.querySelector<HTMLInputElement>('#global-search')?.focus()
        }
        // Escape closes an open modal.
        else if (e.key === 'Escape') {
          if (store.state.modalStack.stack.length > 0 && document.activeElement?.tagName !== 'INPUT') {
            window.history.back()
          }
        }
      }

      onMounted(() => {
        document.addEventListener('keydown', globalKeyHandler)
      })
      onBeforeUnmount(() => {
        document.removeEventListener('keydown', globalKeyHandler)
      })
    }
  })
</script>
