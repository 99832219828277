<template>
  <div class="text-center">
    <p class="font-semibold mb-4">
      {{ $t('setup.start.title') }}
    </p>
    <p class="mb-10">
      {{ $t('setup.start.subtitle') }}
    </p>
    <FormDropdown
      name="locale"
      label="Select language / Sprache auswählen"
      class="mx-auto max-w-xs text-left"
      :options="options"
      :modelValue="locale"
      @update:modelValue="$emit('setState', ['locale', $event])"
    />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, PropType } from 'vue'
  import { availableLocales } from '@/bootstrap/i18n'
  import { useI18n } from 'vue-i18n'
  import { SetupState } from '@/modules/setup/Setup.api'

  export default defineComponent({
    name: 'Start',
    components: {},
    props: {
      state: {
        type: Object as PropType<SetupState>,
        default: () => ({})
      }
    },
    emits: ['setState'],
    setup (props) {
      const i18n = useI18n()
      const locale = computed(() => props.state.locale)
      const options = computed(() => availableLocales.map(locale => ({
        label: i18n.t(`common.locales.${locale.toLowerCase()}`),
        value: locale,
      })))
      return { options, locale }
    }
  })
</script>
