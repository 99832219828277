
interface WakeLockSentinel {
  release (): Promise<void>;

  addEventListener (event: string, callback: EventListenerOrEventListenerObject, options?: any): void;

  removeEventListener (event: string, callback: EventListenerOrEventListenerObject, options?: any): void;
}

export function useWakeLock () {
  let wakeLock: WakeLockSentinel

  function onVisibilityChange () {
    if (document.visibilityState === 'visible') {
      requestWakeLock()
    }
  }

  function releaseWakeLock () {
    if (wakeLock) {
      wakeLock.release()
    }

    document.removeEventListener('visibilitychange', onVisibilityChange)
  }

  async function requestWakeLock () {
    document.addEventListener('visibilitychange', onVisibilityChange)

    try {
      wakeLock = await navigator.wakeLock.request('screen')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // window may not be visible. request a wakelock when the tab is visible again
    }
  }

  return {
    requestWakeLock,
    releaseWakeLock
  }
}