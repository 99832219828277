<template>
  <div class="swatch w-5 h-5 border border-gray-600 shadow rounded" :style="{'background-color': color}" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Swatch',
    inheritAttrs: false,
    props: {
      color: {
        type: String,
        default: ''
      }
    },
  })
</script>

<style lang="stylus" scoped>
.swatch
  @apply relative overflow-hidden
  transition background-color .2s ease-out

  &:before
    content ""
    @apply absolute inset-0 z-10
    background-image linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .25))
</style>
