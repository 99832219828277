<template>
  <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.79 163.624">
    <path
      class="fill-current text-primary"
      d="M162.79 93.493a81.81 81.81 0 11-23.72-70.12H81.82a58.43 58.43 0 1046.75 93.51H81.82a35.06 35.06 0 110-70.12h73.93a81.271 81.271 0 017 23.38h-81a11.69 11.69 0 000 23.37z"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CSLogo',
  })
</script>
