import { Store } from 'vuex'
import { DirectiveBinding } from 'vue'
import { State } from '@/store'

// canDirective checks if the user has a given set of permissions.
// If not, the element is hidden via the display property.
export function canDirective (store: Store<State>) {
  return (el: HTMLElement, bindings: DirectiveBinding) => {
    const getter = Array.isArray(bindings.value) ? 'user/canAll' : 'user/can'
    if (!store.getters[getter](bindings.value)) {
      el.dataset['csInitialDisplay'] = el.style.display ? el.style.display : 'initial'
      el.style.display = 'none'
    } else if (el.dataset['csInitialDisplay']) {
      el.style.display = el.dataset['csInitialDisplay']
    }
  }
}

// readonlyDirective makes all .cs-form-input children readonly.
export function readonlyDirective () {
  return (el: HTMLElement, bindings: DirectiveBinding) => {
    el.querySelectorAll<HTMLElement>('.cs-form-input').forEach(formInput => {
      const input = formInput.querySelector('input')
      if (bindings.value) {
        formInput.classList.add('disabled')
        if (input) {
          input.readOnly = true
        }
      }
    })
  }
}

// displayTextDirective sanitizes and reformats "display text" input fields.
// These field values can only contain a certain type of characters restricted
// by the GEC hardware they are displayed on.
export function displayTextDirective () {
  let target: HTMLInputElement | null
  const cleanup = (value: string) => {
    return typeof value.replaceAll === 'function' ? value.replaceAll(/[^ -\\.a-z0-9]/gi, '').toUpperCase() : value
  }
  const handler = (e: Event) => {
    const eventTarget = e.target as HTMLInputElement
    if (eventTarget) {
      eventTarget.value = cleanup(eventTarget.value)
    }
  }
  return {
    mounted: (el: HTMLInputElement) => {
      target = el.tagName.toUpperCase() === 'INPUT' ? el : el.querySelector('input')
      if (target) {
        target.addEventListener('blur', handler)
      }
    },
    updated: (el: HTMLInputElement) => {
      target = el.tagName.toUpperCase() === 'INPUT' ? el : el.querySelector('input')
      if (target) {
        target.value = cleanup(target.value)
      }
    },
    unmounted: () => {
      target?.removeEventListener('blur', handler)
    }
  }
}

// focusDirective sets the focus into a input field.
export function focusDirective () {
  return (el: HTMLInputElement) => {
    const target = el.tagName.toUpperCase() === 'INPUT' ? el : el.querySelector('input')
    if (target) {
      target.focus()
    }
  }
}

// licensedDirective checks if a given feature is licensed.
// If not, the element is hidden via the display property.
export function licensedDirective (store: Store<State>) {
  return (el: HTMLElement, bindings: DirectiveBinding) => {
    if (!store.getters['license/isLicensed'](bindings.value)) {
      el.dataset['csInitialDisplay'] = el.style.display ? el.style.display : 'initial'
      el.style.display = 'none'
    } else if (el.dataset['csInitialDisplay']) {
      el.style.display = el.dataset['csInitialDisplay']
    }
  }
}
