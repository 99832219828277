import { Action } from 'vuex'
import { State } from '@/store/index'
import { Call, SystemParam } from '@/common/graphql/types'
import { sortCalls } from '@/modules/display/composables/Calls.api'

export interface CallWithSystem extends Call {
  system_id: number
  system_name: string
}

export interface CallData {
  calls: CallWithSystem[]
  presences: CallWithSystem[]
}

export interface CallState {
  bySystem: Record<number, CallData>
}

const state: CallState = {
  bySystem: {},
}

const getters = {
  combinedCalls (state: CallState): CallWithSystem[] {
    return sortCalls([...Object.values(state.bySystem).map(v => v.calls).flat()])
  },
  combinedPresences (state: CallState): CallWithSystem[] {
    return sortCalls([...Object.values(state.bySystem).map(v => v.presences).flat()])
  },
}

const actions: Record<string, Action<CallState, State>> = {
}

const mutations = {
  setCallData (state: CallState, { system, calls, presences }: { system: SystemParam, calls: Call[], presences: Call[] }) {
    const mappedCalls = calls.map((call: Call) => ({ ...call, system_id: Number(system.id), system_name: system.name }))
    const mappedPresences = presences.map((call: Call) => ({ ...call, system_id: Number(system.id), system_name: system.name }))

    state.bySystem[Number(system.id)] = {
      calls: mappedCalls,
      presences: mappedPresences,
    }
  },
  reset (state: CallState) {
    state.bySystem = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}