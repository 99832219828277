import { Composer } from 'vue-i18n'
import { State } from '@/store'
import { Store } from 'vuex'
import { FormDropdownOption } from '@/common/components/form/Forms.api'

export function leftPad (n: string | number, width: number, z = '0') {
  return String(n).padStart(width, z)
}

export function range (size: number, startAt = 0): number[] {
  return [...Array(size).keys()].map(i => i + startAt)
}

export function delay<T> (fn: () => T, ms: number): Promise<T> {
  return new Promise(resolve => setTimeout(() => resolve(fn()), ms))
}

export function randomNumber (min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function randomString (size: number) {
  const rnd = (Math.random() + 1).toString(36)
  // as rnd might return very short strings we pad the string with zeroes.
  const base = rnd + '00000000000000'
  return base.substr(2, size + 2)
}

export function numberFormat (number: number) {
  return new Intl.NumberFormat('de-CH').format(number)
}

export function formatBytes (bytes: number): string {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

const logPrefix = '[CareSuite] '

export function logInfo (message: string, ...context: any) {
  // eslint-disable-next-line no-console
  console.info(logPrefix + message, ...context)
}

export function log (message: string, ...context: any) {
  // eslint-disable-next-line no-console
  console.log(logPrefix + message, ...context)
}

export function logError (message: string, ...context: any) {
  // eslint-disable-next-line no-console
  console.error(logPrefix + message, ...context)
}

export function logWarning (message: string, ...context: any) {
  // eslint-disable-next-line no-console
  console.warn(logPrefix + message, ...context)
}

// Turns a locale object into dropdown options.
export function dropdownOptionsFromLocale (i18n: Composer, key: string): FormDropdownOption[] {
  const options: FormDropdownOption[] = []
  const trans = i18n.tm(key) as Record<string, string>
  if (typeof trans === 'object') {
    for (const key in trans) {
      if (trans.hasOwnProperty(key)) {
        options.push({ value: key, label: trans[key as string] })
      }
    }
  }
  return options
}

export function debounce (fn: (...args: any) => any, duration: number) {
  let timeout: number | null
  return function (this: any, ...args: any) {
    const effect = () => {
      timeout = null
      return fn.apply(this, args)
    }
    if (timeout) {
      window.clearTimeout(timeout)
    }
    timeout = window.setTimeout(effect, duration)
  }

}

export function throttle (fn: (...args: any) => any, duration: number) {
  let shouldWait = false
  return function (this: any, ...args: any) {
    if (!shouldWait) {
      fn.apply(this, args)
      shouldWait = true
      setTimeout(function () {
        shouldWait = false
      }, duration)
    }
  }
}

// getStyle returns the computed style of a property for a specific element.
export function getComputedStyle (el: HTMLElement, property: string) {
  return window.getComputedStyle(el, null).getPropertyValue(property)
}

// sleep waits for a given number of milliseconds.
export function sleep (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// sortNatural sorts an array naturally by the given field.
export function sortNatural<T = Record<string, unknown>> (field: keyof T, input: Readonly<T[]>): T[] {
  return [...input].sort((a, b) => {
    const valA = a[field]
    if (typeof valA !== 'string') {
      return 0
    }
    const valB = b[field]
    if (typeof valB !== 'string') {
      return 0
    }
    return valA.localeCompare(valB, navigator.languages[0], {
      numeric: true,
      ignorePunctuation: true
    })
  })
}

export interface NotificationArgs {
  type: 'success' | 'error' | 'warning'
  messageKey: string
  titleKey: string
  textKey: string
}

// useNotifications provides an easy interface to show notifications.
export function useNotifications (i18n: any, store: Store<State>) {
  return {
    notify (args: string | Partial<NotificationArgs>) {
      if (typeof args === 'string') {
        args = { messageKey: args }
      }

      const defaults = { type: '', titleKey: 'title', textKey: 'text', messageKey: '' }
      const config = { ...defaults, ...args }

      // Reuse the last part of the message key as the type if no type was specified.
      if (defaults.type === '') {
        const messageKeyParts = config.messageKey.split('.')
        if (['success', 'error', 'warning'].includes(messageKeyParts[messageKeyParts.length - 1])) {
          config.type = messageKeyParts.pop() as 'success' | 'error' | 'warning'
        }
      }

      store.commit('notifications/notify', {
        title: i18n.t(`${config.messageKey}.${config.titleKey}`),
        text: i18n.t(`${config.messageKey}.${config.textKey}`),
        type: config.type
      })
    }
  }
}

// arrayGroupBy groups an array of objects by a given key.
export function arrayGroupBy (arr: Array<any>, key: string) {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
