<script setup lang="ts">
  import { computed, PropType, ref, watch } from 'vue'
  import FormInput from '@/common/components/form/FormInput.vue'
  import { useMutation, useQuery } from '@vue/apollo-composable'
  import {
    CreateDisplayProfileMutation,
    DeleteDisplayProfileMutation,
    DisplayProfile,
    DisplayProfileFontSize,
    DisplayProfileSettings,
    DisplayProfilesQuery,
    Locale,
    UpdateDisplayProfileMutation
  } from '@/common/graphql/types'
  import FormDropdown from '@/common/components/form/FormDropdown.vue'
  import { useI18n } from 'vue-i18n'
  import Drawer from '@/common/components/Drawer.vue'
  import FormCheckbox from '@/common/components/form/FormCheckbox.vue'
  import { useStore } from '@/store'
  import QueryDisplayProfiles from '@/modules/display/graphql/QueryDisplayProfiles.gql'
  import Popup from '@/common/components/popup/Popup.vue'
  import MutationCreateDisplayProfile from '../graphql/MutationCreateDisplayProfile.gql'
  import MutationUpdateDisplayProfile from '../graphql/MutationUpdateDisplayProfile.gql'
  import MutationDeleteDisplayProfile from '../graphql/MutationDeleteDisplayProfile.gql'
  import DisplayProfileSystemsSettings from '@/modules/display/components/DisplayProfileSystemsSettings.vue'
  import ActionButton from '@/common/components/button/ActionButton.vue'
  import ConfirmPopup from '@/common/components/popup/ConfirmPopup.vue'
  import { useRouter } from 'vue-router'
  import MdiIcon from '@/common/components/svg/MdiIcon.vue'
  import { mdiAlertCircleOutline } from '@mdi/js'
  import { DisplayParams, displayParamsToUrl } from '@/modules/display/composables/Calls.api'
  import { useLocalStorage } from '@vueuse/core'

  const emit = defineEmits(['close'])
  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
    initialProfileId: {
      type: String,
      default: null,
    },
    settings: {
      type: Object as PropType<DisplayParams>,
      default: () => ({}),
    }
  })

  const store = useStore()
  const i18n = useI18n()
  const router = useRouter()

  const canManageProfiles = computed(() => store.getters['user/can']('display.manage_display_profiles'))

  const lastUsedProfile = useLocalStorage('cs.lastUsedProfile', '')

  const {
    result: displayProfilesResult,
    onResult: onDisplayProfilesResult,
    refetch: refetchProfiles
  } = useQuery<DisplayProfilesQuery>(QueryDisplayProfiles, {}, { fetchPolicy: 'no-cache' })
  onDisplayProfilesResult((result) => {
    if (result.data && props.initialProfileId) {
      activeDisplayProfile.value = props.initialProfileId
    }
  })

  const displayProfiles = computed(() => (displayProfilesResult.value?.displayProfiles ?? []) as DisplayProfile[])

  const systems = computed(() => store.state.app.systems)

  const languageOptions = [
    { value: Locale.De, label: i18n.t('common.locales.de') },
    { value: Locale.It, label: i18n.t('common.locales.it') },
    { value: Locale.Fr, label: i18n.t('common.locales.fr') },
    { value: Locale.En, label: i18n.t('common.locales.en') },
  ]

  const fontSizeOptions = [
    { value: DisplayProfileFontSize.Normal, label: i18n.t('display.profile.font_size.normal') },
    { value: DisplayProfileFontSize.Larger, label: i18n.t('display.profile.font_size.larger') },
    { value: DisplayProfileFontSize.Largest, label: i18n.t('display.profile.font_size.largest') },
  ]

  const createProfileName = ref('')
  const createProfilePopupVisible = ref(false)
  const pendingDeleteProfile = ref<string | null>(null)

  const activeDisplayProfile = ref<string | null>(null)
  const activeDisplayProfileData = ref<DisplayProfile>({
    id: '',
    name: '',
    slug: '',
    locale: Locale.De,
    font_size: DisplayProfileFontSize.Normal,
    fullscreen: false,
    show_video_stream: false,
    settings: [],
  })

  // If there were settings passed via the URL, use these here.
  if (props.settings.fontSize) {
    activeDisplayProfileData.value.font_size = props.settings.fontSize.toUpperCase() as DisplayProfileFontSize
  }
  if (props.settings.fullscreen) {
    activeDisplayProfileData.value.fullscreen = props.settings.fullscreen
  }
  if (props.settings.videoStream) {
    activeDisplayProfileData.value.show_video_stream = props.settings.videoStream
  }
  if (props.settings.locale) {
    activeDisplayProfileData.value.locale = props.settings.locale as Locale
  }

  const url = ref('')
  const dirty = ref(false)

  // If a profile is selected, use the slug in the URL.
  watch(() => activeDisplayProfileData.value, (value) => {
    if (value.slug) {
      url.value = `${document.location.protocol}//${document.location.host}${router.resolve({
        name: 'display',
        params: { slug: value.slug }
      }).href}`
    } else {
      url.value = document.location.toString()
    }
  }, { immediate: true, deep: true })

  // If no profile is active, encode the settings to the URL.
  watch(() => activeDisplayProfileData.value, value => {
    if (activeDisplayProfile.value) {
      return
    }

    const url = displayParamsToUrl(document.location, value).toString()
    if (url) {
      window.history.replaceState({}, '', url)
    }
  }, { deep: true })

  const isLocalhost = computed(() => url.value.includes('localhost') || url.value.includes('127.0.0.'))

  watch(() => [activeDisplayProfile.value, props.isOpen], ([value, isOpen]) => {
    dirty.value = false

    if (!isOpen) {
      return
    }

    if (!value) {
      lastUsedProfile.value = ''
      router.replace({ name: 'display' })

      return
    }

    const profile = displayProfiles.value.find((profile) => profile.id === value)
    if (!profile) {
      return
    }

    router.replace({ name: 'display', params: { slug: profile.slug } })

    activeDisplayProfileData.value = { ...profile }
  })

  const displayProfilesOptions = computed(() => {
    const options = displayProfiles.value.map<{ value: string | null, label: string }>((profile) => ({
      value: profile.id,
      label: profile.name,
    }))

    options.unshift({ value: null, label: '-- ' + i18n.t('display.profile.states.none') })

    return options
  })

  function updateSystemSettings (value: DisplayProfileSettings) {
    dirty.value = true

    const currentSettings = activeDisplayProfileData.value.settings.find((setting) => setting.system_id === value.system_id)
    if (currentSettings) {
      activeDisplayProfileData.value.settings = activeDisplayProfileData.value.settings.map((setting) => {
        return Number(setting.system_id) === Number(value.system_id) ? value : setting
      })
    } else {
      activeDisplayProfileData.value.settings = [
        ...activeDisplayProfileData.value.settings,
        value
      ]
    }
  }

  function changeSystemEnabled (value: DisplayProfileSettings & { enabled: boolean }) {
    dirty.value = true

    if (!value.enabled) {
      activeDisplayProfileData.value.settings = activeDisplayProfileData.value.settings.filter(setting => setting.system_id !== value.system_id)
    } else {
      activeDisplayProfileData.value.settings.push({
        ...value,
      })
    }
  }

  const activeDisplayProfileDataOutput = computed(() => {
    const settings = activeDisplayProfileData.value.settings.filter((setting: any) => (!setting.hasOwnProperty('enabled') || setting.enabled !== false)).map((setting: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled: _, ...rest } = setting
      return rest
    })

    const data: Record<string, unknown> = {
      ...activeDisplayProfileData.value,
      settings,
    }

    delete data.__typename
    delete data.slug

    return data
  })

  /**
   * Create
   */
  const {
    mutate: crateProfileMutation,
    onError: onErrorCreate
  } = useMutation<CreateDisplayProfileMutation>(MutationCreateDisplayProfile)
  onErrorCreate(error => {
    console.error(error)
    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.action.error.title'),
      text: i18n.t('common.notifications.action.error.text'),
      type: 'error',
      offset: [520, 0],
    })
  })

  async function createProfile () {
    if (!createProfileName.value) {
      return
    }

    const response = await crateProfileMutation({
      input: {
        name: createProfileName.value,
        locale: i18n.locale.value as Locale,
        font_size: DisplayProfileFontSize.Normal,
        fullscreen: false,
        show_video_stream: false,
        settings: [{ system_id: store.state.app.systemContext }],
      }
    })

    dirty.value = false

    if (response?.data?.createDisplayProfile?.id) {
      await refetchProfiles()
      activeDisplayProfile.value = response.data.createDisplayProfile.id
    }

    createProfileName.value = ''
    createProfilePopupVisible.value = false
  }

  /**
   * Updates
   */
  const {
    mutate: updateProfileMutation,
    onError: onErrorUpdate
  } = useMutation<UpdateDisplayProfileMutation>(MutationUpdateDisplayProfile)
  onErrorUpdate(error => {
    console.error(error)
    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.action.error.title'),
      text: i18n.t('common.notifications.action.error.text'),
      type: 'error',
      offset: [520, 0],
    })
  })

  async function updateProfile () {
    await updateProfileMutation({
      input: activeDisplayProfileDataOutput.value
    })

    await refetchProfiles()

    dirty.value = false

    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.update.success.title'),
      text: i18n.t('common.notifications.update.success.text'),
      type: 'success',
      offset: [520, 0],
    })
  }

  /**
   * Delete
   */
  const {
    mutate: deleteProfileMutation,
    onError: onErrorDelete,
  } = useMutation<DeleteDisplayProfileMutation>(MutationDeleteDisplayProfile)

  onErrorDelete(error => {
    console.error(error)
    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.delete.error.title'),
      text: i18n.t('common.notifications.delete.error.text'),
      type: 'error'
    })
  })

  async function deleteProfile () {
    if (!pendingDeleteProfile.value) {
      return
    }

    await deleteProfileMutation({
      ids: [pendingDeleteProfile.value]
    })

    pendingDeleteProfile.value = null
    activeDisplayProfile.value = null

    await refetchProfiles()

    store.commit('notifications/notify', {
      title: i18n.t('common.notifications.delete.success.title'),
      text: i18n.t('common.notifications.delete.success.text'),
      type: 'success'
    })
  }

  function close () {
    if (activeDisplayProfile.value && dirty.value && !confirm(i18n.t('display.callouts.unsaved_changes'))) {
      return
    }

    emit('close')
  }
</script>

<template>
  <Drawer
    title="display.settings"
    :isOpen="isOpen"
    @close="close"
  >
    <div class="m-4 space-y-4">
      <!-- URL -->
      <div class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm mb-1">
          {{ $t('display.config.url') }}<br>
        </div>
        <FormInput
          v-model="url"
          :readonly="true"
          name="url"
        />
        <div v-if="isLocalhost" class="text-yellow-700 text-xs mt-2 flex items-center">
          <div class="shrink-0">
            <MdiIcon :icon="mdiAlertCircleOutline" class="mr-2 size-4" />
          </div>
          <div>
            {{ $t('display.config.url_comment') }}
          </div>
        </div>
      </div>

      <!-- Display Profile -->
      <div class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm border-b pb-2 mb-4">
          {{ $t('display.profile.title') }}<br>
        </div>
        <div class="flex gap-2">
          <div class="flex-1">
            <FormDropdown
              v-model="activeDisplayProfile"
              name="profile"
              :options="displayProfilesOptions"
            />
          </div>
          <div class="flex gap-2">
            <ActionButton
              v-tooltip="$t('common.actions.create')"
              v-can="'display.manage_display_profiles'"
              icon="plus"
              displayMode="icon"
              @click="createProfilePopupVisible = true"
            />
            <ActionButton
              v-if="activeDisplayProfile"
              v-can="'display.manage_display_profiles'"
              v-tooltip="$t('common.actions.delete')"
              icon="trash"
              displayMode="icon"
              @click="pendingDeleteProfile = activeDisplayProfile"
            />
          </div>
        </div>
      </div>

      <!-- General Settings -->
      <div v-if="canManageProfiles" class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm border-b pb-2 mb-4">
          {{ $t('display.config.display_settings') }}<br>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-4">
          <div>
            <FormDropdown
              v-model="activeDisplayProfileData.locale"
              name="locale"
              label="display.profile.fields.locale"
              :options="languageOptions"
              @change="dirty = true"
            />
          </div>
          <div>
            <FormDropdown
              v-model="activeDisplayProfileData.font_size"
              name="fontSize"
              label="display.profile.fields.font_size"
              :options="fontSizeOptions"
              @change="dirty = true"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <FormCheckbox
              v-model="activeDisplayProfileData.show_video_stream"
              name="videoStream"
              label="display.profile.fields.show_video_stream"
              @change="dirty = true"
            />
          </div>
          <div>
            <FormCheckbox
              v-model="activeDisplayProfileData.fullscreen"
              name="fullscreen"
              label="display.profile.fields.fullscreen"
              @change="dirty = true"
            />
          </div>
        </div>
      </div>

      <DisplayProfileSystemsSettings
        v-if="canManageProfiles && activeDisplayProfile && Number(activeDisplayProfile) > 0"
        :modelValue="activeDisplayProfileData?.settings ?? []"
        :systems="systems"
        @update:modelValue="updateSystemSettings"
        @changeState="changeSystemEnabled"
      />
    </div>

    <template #after>
      <transition name="fade">
        <div
          v-if="activeDisplayProfile && dirty && canManageProfiles"
          class="sticky bottom-0 w-full bg-white shadow p-4 border-t border-gray-300 flex justify-end items-center"
        >
          <ActionButton type="primary" @click="updateProfile">
            {{ $t('common.actions.save') }}
          </ActionButton>
        </div>
      </transition>
    </template>
  </Drawer>

  <teleport to="body">
    <transition name="popup">
      <Popup v-if="createProfilePopupVisible" :title="$t('display.modals.create_profile.title')">
        <p class="mb-4">
          {{ $t('display.modals.create_profile.text') }}
        </p>

        <FormInput
          v-model="createProfileName"
          name="name"
          label="common.name"
          autofocus
        />

        <template #actions>
          <ActionButton @click="activeDisplayProfile = null; createProfilePopupVisible = false">
            {{ $t('common.actions.cancel') }}
          </ActionButton>
          <ActionButton :disabled="!createProfileName" type="primary" @click="createProfile">
            {{ $t('common.actions.create') }}
          </ActionButton>
        </template>
      </Popup>
    </transition>

    <transition name="popup">
      <ConfirmPopup
        v-if="pendingDeleteProfile"
        title="display.modals.delete_profile.title"
        text="display.modals.delete_profile.text"
        action="display.modals.delete_profile.action"
        @cancel="pendingDeleteProfile = null"
        @confirm="deleteProfile"
      />
    </transition>
  </teleport>
</template>