<template>
  <div class="h-screen bg-gray-200 flex justify-center items-center px-12 setup-screen">
    <div class="border border-gray-200 bg-white shadow-md rounded w-full max-w-2xl mx-auto">
      <div class="flex px-8 py-4 items-center border-b border-gray-200 relative">
        <CSLogo />
        <div class="text-sm ml-6 font-semibold text-gray-800">
          {{ activeStep.name }}
        </div>
        <div class="flex justify-center items-center absolute inset-0">
          <nav class="flex items-center justify-center" aria-label="Progress">
            <ol class="flex items-center space-x-5">
              <li v-for="(currentStep, index) in steps" :key="currentStep.name">
                <span
                  v-if="(index + 1) < step"
                  class="block w-2.5 h-2.5 bg-csBlue-600 rounded-full"
                >
                  <span class="sr-only">{{ currentStep.name }}</span>
                </span>
                <span
                  v-else-if="(index + 1 ) === step"
                  class="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                    <span class="w-full h-full rounded-full bg-csBlue-150" />
                  </span>
                  <span class="relative block w-2.5 h-2.5 bg-csBlue-600 rounded-full" aria-hidden="true" />
                  <span class="sr-only">{{ currentStep.name }}</span>
                </span>
                <span v-else class="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                  <span class="sr-only">{{ currentStep.name }}</span>
                </span>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="content p-8 text-base leading-snug text-gray-800">
        <component :is="activeStep.component" :state="state" @setState="setState($event)" />
      </div>
      <div class="flex justify-between px-8 py-4 bg-gray-100">
        <div>
          <ActionButton v-if="step > 1" class="mr-4" @click.prevent="step = step - 1">
            {{ $t('common.actions.prev') }}
          </ActionButton>
        </div>
        <div>
          <ActionButton
            v-show="!activeStep.hideAction"
            :type="activeStep.actionLabel && step < steps.length ? 'secondary' : 'primary'"
            :disabled="!stepIsComplete"
            @click.prevent="next"
          >
            {{ activeStep.actionLabel ? activeStep.actionLabel : $t('common.actions.next') }}
            <span v-if="step === steps.length" class="inline-block font-bold ml-4">
              &rarr;
            </span>
          </ActionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch, watchEffect } from 'vue'
  import Start from '@/modules/setup/steps/Start.vue'
  import License from '@/modules/setup/steps/License.vue'
  import { useStore } from '@/store'
  import InitialUser from '@/modules/setup/steps/InitialUser.vue'
  import Configurator from '@/modules/setup/steps/Configurator.vue'
  import Finished from '@/modules/setup/steps/Finished.vue'
  import { useI18n } from 'vue-i18n'
  import router from '@/bootstrap/router'
  import CSLogo from '@/common/components/svg/CSLogo.vue'
  import { SetupState } from '@/modules/setup/Setup.api'
  import { loadLocale } from '@/bootstrap/i18n'
  import { Locale } from '@/common/graphql/types'

  export default defineComponent({
    name: 'SetupView',
    components: { Start, License, InitialUser, Configurator, Finished, CSLogo },
    setup () {
      const store = useStore()
      const i18n = useI18n()
      const state = ref({
        locale: i18n.locale.value.toUpperCase(),
        license: store.state.license.license,
        user: store.state.user.user,
        configurator: store.state.app.configuratorIP,
      } as SetupState)

      const license = computed(() => store.state.license.license)
      watch(() => license.value, newV => {
        if (newV) {
          state.value.license = newV
        }
      })
      const user = computed(() => store.state.user.user)
      watch(() => user.value, newV => {
        if (newV) {
          state.value.user = newV
        }
      })
      const configurator = computed(() => store.state.app.configuratorIP)
      watch(() => configurator.value, newV => {
        if (newV) {
          state.value.configurator = newV
        }
      })
      watch(() => state.value.locale, newV => {
        if (newV) {
          loadLocale(newV.toUpperCase() as Locale)
        }
      })

      const steps = computed(() => [
        { name: i18n.t('setup.start.label'), component: 'start' },
        { name: i18n.t('setup.license.label'), component: 'license', actionLabel: state.value.license?.id && state.value.license.id > 0 ? '' : i18n.t('setup.license.action') },
        { name: i18n.t('setup.start.label'), component: 'configurator', actionLabel: state.value.configurator ? '' : i18n.t('setup.gets.action') },
        { name: i18n.t('setup.admin.label'), component: 'initial-user', hideAction: !state.value.user?.id },
        { name: i18n.t('setup.finished.label'), component: 'finished', actionLabel: i18n.t(`setup.finished.${state.value.configurator ? 'action_sync' : 'action_home'}`) },
      ])
      const validations: (() => boolean)[] = [
        () => state.value.locale.toString() !== '',
        () => true,
        () => true,
        () => !!state.value.user?.id,
        () => true,
      ]
      const step = ref(1)
      const stepIsComplete = computed(() => validations[step.value - 1]())
      const activeStep = computed(() => steps.value[step.value - 1])

      // If no user is signed in, but the setup was already completed once,
      // redirect the user to the login screen.
      const isSetUp = computed(() => store.state.app.setupTime)
      watchEffect(() => {
        if (isSetUp.value && !user.value.id) {
          router.push({ name: 'login' })
        }
      })

      function setState (update: [string, unknown]) {
        const [key, value] = update
        state.value[key] = value
      }

      function next () {
        if (step.value === steps.value.length) {
          return router.push({ name: state.value.configurator ? 'sync' : 'homepage' })
        }
        step.value += 1
      }

      return {
        steps,
        step,
        activeStep,
        stepIsComplete,
        state,
        setState,
        next,
      }
    }
  })
</script>

<style lang="stylus" scoped>
.content
  min-height 260px
  display flex
  flex-direction column
  justify-content center
</style>

<style lang="stylus">
.setup-screen .input-comment
  text-align left
</style>