<script setup lang="ts">
  import FormSwitch from '@/common/components/form/FormSwitch.vue'
  import { DisplayProfileSettings, SystemParam } from '@/common/graphql/types'
  import { computed, PropType, ref } from 'vue'
  import DisplayProfileSystemsSchedule from '@/modules/display/components/DisplayProfileSystemsSchedule.vue'

  const emit = defineEmits(['update:modelValue', 'changeState'])
  const props = defineProps({
    systems: {
      type: Array as PropType<ReadonlyArray<SystemParam>>,
      default: () => []
    },
    modelValue: {
      type: Array as PropType<ReadonlyArray<DisplayProfileSettings>>,
      default: () => ([])
    }
  })

  type DisplayProfileSettingsWithConfig = DisplayProfileSettings & { enabled?: boolean, system_id?: number }

  const preselectedSystem = props.modelValue?.[0]?.system_id

  const activeSystem = ref(props.systems.find((system) => Number(system.id) === Number(preselectedSystem)) || props.systems[0])
  const activeConfig = computed(() => props.modelValue.find((setting) => setting.system_id === Number(activeSystem.value.id)) as DisplayProfileSettingsWithConfig)

  function systemIsEnabled (checkSystem: SystemParam) {
    const system = props.modelValue.find((setting) => setting.system_id === Number(checkSystem.id)) as DisplayProfileSettingsWithConfig
    return !!system?.system_id && system?.enabled !== false
  }

  function updateValue (value: any) {
    emit('update:modelValue', {
      system_id: Number(activeSystem.value.id),
      ...value
    })
  }
</script>

<template>
  <div>
    <div class="overflow-x-auto border-b border-gray-300 scrollbar-thin">
      <ul class="flex text-xs">
        <li
          v-for="system in systems"
          :key="system.id"
          class="first:rounded-tl last:rounded-tr border-gray-300 border-l border-t last:border-r whitespace-nowrap"
        >
          <a
            href="#"
            class="block px-4 py-2.5 bg-white whitespace-nowrap"
            :class="{ 'opacity-40 !bg-gray-100': system.id !== activeSystem.id }"
            @click.prevent="activeSystem = system"
          >
            {{ system.name }}
          </a>
        </li>
      </ul>
    </div>

    <div
      v-for="system in systems"
      v-show="system.id === activeSystem.id"
      :key="system.id"
      class="bg-white border border-gray-300 border-t-0 p-4 rounded-b shadow"
    >
      <div>
        <FormSwitch
          name="show_calls_from_system"
          :modelValue="systemIsEnabled(system)"
          :label="$t('display.profile.fields.show_calls_from_system', [system.name])"
          :comment="$t('display.profile.fields.show_calls_from_system_comment')"
          @update:modelValue="updateValue({ ...activeConfig, enabled: $event })"
        />
      </div>
      <DisplayProfileSystemsSchedule
        v-if="systemIsEnabled(system)"
        class="mt-4"
        :system="system"
        :config="activeConfig"
        @update:modelValue="updateValue"
      />
    </div>
  </div>
</template>