<script setup lang="ts">
  import { computed } from 'vue'
  import { useStore } from '@/store'
  import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/outline'
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { useAppDataLoader } from '@/common/services/appdata/AppData.api'
  import Spinner from '@/common/components/svg/Spinner.vue'
  import { SystemParam } from '@/common/graphql/types'

  const store = useStore()
  const loading = computed(() => store.state.app.systemContextLoading)

  const { fetch: fetchAppData } = useAppDataLoader(store)

  const selectedSystemId = computed(() => store.state.app.systemContext)
  const systems = computed<SystemParam[]>(() => store.getters['app/availableSystems'])
  const currentSystem = computed(() => systems.value.find(s => Number(s.id) === Number(selectedSystemId.value)))

  async function setSystemContext (systemId: string) {
    await store.dispatch('app/setSystemContext', { systemId, fetchAppData })
  }

  const hasMultipleWithSameName = computed(() => {
    const systemNames = systems.value.map(s => s.name)
    return systemNames.some((name, index) => systemNames.indexOf(name) !== index)
  })
</script>

<template>
  <div class="flex-inline relative" data-testid="system-selector">
    <Menu>
      <MenuButton
        class="flex items-center w-full justify-center py-2 px-3 rounded-md hover:bg-white/10 text-xs text-white/75 hover:text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        data-testid="system-selector__button"
      >
        <div v-if="currentSystem" class="max-w-[140px] truncate leading-tight" data-testid="system-selector__current">
          {{ currentSystem.name }}
          <span v-if="hasMultipleWithSameName">
            ({{ currentSystem.id }})
          </span>
        </div>
        <ChevronDownIcon
          class="ml-2 h-3.5 w-3.5"
          aria-hidden="true"
        />
        <transition
          enterActiveClass="transition duration-100 ease-out"
          enterFromClass="transform opacity-0"
          enterToClass="transform opacity-100"
          leaveActiveClass="transition duration-75 ease-in"
          leaveFromClass="transform opacity-100"
          leaveToClass="transform opacity-0"
        >
          <Spinner
            v-if="loading"
            class="ml-4"
            width="16"
            height="16"
            color="#fff"
          />
        </transition>
      </MenuButton>
      <transition
        enterActiveClass="transition duration-100 ease-out"
        enterFromClass="transform scale-95 opacity-0"
        enterToClass="transform scale-100 opacity-100"
        leaveActiveClass="transition duration-75 ease-in"
        leaveFromClass="transform scale-100 opacity-100"
        leaveToClass="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute left-0 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="text-3xs font-semibold text-gray-600 uppercase tracking-wide px-4 pt-3">
            {{ $t('systemparam.plural') }}
          </div>
          <div class="p-2" data-testid="system-selector__list">
            <MenuItem v-for="system in systems" :key="system.id" v-slot="{ active }">
              <button
                :class="[
                  active ? 'bg-gray-200 text-gray-800' : 'text-gray-800',
                  'group flex w-full items-center rounded-md px-3 py-2.5 text-sm leading-tight',
                ]"
                @click="setSystemContext(system.id)"
              >
                <div class="w-7 flex-shrink-0">
                  <CheckIcon
                    v-if="Number(system.id) === Number(selectedSystemId)"
                    class="w-4 stroke-csBlue-600 relative top-px"
                  />
                </div>
                <div class="truncate">
                  {{ system.name }}
                  <span v-if="hasMultipleWithSameName">
                    ({{ system.id }})
                  </span>
                </div>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
