<script setup lang="ts">
  import { defaultFormInputProps, useFormProps } from '@/common/components/form/Forms.api'
  import { computed, onMounted, ref } from 'vue'

  defineOptions({
    inheritAttrs: false,
  })

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps({
    ...defaultFormInputProps,
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
    },
    inputClass: {
      type: String,
      default: 'text-base'
    },
    autoResize: {
      type: Boolean,
      default: true
    }
  })

  const textarea = ref<HTMLTextAreaElement>()
  const { realId, uniqueId, realComment, mergedContainerClasses, error } = useFormProps(props as any)

  const hasIcon = computed(() => {
    // TODO: Check if the icon slot is actually in use. This code broke with the Vue 3 update.
    return false
  })

  const mergedInputClasses = computed(() => {
    return [
      { 'loading-shimmer': props.loading },
      { 'pr-10': hasIcon.value },
      { 'border-red-400 text-red-900 placeholder-red-300 focus:border-red-400 focus:ring-red': error.value },
      props.inputClass
    ]
  })

  onMounted(() => {
    resizeArea()
  })

  function resizeArea () {
    if (!props.autoResize) {
      return
    }

    if (textarea.value) {
      textarea.value.style.height = '0px'
      textarea.value.style.height = (textarea.value.scrollHeight) + 'px'
    }
  }

  function onInput () {
    if (textarea.value) {
      emit('update:modelValue', textarea.value.value)
      resizeArea()
    }
  }
</script>

<template>
  <div class="cs-form-input" :class="mergedContainerClasses">
    <label
      v-if="label"
      class="flex justify-between items-center text-sm leading-5 text-gray-700 mb-1"
      :for="uniqueId"
    >
      <span class="truncate">{{ $t(label) }}</span>
      <svg
        v-if="help"
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 fill-current flex-shrink-0 ml-2 hover:text-gray-700"
        viewBox="0 0 512 512"
      >
        <path
          d="M256 0C114.509 0 0 114.496 0 256c0 141.489 114.496 256 256 256 141.491 0 256-114.496 256-256C512 114.509 397.504 0 256 0zm0 476.279c-121.462 0-220.279-98.816-220.279-220.279S134.538 35.721 256 35.721c121.463 0 220.279 98.816 220.279 220.279S377.463 476.279 256 476.279z"
        />
        <path
          d="M248.425 323.924c-14.153 0-25.61 11.794-25.61 25.946 0 13.817 11.12 25.948 25.61 25.948s25.946-12.131 25.946-25.948c0-14.152-11.794-25.946-25.946-25.946zM252.805 127.469c-45.492 0-66.384 26.959-66.384 45.155 0 13.142 11.12 19.208 20.218 19.208 18.197 0 10.784-25.948 45.155-25.948 16.848 0 30.328 7.414 30.328 22.915 0 18.196-18.871 28.642-29.991 38.077-9.773 8.423-22.577 22.24-22.577 51.22 0 17.522 4.718 22.577 18.533 22.577 16.511 0 19.881-7.413 19.881-13.817 0-17.522.337-27.631 18.871-42.121 9.098-7.076 37.74-29.991 37.74-61.666s-28.642-55.6-71.774-55.6z"
        />
      </svg>
    </label>
    <div class="input-group relative rounded-md shadow-sm flex">
      <textarea
        v-bind="$attrs"
        :id="uniqueId"
        ref="textarea"
        class="form-input flex-grow block w-full border-gray-300 rounded-md focus:border-csBlue-300 focus:ring focus:ring-csBlue-300 focus:ring-opacity-50 sm:text-sm sm:leading-5"
        :class="mergedInputClasses"
        :disabled="disabled"
        :value="modelValue"
        :readonly="readonly"
        autocomplete="off"
        @input="onInput"
      />
      <div class="absolute inset-y-0 right-0 flex items-center pointer-events-none">
        <slot name="icon" />
      </div>
    </div>
    <p v-if="realComment" class="input-comment text-gray-400 text-xs mt-2">
      {{ $t(realComment, { field: realId }) }}
    </p>
  </div>
</template>

<style lang="stylus" scoped>
  textarea
    overflow-y hidden
    resize none
    height 36px
    min-height 72px

  &.disabled
    .input-group .form-input
      @apply bg-gray-100 cursor-not-allowed
</style>
