import { Ref } from 'vue'
import { transformErrors } from '@/common/components/form/Forms.api'
import { Composer } from 'vue-i18n'

export function transformLicenseErrors (i18n: Composer, response: any, errors: Ref<Record<string, unknown>>, callout: Ref<string>) {
  const nonValidation = response.graphQLErrors.filter((err: Record<string, Record<string, unknown>>) => !err?.extensions?.validation)
  if (nonValidation.length > 0) {
    if (nonValidation[0].message.indexOf('activation failed') > -1) {
      const code = nonValidation[0].message.replace('activation failed: ', '')
      callout.value = i18n.t(`system.license.activation_errors.${code}`).toString()
    } else {
      callout.value = nonValidation
    }
  }
  errors.value = transformErrors(response.graphQLErrors)
}