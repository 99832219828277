<script setup lang="ts">
  import { defaultFormInputProps, FormDropdownOption, useFormProps } from '@/common/components/form/Forms.api'
  import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { PropType, ref, watchEffect } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { autoPlacement, autoUpdate, useFloating } from '@floating-ui/vue'

  const emit = defineEmits(['update:modelValue', 'change'])
  const props = defineProps({
    ...defaultFormInputProps,
    options: {
      type: Array as PropType<ReadonlyArray<FormDropdownOption>>,
      default: () => []
    },
    // By default, the active option gets a "tick"
    markActive: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
  })

  const i18n = useI18n()
  const { realId, realComment, mergedContainerClasses } = useFormProps(props as any)

  const defaultOption = {
    value: '',
    label: `-- ${props.placeholder ? props.placeholder : i18n.t('common.forms.please_select')}`
  }

  const selectedOption = ref<FormDropdownOption>(defaultOption)

  watchEffect(() => {
    const newSelectedOption = props.options.find(option => option.value == props.modelValue)
    if (newSelectedOption) {
      selectedOption.value = newSelectedOption
    } else {
      selectedOption.value = defaultOption
    }
  })

  // emitInputValue makes sure the value is emitted with the same type
  // as was passed into the component. This makes sure a int ID is not
  // converted to a string ID when using this dropdown.
  function emitInputValue (option: FormDropdownOption) {
    selectedOption.value = option

    // pass the change to the parent component
    const value = option.value
    if (typeof props.modelValue === 'number') {
      emit('update:modelValue', value === null ? null : Number(value))
    } else {
      emit('update:modelValue', value)
    }
    emit('change')
  }

  const listboxOptions = ref(null)
  const button = ref(null)

  const { floatingStyles } = useFloating(button, listboxOptions, {
    whileElementsMounted: autoUpdate,
    middleware: [autoPlacement({
      allowedPlacements: ['bottom-start', 'top-start'],
    })],
  })
</script>

<template>
  <div class="cs-form-input form-dropdown" :class="mergedContainerClasses">
    <div class="w-full">
      <Listbox
        v-slot="{ open }"
        as="div"
        class="listbox"
        :class="{'listbox--loading': loading }"
        :modelValue="selectedOption"
        :data-testid="id ? id : undefined"
        @update:modelValue="emitInputValue($event)"
      >
        <div class="flex justify-between items-center">
          <ListboxLabel v-if="label" class="text-sm leading-5 text-gray-700">
            {{ $t(label) }}
          </ListboxLabel>
          <slot name="label" />
        </div>
        <div class="relative" :class="{'mt-1': label}">
          <span ref="button" class="inline-block w-full rounded-md shadow-sm">
            <ListboxButton
              class="listbox__button cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-2.5 pr-10 py-2 text-left focus:outline-none focus:ring-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              <div class="flex items-center">
                <slot name="icon" v-bind="{ option: selectedOption }" />
                <span class="block truncate">
                  {{ selectedOption.label }}
                </span>
              </div>
              <span
                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </ListboxButton>
          </span>
          <transition
            leaveActiveClass="transition ease-in duration-100"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <div
              v-if="open && !disabled"
              class="absolute w-full rounded-md bg-white shadow-lg z-50"
            >
              <ListboxOptions
                ref="listboxOptions"
                static
                class="absolute bg-white shadow max-h-60 z-40 w-full rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                :style="floatingStyles"
              >
                <ListboxOption
                  v-for="option in options"
                  :key="option.value !== null ? option.value : '<null>'"
                  v-slot="{ selected, active }"
                  :value="option"
                  :data-testid="`list-item-${id ? id : 'unknown'}-${option.value}`"
                >
                  <div
                    :class="`${
                      active ? 'bg-gray-100' : 'text-gray-900'
                    } cursor-pointer select-none relative py-2 pl-9 pr-4`"
                  >
                    <span
                      :class="`${
                        selected ? 'font-semibold' : 'font-normal'
                      } block truncate`"
                    >
                      {{ option.label }}
                    </span>
                    <span
                      :class="`text-csBlue-600 absolute inset-y-0 left-0 flex items-center pl-2.5`"
                    >
                      <slot v-if="!selected || !markActive" name="icon" v-bind="{ option }" />
                      <svg
                        v-else-if="selected && markActive"
                        class="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </transition>
        </div>
      </Listbox>
    </div>
    <p v-if="realComment" class="input-comment text-gray-700 text-xs mt-2">
      {{ $t(realComment, {field: realId}) }}
    </p>
  </div>
</template>

<style lang="stylus" scoped>
  &.disabled
    .listbox__button
      @apply bg-gray-100 cursor-not-allowed
</style>
