import { AppDataQuery, SystemParam } from '@/common/graphql/types'
import { useQuery } from '@vue/apollo-composable'
import { Store } from 'vuex'
import { State } from '@/store'
import { useI18n } from 'vue-i18n'

import QueryAppData from '../../graphql/QueryAppData.gql'
import { ref } from 'vue'

// useAppDataLoader fetches the required app data from the backend (this request is run on every page load).
export function useAppDataLoader (store: Store<State>) {
  // systemContextFixed is true when the user had a system context set, that is
  // not available. We only try to change this once to avoid an infinite loop.
  const systemContextHasBeenFixed = ref(false)

  const i18n = useI18n()

  const enabled = ref(false)

  const {
    refetch: refetchAppData,
    onError,
    onResult,
  } = useQuery<AppDataQuery>(QueryAppData, () => ({
    hasSession: store.state.user.user.id > 0,
  }), () => ({
    enabled: enabled.value,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'ignore' // Ignore missing session errors if the user is not logged in.
  }))

  // enforceAvailableSystemContext makes sure that the current system context is available.
  async function enforceAvailableSystemContext () {
    // We only ever try to change the system context once.
    if (systemContextHasBeenFixed.value) {
      return
    }

    systemContextHasBeenFixed.value = true

    const available = store.getters['app/availableSystems'] as SystemParam[]
    const current = store.state.app.systemContext
    const isAvailable = available.some(s => Number(s.id) === Number(current))

    // Do nothing if no systems are available or the currently selected system is available.
    if (isAvailable || available.length === 0) {
      return
    }

    await store.dispatch('app/setSystemContext', {
      systemId: available[0].id,
      fetchAppData: fetch,
    })
  }

  let resultPromise: (data: any) => void

  onResult(async result => {
    if (!result?.loading && result?.data) {
      const data = result.data
      store.commit('user/set', data.authUser)
      store.commit('app/setSettings', data.globalSettings)

      if (data?.license) {
        store.commit('license/set', data.license)
      }

      if (data?.appState) {
        await store.dispatch('app/setState', data)
        await enforceAvailableSystemContext()
      }

      if (resultPromise) {
        resultPromise(result.data)
      }
    }
  })

  onError((e) => {
    // Don't show a popup of the session has expired.
    if (e.message.indexOf('MISSING_AUTH') > -1) {
      return
    }
    store.commit('notifications/notify', {
      type: 'error',
      title: i18n.t('common.notifications.app_data.title'),
      text: i18n.t('common.notifications.app_data.text'),
    })
  })

  async function fetch (forceSystemContextFix: boolean = false) {
    if (forceSystemContextFix) {
      systemContextHasBeenFixed.value = false
    }

    if (enabled.value) {
      return await refetchAppData(() => ({
        hasSession: store.state.user.user.id > 0,
      }))
    }

    enabled.value = true

    return new Promise((resolve) => {
      resultPromise = resolve
    })
  }

  return {
    onError,
    fetch,
  }
}
