<script setup lang="ts">
  import { PropType } from 'vue'
  import { NotificationActions } from '@/common/components/notification/Notification.api'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const props = defineProps({
    type: {
      type: String,
      default: 'success',
    },
    actions: {
      type: Object as PropType<NotificationActions>,
      default: () => ({}),
    }
  })

  function onClickPrimary () {
    if (props.actions?.primary?.action) {
      props.actions.primary.action()
    }
    emit('close')
  }

  function onClickDismiss () {
    if (props.actions?.dismiss?.action) {
      props.actions.dismiss.action()
    }
    emit('close')
  }
</script>

<template>
  <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
    <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <svg
              v-if="type === 'success'"
              class="h-6 w-6 text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <svg
              v-else-if="type === 'error'"
              style="transform: translateY(1px)"
              class="h-5 w-5 text-red-400 fill-current"
              viewBox="0 0 400 400"
            >
              <path
                d="M276.833 149.082l-25.915-25.914L200 174.267l-50.918-51.099-25.914 25.914L174.267 200l-51.099 50.918 25.914 25.915 50.918-51.1 50.918 51.1 25.915-25.915-51.1-50.918 51.1-50.918zM200 17.5C99.209 17.5 17.5 99.209 17.5 200c0 100.792 81.709 182.5 182.5 182.5 100.792 0 182.5-81.708 182.5-182.5 0-100.791-81.708-182.5-182.5-182.5zm0 331.968c-82.549 0-149.468-66.919-149.468-149.468 0-82.549 66.919-149.468 149.468-149.468 82.507.1 149.367 66.96 149.468 149.468 0 82.549-66.919 149.468-149.468 149.468z"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <div class="text-sm leading-5 font-semibold text-gray-900">
              <slot name="title" />
            </div>
            <div class="mt-1 text-sm leading-5 text-gray-500">
              <slot />
            </div>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              @click="$emit('close')"
            >
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="actions && (actions.primary || actions.dismiss)"
        class="flex border-t divide-x text-sm text-center"
      >
        <button
          v-if="actions.primary && actions.primary.label"
          class="flex-1 flex-shrink-0 p-2.5 hover:bg-csBlue-50"
          @click.prevent="onClickPrimary"
        >
          {{ actions.primary.label }}
        </button>
        <button
          v-if="actions.dismiss && actions.dismiss.label"
          class="flex-1 flex-shrink-0 p-2.5 hover:bg-csBlue-50"
          @click.prevent="onClickDismiss"
        >
          {{ actions.dismiss.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .button
    &[disabled]
      opacity .8
      cursor not-allowed
      filter grayscale(.5)
</style>

<style lang="stylus">
  .button
    .loading-spinner__spinner
      width 20px !important
      height 20px !important
</style>