// afterEachHook is used to check if the user navigated to a route that has
import { logWarning } from '@/common/utils'
import { RouteLocation, Router, RouteRecordRaw } from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store'
import { computed, Ref } from 'vue'
import { UserState } from '@/store/user.state'
import { onlyOnePermissionRequired } from '@/bootstrap/router'
import { I18n } from '@/bootstrap/i18n'

export function beforeEachHook (router: Router, store: Store<State>, i18n: I18n) {
  return (to: RouteLocation, from: RouteLocation, next: any) => {
    const state = computed(() => store.state.user)
    const managementLicensed = computed(() => store.getters['license/isLicensed']('management'))
    const canAccessLog = computed(() => store.getters['user/can']('calllog::use_module'))
    const canAccessTree = computed(() =>
      (store.getters['user/can']('systemtree::use_module')
      || store.getters['user/can']('systemtree::see_display_group_overrides'))
      && managementLicensed.value
    )

    // If the user tries to access the system tree but the management module is not licensed,
    // return a redirect to the call log instead. We handle this here because the state (user/license)
    // may not be available when Vue router loads the route definitions for the first time.
    if (to.name === 'tree' && state.value.user.id > 0 && !state.value.user.is_superuser && !canAccessTree.value) {
      if (canAccessLog.value) {
        return next({ name: 'log' })
      }
      return next({ name: 'display' })
    }

    // We cannot use `to.meta.redirectToFirstAvailableChild` since Vue includes the meta data
    // of parent routes in child routes as well. This would lead to redirects for all
    // child routes. By accessing the route from the matched array we get the original meta data.
    const matchedRoute = to.matched[to.matched.length - 1]
    if (matchedRoute?.meta.redirectToFirstAvailableChild) {
      return matchedRoute.name ? next(redirectToFirstAvailableChild(router, store, String(matchedRoute.name))) : { name: 'accessDenied' }
    }

    if (routeNeedsSessionButThereIsNone(state, to)) {
      return next({ name: 'login' })
    }

    let accessDenied = false

    const needsPermission = to.matched.reverse().find(route => needsPermissionCheck(route))
    const needsLicense = to.matched.reverse().find(route => needsLicenseCheck(route))

    if (needsPermission && !canAccess(needsPermission, store)) {
      logWarning(`not all of the required permissions for route ${String(to.name)} are available:`, to.meta.permissions)
      accessDenied = true
    }

    if (needsLicense && !isLicensed(needsLicense, store)) {
      logWarning(`the required module ${to.meta.license} is not licensed but required for route ${String(to.name)}`)
      accessDenied = true
    }

    if (accessDenied) {
      store.commit('notifications/notify', {
        // @ts-ignore
        title: i18n.global.t('user.notifications.access_denied.title'),
        // @ts-ignore
        text: i18n.global.t('user.notifications.access_denied.text'),
        type: 'error'
      })
      return next({ name: 'accessDenied' })
    }
    
    return next()
  }
}

// check if the user has all permissions to access a route.
export function canAccess (to: RouteRecordRaw, store: Store<State>) {
  let getter = 'user/canAll'

  const permissions = (to.meta?.permissions ?? []) as (string|symbol)[]
  if (permissions.length > 1 && permissions[0] === onlyOnePermissionRequired) {
    getter = 'user/canAny'
  }

  // Check if the "if" clause on the route is blocking access.
  let ifClauseBlocked = false
  if (to.meta?.if && typeof to.meta.if === 'function') {
    ifClauseBlocked = !to.meta.if()
  }

  return !needsPermissionCheck(to) || (store.getters[getter](to?.meta?.permissions) && !ifClauseBlocked)
}

// check if the required license modules are available.
export function isLicensed (to: RouteRecordRaw, store: Store<State>) {
  return !needsLicenseCheck(to) || store.getters['license/isLicensed'](to?.meta?.license)
}

// check if the route object has permissions set on the meta key.
function needsPermissionCheck (to: RouteRecordRaw) {
  return to.meta?.permissions && Array.isArray(to.meta.permissions)
}

// check if the route object has license checks set on the meta key.
function needsLicenseCheck (to: RouteRecordRaw) {
  return to.meta?.license && Array.isArray(to.meta.license)
}

function routeNeedsSessionButThereIsNone (state: Ref<UserState>, to: RouteLocation) {
  return to.meta.authRequired !== false && state.value.user.id < 1
}

// this function returns the first child route the user has access to.
function redirectToFirstAvailableChild (router: Router, store: Store<State>, parentName: string) {
  const route = router.getRoutes()?.find((r: RouteRecordRaw) => r.name === parentName)
  
  // Ignore redirects, because we cannot easily check if the target route is accessible.
  // This also prevents infinite loops.
  const firstAvailable = route?.children?.find((r: RouteRecordRaw) => canAccess(r, store) && !r.redirect)

  // If a user is logged in, display the access denied page, otherwise redirect to the login page.
  const redirectRoute = store.state.user.user.id ? 'accessDenied' : 'login'

  return firstAvailable ? firstAvailable : { name: redirectRoute }
}
