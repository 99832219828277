<template>
  <div class="modal-stack" :class="{'pointer-events-none' : stack.length < 1}">
    <transition-group name="slide" :duration="200">
      <div v-for="(item, level) in stack" :key="item.key" class="modal-stack__backdrop" @click="onClick">
        <div
          class="modal-stack__canvas"
          :style="{transform: `translateX(${(((stack.length - 1 - level) * 100) - 100) * -1}px)`}"
        >
          <component :is="item.component" v-bind="item.props" class="h-full bg-gray-50" />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
  import { useModalStack } from '@/common/components/modal/ModalStack.api'
  import { defineComponent, watch } from 'vue'

  // The modal stack emulates a child-router. It renders the component of a child
  // route inside a modal. The stack is kept in the vuex store.
  export default defineComponent({
    name: 'ModalStack',
    setup () {
      const { onClick, stack } = useModalStack()
      const htmlElement = document.documentElement
      const scrollbarWidth = getScrollbarWidth()

      watch(stack, () => {
        if (stack.length > 0 && isOverflown(htmlElement)) {
          htmlElement.style.overflow = 'hidden'
          htmlElement.style.paddingRight = `${scrollbarWidth}px`
        } else {
          htmlElement.style.overflow = 'auto'
          htmlElement.style.paddingRight = '0px'
        }
      })

      function isOverflown (element: HTMLElement) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
      }

      function getScrollbarWidth () {
        const outer = document.createElement('div')
        outer.style.visibility = 'hidden'
        outer.style.overflow = 'scroll'
        document.body.appendChild(outer)

        const inner = document.createElement('div')
        outer.appendChild(inner)

        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)
        outer.parentNode?.removeChild(outer)

        return scrollbarWidth
      }

      return {
        onClick,
        stack,
      }
    }
  })
</script>

<style lang="stylus" scoped>
.modal-stack
  @apply inset-0 fixed h-full z-30

  &__backdrop
    @apply inset-0 fixed h-full
    background rgba(0, 0, 0, .4)

  &__canvas
    @apply fixed inset-y-0 right-0 bg-white h-full w-full
    max-width 1024px
    border-right 100px solid #fff
    box-shadow 0 -5px 20px rgba(0, 0, 0, .2)

.slide-enter-from,
.slide-leave-to
  opacity 0

  .modal-stack__canvas
    transform translateX(200px) !important

.slide-enter-active,
.slide-leave-active
  transition opacity .15s ease-out

.slide-enter-to,
.slide-leave-from
  opacity 1

  .modal-stack__canvas
    transform translateX(100px) !important

.modal-stack__canvas
  transition transform .15s ease-out
</style>
