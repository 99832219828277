<template>
  <div class="text-center">
    <CSLogo class="w-16 mb-8 mx-auto" />
    <p class="font-semibold mb-4">
      {{ $t('setup.finished.title') }}
    </p>
    <p class="mx-auto max-w-md">
      {{ $t('setup.finished.subtitle') }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import CSLogo from '@/common/components/svg/CSLogo.vue'
  import { SetupState } from '@/modules/setup/Setup.api'

  export default defineComponent({
    name: 'Finished',
    components: { CSLogo },
    props: {
      state: {
        type: Object as PropType<SetupState>,
        default: () => ({})
      }
    },
    setup () {
      return {}
    }
  })
</script>

