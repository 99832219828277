<template>
  <div :class="{'cursor-not-allowed': disabled}">
    <input
      v-bind="$attrs"
      :id="uniqueId"
      type="checkbox"
      class="hidden form-switch-input"
      :checked="isChecked"
      :disabled="disabled"
      :value="modelValue"
      @change="change"
    >
    <label
      :for="uniqueId"
      class="form-switch select-none cs-form-input flex"
      :class="[activeColor, { 'disabled': disabled }, containerClass]"
    >
      <span class="form-switch-toggle" />
      <div
        v-if="label || realComment"
        class="form-switch-label-container pl-5 text-sm leading-5"
      >
        <p v-if="label" class="form-switch-label text-gray-700">{{ $t(label) }}</p>
        <p v-if="realComment" class="input-comment text-gray-500 mt-1">{{ $t(realComment, {field: realId}) }}</p>
      </div>
    </label>
  </div>
</template>

<script lang="ts">
  import { defaultFormInputProps, useFormProps } from '@/common/components/form/Forms.api'
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'FormSwitch',
    inheritAttrs: false,
    props: {
      ...defaultFormInputProps,
      name: {
        required: false,
        type: String,
        default: '',
      },
      inputValue: {
        type: [Array, Boolean, String, Number, Object],
        default: '',
      },
      modelValue: {
        type: [Array, Boolean, String, Number, Object],
        default: '',
      },
      trueValue: {
        default: true,
        type: [Array, Boolean, String, Number, Object],
      },
      falseValue: {
        default: false,
        type: [Array, Boolean, String, Number, Object],
      },
      containerClass: {
        type: String,
        default: '',
      },
      activeColor: {
        type: String,
        default: 'text-csBlue-600',
      }
    },
    emits: ['update:modelValue'],
    setup (props, { emit }) {
      const { realId, uniqueId, realComment, mergedContainerClasses: preMergedContainerClasses, error } = useFormProps(props as any)

      const isChecked = computed(() => {
        return Array.isArray(props.modelValue)
          ? props.modelValue.includes(props.inputValue)
          : props.modelValue == props.trueValue
      })

      function change (e: Event) {
        if (props.disabled) {
          return
        }

        const isChecked = (e.target as HTMLInputElement)?.checked

        if (Array.isArray(props.modelValue)) {
          const newValue = [...props.modelValue]
          if (isChecked) {
            newValue.push(props.inputValue)
          } else {
            newValue.splice(newValue.indexOf(props.inputValue), 1)
          }
          emit('update:modelValue', newValue)
        } else {
          emit('update:modelValue', isChecked ? props.trueValue : props.falseValue)
        }
      }

      const mergedContainerClasses = computed(() => [...preMergedContainerClasses.value, props.containerClass])

      return {
        change,
        isChecked,
        error,
        realId,
        uniqueId,
        realComment,
        mergedContainerClasses
      }
    }
  })
</script>

<style lang="stylus" scoped>

  label
    @apply flex items-center

  .form-switch-toggle
    @apply w-9 h-5 cursor-pointer bg-gray-300 block relative
    border-radius 10px

    &:after
      @apply absolute bg-white
      border-radius 8px
      height 0.9rem
      width 0.9rem
      margin-top 3px
      content ''
      transition .2s ease-out
      transform translateX(4px)

    &:active:after
      width 1rem

  .form-switch-input:checked + label > .form-switch-toggle
    @apply bg-current

    &:after
      transform translateX(calc(100% + 3px))

  &.disabled
    @apply pointer-events-none

    .form-switch-label
      @apply text-gray-500

    .form-switch-toggle
      @apply text-gray-400

    .input-comment
      @apply text-gray-400

  .danger
    .input-comment
      @apply text-red-500 text-xs

    .form-switch
      @apply border-red-700

    .form-switch:checked
      @apply text-red-700

  .form-switch--small
    @apply text-xs
    .form-switch-toggle
      @apply w-7 h-4
      &:after
        height 0.65rem
        width 0.65rem

    .form-switch-label-container
      @apply text-xs pl-2

  .form-switch-input:checked + label.form-switch--small > .form-switch-toggle
    &:after
      transform translateX(calc(100% + 4px))

</style>
