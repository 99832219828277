<template>
  <svg viewBox="0 0 24 24" class="w-4">
    <path class="fill-current text-gray-500" d="M4 4h7l2 2h7a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2z" />
    <rect
      width="20"
      height="12"
      x="2"
      y="8"
      class="fill-current text-gray-300"
      rx="2"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SystemTreeIconGroup',
  })
</script>
