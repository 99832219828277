<template>
  <svg class="fill-current" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.414 15.586l-7-7a2 2 0 00-2.828 0l-7 7a2 2 0 102.828 2.828L21 14.828V54a2 2 0 004 0V14.828l3.586 3.586c.39.391.902.586 1.414.586s1.024-.195 1.414-.586a2 2 0 000-2.828zM49.414 45.586a2 2 0 00-2.828 0L43 49.172V10a2 2 0 00-4 0v39.172l-3.586-3.586a2 2 0 10-2.828 2.828l7 7c.391.391.902.586 1.414.586s1.023-.195 1.414-.586l7-7a2 2 0 000-2.828z"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'IconSort',
  })
</script>
