<template>
  <div
    class="h-72 flex items-center justify-center text-base text-gray-600 bg-white rounded border border-gray-200 shadow select-none"
  >
    <div class="flex flex-col items-center justify-center">
      <div class="mb-8">
        <IconBell class="w-12" />
      </div>
      <div>{{ $t('call.display.empty_state') }}</div>
    </div>
  </div>
</template>

<script lang="ts">
  import IconBell from '@/common/components/svg/IconBell.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'EmptyState',
    components: { IconBell }
  })
</script>
