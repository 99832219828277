import { User } from '@/modules/user/models/UserModel'
import { Permission, PermissionLookup, UserObject } from '@/modules/user/types/User.types'

export class UserCreator {
  /**
   * Return a null user instance.
   * @constructor
   */
  public static Null (): User {
    return new User(0, '')
  }

  /**
   * Build a new User from an Object.
   * @param data
   * @constructor
   */
  public static FromObject (data: UserObject): User {
    return new User(
      Number(data.id),
      data.name,
      data.is_superuser,
      data.locale,
      data.homepage,
      UserCreator.transformPermissions(data.permissions),
      data.roles?.map(a => String(a.id)) ?? [],
      data.system_access
    )
  }

  /**
   * Transform the permissions array to a lookup object.
   * @param permissions
   */
  protected static transformPermissions (permissions: Permission[]): PermissionLookup {
    const lookup: PermissionLookup = {}
    if (!Array.isArray(permissions)) return {}
    permissions.forEach(a => lookup[a] = true)
    return lookup
  }
}
