<template>
  <svg viewBox="0 0 24 24" class="w-4">
    <path
      class="fill-current text-gray-400"
      d="M9.17 16.17a1 1 0 1 1-1.41-1.41 6 6 0 0 1 8.48 0 1 1 0 0 1-1.41 1.41 4 4 0 0 0-5.66 0zm-2.83-2.83a1 1 0 0 1-1.41-1.41 10 10 0 0 1 14.14 0 1 1 0 0 1-1.41 1.41 8 8 0 0 0-11.32 0zm-2.83-2.83a1 1 0 1 1-1.4-1.4 14 14 0 0 1 19.79 0 1 1 0 1 1-1.41 1.4 12 12 0 0 0-16.98 0z"
    />
    <path class="fill-current text-gray-500" d="M12 20.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SystemTreeIconRadio',
  })
</script>
