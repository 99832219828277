<script setup lang="ts">
  import { ref, watch } from 'vue'
  import FormSwitch from '@/common/components/form/FormSwitch.vue'
  import { useSubscription } from '@vue/apollo-composable'
  import SubscriptionHubPrinterMessage from './graphql/SubscriptionHubPrinterMessage.gql'
  import SearchResultItem from '@/common/components/search/SearchResultItem.vue'
  import Callout from '@/common/components/callout/Callout.vue'
  import { HubPrinterMessageSubscription } from '@/common/graphql/types'
  import Loader from '@/common/components/loader/Loader.vue'

  type PartialSystemTreeNode = NonNullable<HubPrinterMessageSubscription['hubPrinterMessage']['system_tree_node']>

  type SearchResult = {
    mac: string,
    created_at: number,
    system_tree_node: PartialSystemTreeNode
  }

  defineEmits(['close'])

  const enabled = ref(false)

  const results = ref<Record<string, SearchResult>>({})

  const error = ref('')

  // Polling subscription.
  const {
    onError: onError,
    result: resultPollMagnet,
    restart
  } = useSubscription<HubPrinterMessageSubscription>(SubscriptionHubPrinterMessage, {}, () => ({
    enabled: enabled.value,
    fetchPolicy: 'no-cache',
  }))

  // Reset the error if the user toggles the poll magnet switch.
  watch(() => enabled.value, () => {
    error.value = ''
    results.value = {}
  })

  // Process magnet messages.
  watch(() => resultPollMagnet.value, data => {
    if (!data || !data.hubPrinterMessage) {
      return
    }

    if (data.hubPrinterMessage.error) {
      error.value = data.hubPrinterMessage.error
      return
    }

    // Ignore messages that we don't know what system tree node they belong to.
    if (!data.hubPrinterMessage.system_tree_node) {
      return
    }

    error.value = ''

    results.value[data.hubPrinterMessage.address] = {
      mac: data.hubPrinterMessage.address,
      created_at: Date.now(),
      system_tree_node: data.hubPrinterMessage.system_tree_node
    }
  })

  // Restart the subscription if an error occurs after a few seconds.
  let restartTimeout: number

  onError((err) => {
    error.value = err.message

    clearTimeout(restartTimeout)
    restartTimeout = window.setTimeout(() => {
      restart()
    }, 5000)
  })
</script>

<template>
  <div>
    <div class="flex p-3 justify-between">
      <FormSwitch
        v-model="enabled"
        :label="$t('common.global_search.polling.title')"
        containerClass="form-switch--small"
      />
    </div>

    <template v-if="enabled">
      <div class="search-result p-3 pt-0">
        <Callout v-if="error === 'no hubs registered'" type="warning" :text="$t('common.global_search.polling.no_hubs')" />
        <Callout v-else-if="error" type="error" :text="$t('common.global_search.polling.error')">
          {{ error }}
        </Callout>
        <div v-else class="space-y-3">
          <div class="space-y-1.5">
            <transition-group name="fade">
              <SearchResultItem
                v-for="item in results"
                :key="item.system_tree_node.id"
                :name="item.system_tree_node.name"
                :serial="item.system_tree_node.serial"
                :kind="item.system_tree_node.kind"
                :mac="item.mac"
                :linkTarget="{ name: 'systemTreeNodeForm', params: { id: item.system_tree_node.id }}"
                @click="$emit('close')"
              >
                <div class="flex justify-end w-16 relative">
                  <div class="rounded-full bg-csBlue-100 text-3xs px-2">
                    {{ new Date(item?.created_at).toLocaleTimeString() }}
                  </div>
                </div>
              </SearchResultItem>
            </transition-group>
          </div>
          <div>
            <div class="rounded-md border border-dashed p-2 text-xs text-center">
              <Loader :size="20" class="!my-2" layout="horizontal" :text="$t('common.global_search.polling.waiting_for_magnet')" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>