import { leftPad, logError, logInfo } from '@/common/utils'
import { ApolloClient, ApolloLink, concat, from, InMemoryCache, split } from '@apollo/client/core'
import { OperationDefinitionNode } from 'graphql'
import { Store } from 'vuex'
import { config } from '@/bootstrap/config'
import { State } from '@/store'
import { Router } from 'vue-router'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { Client, ClientOptions, createClient } from 'graphql-ws'
import { SystemContextKey } from '@/store/app.state'
import { createUploadLink } from 'apollo-upload-client'

function getSystemsCtx (store: Store<State>) {
  return normalizeSystemCtx(store.state.app.systemContext)
}

function normalizeSystemCtx (ctx: string|null) {
  return leftPad(ctx || 1, 2, '0')
}

interface RestartableClient extends Client {
  restart (): void;
}

let wsClient: RestartableClient
let inMemoryCache: InMemoryCache

export function getWsClient () {
  return wsClient
}

export function getInMemoryCache () {
  return inMemoryCache
}

export function setupApollo (store: Store<State>, router: Router) {
  const backend = config.backend

  const httpLink = createUploadLink({
    uri: `${backend}/query`,
    credentials: 'include',
  })

  const headersMiddleware = new ApolloLink((operation, forward) => {
    const systemCtxOverride = operation.getContext().system_id ?? null
    operation.setContext(({ headers = {} }) => {
      let systemCtx = getSystemsCtx(store)
      if (systemCtxOverride) {
        systemCtx = normalizeSystemCtx(systemCtxOverride)
      }

      const additionalHeaders = {
        'X-CS-System': systemCtx,
        'Accept-Language': (store.state.user.user.locale ?? 'de').toLowerCase(),
      }

      return {
        headers: {
          ...headers,
          ...additionalHeaders,
        }
      }
    })
    return forward(operation)
  })

  wsClient = createRestartableClient({
    lazy: true,
    url: `${backend}/query`.replace('http', 'ws').replace('/backend', '/ws/backend'),
    connectionParams: () => {
      return {
        'X-CS-System': getSystemsCtx(store),
        'Accept-Language': (store.state.user.user.locale ?? 'de').toLowerCase(),
      }
    },
    retryAttempts: Infinity,
    shouldRetry: () => true,
    retryWait: async function waitForServerHealthyBeforeRetry (retries) {
      const delay = retries < 2 ? 0 : Math.min(retries * 250, 6000)

      await new Promise((resolve) =>
        setTimeout(resolve, delay)
      )
    }, on: {
      connected: () => {
        store.commit('app/setIsBackendConnected', true)
      },
      closed: () => {
        store.commit('app/setIsBackendConnected', false)
      },
    }
  })

  const wsLink = new GraphQLWsLink(wsClient)

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  // updateChecker compares the X-CS-Version response header with the currently running version.
  // It makes the update notifier component visible if the versions do not match.
  const updateChecker = new ApolloLink((operation, forward) => {
    const responses = forward(operation)
    if (typeof responses.map !== 'function') {
      return responses
    }
    return responses.map(response => {
      const context = operation.getContext()
      if (!context?.response?.headers) {
        return response
      }
      const { response: { headers } } = context
      if (headers) {
        const backendVersion = headers.get('X-CS-Version')
        if (backendVersion && backendVersion != config.version) {
          store.commit('app/setAvailableUpdate', backendVersion)
        }
      }
      return response
    })
  })

  const errorHandler = onError(error => {
    if (error.networkError) {
      const networkError = error.networkError
      if (
        networkError &&
          'statusCode' in networkError &&
          networkError.statusCode === 424
      ) {
        logError('Requested system is not available. Falling back to first available system.')
        localStorage.setItem(SystemContextKey, '1')

        if (!router.currentRoute.value.query.hasOwnProperty('systemRedirect')) {
          setTimeout(() => document.location.reload(), 500)
        }

        router.push({ path: '/tree/overview', query: { systemRedirect: '1' } })
      } else {
        logError('Network error:', error.networkError)
      }
    }
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach(error => {
        if (error?.message.indexOf('MISSING_AUTH') > -1) {
          // Don't do a redirect if we are on the login page already.
          // Never redirect call displays.
          if (router.currentRoute.value.name === 'login' || router.currentRoute.value.name === 'display') {
            return
          }
          logInfo('Session timed out: ', error)
          router.push({ name: 'login' })
          return
        }
        if (error?.message.indexOf('MISSING_PERMISSION') > -1) {
          logInfo('Access denied: missing permission', error)
          return
        }
        logError('GraphQL error: ', error)
      })
    }
  })

  inMemoryCache = new InMemoryCache({
    typePolicies: {
      SystemTreeNode: {
        fields: {
          children: {
            merge: false,
          }
        }
      }
    }
  })

  return new ApolloClient({
    link: concat(headersMiddleware, from([
      errorHandler,
      updateChecker,
      link
    ])),
    cache: inMemoryCache,
    connectToDevTools: true,
  })
}

// createRestartableClient is a wrapper around the graphql-ws client that allows
// you to restart the client without having to recreate it.
// The restart function is used to restart the client when the system context changes.
function createRestartableClient (options: ClientOptions): RestartableClient {
  let restartRequested = false
  let restart = () => {
    restartRequested = true
  }

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: (socket: any) => {
        options.on?.opened?.(socket)

        restart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            socket.close(4205, 'Client Restart')
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true
          }
        }

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false
          restart()
        }
      },
    },
  })

  return {
    ...client,
    restart: () => restart(),
  }
}