import { Component } from 'vue'

export interface ModalState {
  stack: ModalStackItem[];
}

interface ModalStackItem {
  component: Component;
  key: string;
  path: string;
  props: any;
}

const state: ModalState = {
  stack: [],
}

const getters = {}

const actions = {}

const mutations = {
  push (state: ModalState, item: ModalStackItem) {
    state.stack.push({ ...item })
  },
  close (state: ModalState) {
    if (state.stack.length === 1) {
      document.body.classList.remove('overflow-hidden')
    }
    state.stack.pop()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}