<script setup lang="ts">
  import {
    ActiveDisplayNotesQuery,
    DisplayProfile,
    DisplayProfilesQuery,
    Locale,
    SystemParam
  } from '@/common/graphql/types'
  import { defaultDisplayConfig, DisplayParams } from '@/modules/display/composables/Calls.api'
  import { useWakeLock } from '@/modules/display/composables/WakeLock'
  import { computed, onBeforeMount, onMounted, onUnmounted, PropType, ref, watch, watchEffect } from 'vue'
  import { useStore } from '@/store'
  import ViewScaffold from '@/common/layouts/main/ViewScaffold.vue'
  import { useAppDataLoader } from '@/common/services/appdata/AppData.api'
  import { DisplayGroupConfiguration } from '@/modules/admin/displaygroup/DisplayGroup.api'
  import DisplayProfilesDrawer from '@/modules/display/components/DisplayProfilesDrawer.vue'
  import Box from '@/common/components/Box.vue'
  import { useI18n } from 'vue-i18n'
  import { CallWithSystem } from '@/store/call.state'
  import { useQuery } from '@vue/apollo-composable'
  import QueryActiveDisplayNotes from '@/modules/admin/displaynote/graphql/QueryActive.gql'
  import QueryDisplayProfiles from './/graphql/QueryDisplayProfiles.gql'
  import { debounce, logError } from '@/common/utils'
  import CloseCallConfirmationPopup from '@/modules/calllog/components/CloseCallConfirmationPopup.vue'
  import Display from '@/modules/display/components/Display.vue'
  import { useRoute, useRouter } from 'vue-router'
  import Callout from '@/common/components/callout/Callout.vue'
  import ActionButton from '@/common/components/button/ActionButton.vue'
  import { useLocalStorage } from '@vueuse/core'
  import { useTime } from '@/common/services/time'
  import CallSubscription from '@/modules/display/components/CallSubscription.vue'

  const props = defineProps({
    sections: {
      type: Array as PropType<number[]>,
      default: () => ([])
    },
    ignoreSectionsFilterForCallTypes: {
      type: Array as PropType<number[]>,
      default: () => ([])
    },
    ignoreSectionsTimeStart: {
      type: String,
      default: ''
    },
    ignoreSectionsTimeEnd: {
      type: String,
      default: ''
    },
    layout: {
      type: String as PropType<'grid' | 'list'>,
      default: 'grid'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    videoStream: {
      type: Boolean,
      default: true
    },
    displayGroupConfig: {
      type: Object as PropType<Record<number, DisplayGroupConfiguration>>,
      default: () => ({})
    },
    locale: {
      type: String as PropType<Locale>,
      default: ''
    },
    system: {
      type: String,
      default: ''
    },
    systems: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    fontSize: {
      type: String as PropType<'normal' | 'larger' | 'largest'>,
      default: 'normal'
    },
  })

  const i18n = useI18n()
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const time = useTime()

  const { fetch: fetchAppData } = useAppDataLoader(store)

  const settings = ref<DisplayParams>({
    fontSize: props.fontSize,
    fullscreen: props.fullscreen,
    layout: props.layout,
    sections: props.sections,
    videoStream: props.videoStream,
    system: props.system ?? store.state.app.systemContext,
    ignoreSectionsFilterForCallTypes: props.ignoreSectionsFilterForCallTypes,
    ignoreSectionsTimeEnd: props.ignoreSectionsTimeEnd,
    ignoreSectionsTimeStart: props.ignoreSectionsTimeStart,
    displayGroupConfig: props.displayGroupConfig,
    locale: props.locale,
  })

  const settingsDrawerVisible = ref(false)
  const secondsSinceAppStarted = Math.floor((time.value.now - (+store.state.app.started)) / 1000)

  // Trigger full screen mode if settings are set to full screen.
  let unwatch: () => void = () => {}
  unwatch = watch(() => settings.value, async () => {
    // If a system parameter is set, reset the system context.
    if (settings.value.system && store.state.app.systemContext !== settings.value.system) {
      await setSystemContext(settings.value.system)
      return
    }

    if (secondsSinceAppStarted < 5 && settings.value.fullscreen && !settingsDrawerVisible.value) {
      unwatch()
      store.commit('app/setIsFullscreen', true)
    }
  }, { deep: true, immediate: true })

  const reloadMilliseconds = 1000 * 60 * 60 * 4 // Reload the display every few hours.
  const isFullscreen = computed(() => store.state.app.isFullscreen)
  const isBackendConnected = computed(() => store.state.app.isBackendConnected)
  const error = ref<Error | null>(null)

  //
  // Display Profiles
  //
  const {
    result,
    refetch: refetchDisplayProfiles,
    loading: loadingDisplayProfiles
  } = useQuery<DisplayProfilesQuery>(QueryDisplayProfiles, {}, { fetchPolicy: 'cache-and-network' })

  // cache the last used profile. This makes sure the last used profile is loaded when the user navigates.
  const lastUsedProfile = useLocalStorage('cs.lastUsedProfile', '')
  const lastDisplayConfigUpdate = computed(() => store.state.app.lastDisplayConfigUpdate)

  watch(() => lastDisplayConfigUpdate.value, async () => {
    await refetchDisplayProfiles()
  })

  const displayProfiles = computed(() => (result.value?.displayProfiles ?? []) as DisplayProfile[])
  const activeProfile = computed(() => {
    const slug = route.params.slug || lastUsedProfile.value
    if (!slug || Object.keys(route.query).length > 0) {
      return undefined
    }

    return displayProfiles.value.find(p => p.slug === slug)
  })

  // Remove the profile slug if the profile is not found.
  const cancelSlugWatch = watchEffect(() => {
    if (displayProfiles.value.length === 0) {
      return
    }
    if (route.params.slug && !activeProfile.value) {
      // There is a slug, but we don't know the profile. Remove the slug.
      router.push({ name: 'display', params: { slug: '' } })
    } else if (!route.params.slug && activeProfile.value && Object.keys(route.query).length === 0) {
      // There is no slug but we have a profile. Add the slug (only if no query params are present).
      router.push({ name: 'display', params: { slug: activeProfile.value.slug } })
    }
    // Only run once.
    cancelSlugWatch()
  })

  const { requestWakeLock, releaseWakeLock } = useWakeLock()

  onBeforeMount(() => {
    store.commit('call/reset')
  })

  function keyPressHandler (e: KeyboardEvent) {
    if (e.code === 'Escape') {
      store.commit('app/setIsFullscreen', false)
    }
  }

  let reloadInterval: ReturnType<typeof setInterval>
  onMounted(async () => {
    reloadInterval = setInterval(() => {
      if (isBackendConnected.value) {
        document.location.reload()
      }
    }, reloadMilliseconds)

    document.body.addEventListener('keydown', keyPressHandler)
    await requestWakeLock()
  })

  onUnmounted(() => {
    clearInterval(reloadInterval)
    document.body.removeEventListener('keydown', keyPressHandler)
    releaseWakeLock()
  })

  async function setSystemContext (systemID: string) {
    await store.dispatch('app/setSystemContext', { systemId: systemID, fetchAppData })

    const params = new URLSearchParams(window.location.search)
    params.delete('system')

    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`)

    document.location.reload()
  }

  // availableSystems is a map of all available systems.
  const availableSystems = computed(() => {
    const systems: Record<string, SystemParam> = {}
    store.state.app.systems.forEach((s: SystemParam) => {
      systems[s.id] = s
    })
    return systems
  })

  // activeSystems is the currently active system or the systems defined in the display profile.
  const activeSystems = computed(() => {
    if (!store.state.app.systemContext || loadingDisplayProfiles.value) {
      return []
    }

    if (!activeProfile.value) {
      const system = availableSystems.value[Number(store.state.app.systemContext)]
      return system ? [
        availableSystems.value[Number(store.state.app.systemContext)]
      ] : []
    }

    return activeProfile.value.settings.map(s => availableSystems.value[s.system_id])
  })

  const noActiveSystems = ref(false)
  const noActiveSystemsCheck = debounce(() => { noActiveSystems.value = activeSystems.value.length === 0 }, 1000)
  watch(() => activeSystems.value, noActiveSystemsCheck, { deep: true })

  watch(() => activeProfile.value, profile => {
    store.commit('call/reset')

    if (!profile) {
      // Reset the last used profile, but only if the profiles are properly loaded.
      if (displayProfiles.value.length) {
        lastUsedProfile.value = ''
      }
      return
    }

    lastUsedProfile.value = profile.slug

    settings.value.fontSize = profile.font_size.toLowerCase() as 'normal' | 'larger' | 'largest'
    settings.value.fullscreen = profile.fullscreen
    settings.value.locale = profile.locale as Locale
    settings.value.videoStream = profile.show_video_stream

    profile.settings.forEach(s => {
      settings.value.displayGroupConfig[s.system_id] = {
        weekday_schedule_id: s.weekday_schedule_id,
        weekday_display_group_1_id: s.weekday_display_group_1_id,
        weekday_display_group_2_id: s.weekday_display_group_2_id,
        weekday_display_group_3_id: s.weekday_display_group_3_id,
        weekday_display_group_4_id: s.weekday_display_group_4_id,
        weekday_display_group_5_id: s.weekday_display_group_5_id,

        weekend_schedule_id: s.weekend_schedule_id,
        weekend_display_group_1_id: s.weekend_display_group_1_id,
        weekend_display_group_2_id: s.weekend_display_group_2_id,
        weekend_display_group_3_id: s.weekend_display_group_3_id,
        weekend_display_group_4_id: s.weekend_display_group_4_id,
        weekend_display_group_5_id: s.weekend_display_group_5_id,
      }
    })
  })

  const callToBeClosed = ref<CallWithSystem | null>(null)

  const {
    onError: onDisplayNotesError,
    result: displayNotesResult
  } = useQuery<ActiveDisplayNotesQuery>(QueryActiveDisplayNotes, {}, { fetchPolicy: 'no-cache' })

  onDisplayNotesError((error) => {
    logError('Failed to load displaynotes', error)
    store.commit('notifications/notify', {
      title: i18n.t('displaynote.notifications.error.title'),
      text: i18n.t('displaynote.notifications.error.title'),
      type: 'error',
      persist: true
    })
  })

  const displayNotes = computed(() => displayNotesResult.value?.activeDisplayNotes ?? [])
  const combinedCalls = computed(() => store.getters['call/combinedCalls'])
  const combinedPresences = computed(() => store.getters['call/combinedPresences'])
  const displayConfig = computed(() => defaultDisplayConfig(activeSystems.value.length > 1))

  const loadingStates = ref<Record<string, boolean>>({})
  const loading = computed(() => Object.values(loadingStates.value).some(l => l))
</script>

<template>
  <ViewScaffold title="common.modules.visual" subtitle="call.view.subtitle" classes="mb-4">
    <CallSubscription
      v-for="s in activeSystems"
      :key="s.id"
      :system="s"
      :settings="settings"
      @loading="loadingStates[s.id] = $event"
      @error="error = $event"
    />

    <div class="display" :class="{'p-10': isFullscreen}">
      <Box v-if="loading">
        <Loader class="bg-white py-10" />
      </Box>
      <Callout v-else-if="error" type="error" :text="error.message" />
      <div v-show="!loading">
        <Callout
          v-if="noActiveSystems && activeSystems.length === 0"
          type="warning"
          class="mb-4 border border-amber-500"
          text="display.callouts.no_systems_subscribed.title"
        >
          <p class="mb-4">
            {{ $t('display.callouts.no_systems_subscribed.text') }}
          </p>
          <ActionButton type="warning" size="small" @click="settingsDrawerVisible = true">
            {{ $t('display.callouts.no_systems_subscribed.action') }}
          </ActionButton>
        </Callout>
        <Display
          :calls="combinedCalls"
          :presences="combinedPresences"
          :displayProfile="activeProfile"
          :displayConfig="displayConfig"
          :fullscreen="isFullscreen"
          :videoStream="settings.videoStream"
          :layout="settings.layout"
          :displayNotes="displayNotes"
          :fontSize="settings.fontSize"
          @close="callToBeClosed = $event"
          @setLayout="settings.layout = $event"
          @openSettings="settingsDrawerVisible = true"
        />
      </div>
    </div>

    <DisplayProfilesDrawer
      :initialProfileId="activeProfile?.id"
      :isOpen="settingsDrawerVisible"
      :settings="settings"
      @close="settingsDrawerVisible = false"
    />
  </ViewScaffold>

  <teleport to="body">
    <transition name="popup">
      <CloseCallConfirmationPopup
        v-if="callToBeClosed !== null"
        :call="callToBeClosed"
        :allCalls="combinedCalls"
        :displayConfig="displayConfig.calls"
        @success="callToBeClosed = null"
        @cancel="callToBeClosed = null"
      />
    </transition>
  </teleport>
</template>

<style lang="stylus" scoped>
  .appear-enter-active,
  .appear-leave-active
    transition .5s ease-out
    transition-property transform, opacity

  .appear-enter-from,
  .appear-leave-to
    transform scale(0)
    opacity 0

  .appear-to,
  .appear-leave-from
    transform scale(1)
    opacity 1
</style>
