<script setup lang="ts">
  import { ref } from 'vue'
  import { SearchIcon } from '@heroicons/vue/solid'
  import SearchPopup from '@/common/components/search/SearchPopup.vue'

  const visible = ref(false)
</script>

<template>
  <div class="max-w-lg w-full lg:max-w-xs">
    <label for="global-search" class="sr-only">{{ $t('common.search') }}</label>
    <div ref="search" class="relative text-gray-400 focus-within:text-gray-600">
      <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
        <SearchIcon class="h-5 w-5" aria-hidden="true" />
      </div>
      <div class="absolute right-0 border border-gray-300 shadow-sm text-gray-600 rounded mr-2 mt-[7px] px-2 py-1 text-3xs">
        Ctrl + K
      </div>
      <input
        id="global-search"
        data-testid="global-search-trigger"
        autocomplete="off"
        class="block w-full bg-white py-2 pl-10 pr-20 border border-transparent rounded-md leading-5 text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-csBlue-600 focus:ring-white focus:border-white sm:text-sm"
        :placeholder="`${$t('common.search')}`"
        type="search"
        @focus="visible = true"
      >
    </div>
  </div>

  <teleport to="body">
    <transition
      enterActiveClass="transition ease-out duration-100"
      enterFromClass="opacity-0"
      enterToClass="opacity-100"
      leaveActiveClass="transition ease-in duration-75"
      leaveFromClass="opacity-100"
      leaveToClass="opacity-0"
    >
      <SearchPopup v-if="visible" @close="visible = false" />
    </transition>
  </teleport>
</template>

