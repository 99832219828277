<template>
  <div class="form-color-picker" :class="mergedContainerClasses">
    <label class="flex items-center text-gray-600 text-sm mb-2 -my-1">
      <div v-if="label" class="flex leading-none">
        <div
          class="py-1 mr-4 rounded text-sm"
          :style="{ 'background-color': modelValue ? modelValue.toString() : 'transparent' }"
          :class="{'px-2 text-white': modelValue}"
        >
          {{ $t(label) }}
        </div>
        <div v-if="modelValue" class="py-1 text-sm" :style="{ 'color': modelValue.toString() }">
          {{ $t(label) }}
        </div>
      </div>
    </label>

    <div class="flex flex-wrap leading-none -mb-2 -mx-1">
      <div
        v-for="color in colors"
        :key="color"
        class="w-7 h-7 p-1"
        :class="{'cursor-pointer' : !disabled, 'opacity-50': disabled }"
        @click="$emit('update:modelValue', color)"
      >
        <ColorSwatch :color="loading ? '' : color" :class="{'loading-shimmer' : loading}" />
      </div>
    </div>

    <div v-if="realComment" class="input-comment mt-4 block text-gray-700 text-xs">
      {{ $t(realComment, {field: realId}) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defaultFormInputProps, useFormProps } from '@/common/components/form/Forms.api'
  import { defineComponent, PropType } from 'vue'
  import ColorSwatch from '@/common/components/ColorSwatch.vue'

  export default defineComponent({
    name: 'FormColorPicker',
    components: { ColorSwatch },
    inheritAttrs: false,
    props: {
      ...defaultFormInputProps,
      colors: {
        type: Array as PropType<string[]>,
        default: () => []
      }
    },
    emits: ['update:modelValue'],
    setup (props) {
      const { realId, realComment, mergedContainerClasses, error } = useFormProps(props as any)

      return {
        error,
        realId,
        realComment,
        mergedContainerClasses
      }
    }
  })
</script>

<style lang="stylus" scoped>
  .disabled
    filter grayscale(.75)
</style>
