/**
 * Represents a mock localStorage entry.
 */
interface DataObject {
  [key: string]: string;
}

/**
 * Mock implementation of localStorage.
 */
export class MockStorage implements Storage {
  public readonly length: number = 0

  public data: DataObject = {}

  public setState (state: DataObject) {
    this.data = state
  }

  public clear (): void {
    this.data = {}
  }

  public getItem (key: string): string | null {
    return this.data.hasOwnProperty(key) ? this.data[key] : ''
  }

  public key (): string | null {
    return ''
  }

  public removeItem (key: string): void {
    delete this.data[key]
  }

  public hasKey (key: string): boolean {
    return this.data.hasOwnProperty(key)
  }

  public setItem (key: string, value: string): void {
    this.data[key] = value
  }
}
