<template>
  <div class="container mt-12">
    <Box>
      <div class="flex items-center justify-center flex-col p-8 text-red-800">
        <p class="mb-4 font-semibold">
          {{ $t('common.errors.page_not_found.title') }}
        </p>
        <p class="mb-8 text-sm">
          {{ $t('common.errors.page_not_found.text') }}
        </p>
        <ActionButton type="danger" @click="$router.go(-1)">
          {{ $t('common.actions.go_back') }}
        </ActionButton>
      </div>
    </Box>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'NotFound',
  })
</script>
