// ConditionalQueryReturn picks only the required return values used by the application.
import { useMutation, UseMutationReturn, useQuery, UseQueryReturn } from '@vue/apollo-composable'
import { Ref, ref } from 'vue'
import { DocumentNode } from 'graphql'
import { ApolloError, ApolloQueryResult, FetchResult, NetworkStatus, OperationVariables } from '@apollo/client/core'
import { OnResultContext } from '@vue/apollo-composable/dist/useQuery'
import { OnDoneContext, OnErrorContext } from '@vue/apollo-composable/dist/useMutation'

type ConditionalQueryReturn<TResult, TVariables extends OperationVariables> = Pick<UseQueryReturn<TResult, TVariables>, 'result' | 'loading' | 'error' | 'refetch' | 'onResult'>

// useConditionalQuery returns dummy return values if the specified query is null.
export function useConditionalQuery<TResult, TVariables extends OperationVariables = any> (query?: DocumentNode, vars?: any, options?: any): ConditionalQueryReturn<TResult, TVariables> {
  if (!query) {
    return {
      result: ref({}) as Ref<TResult>,
      loading: ref(false),
      error: ref<ApolloError | null>(null),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      refetch: async (vars?: TVariables) => ({
        data: {} as TResult,
        errors: [],
        loading: false,
        networkStatus: NetworkStatus.ready,
        stale: false
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onResult: (fn: (param: ApolloQueryResult<TResult>, context: OnResultContext) => void) => ({
        off: () => {
        }
      })
    }
  }
  return useQuery<TResult, TVariables>(query, vars, options)
}

// ConditionalMutationReturn picks only the required return values used by the application.
type ConditionalMutationReturn<TResult, TVariables> = Pick<UseMutationReturn<TResult, TVariables>, 'onError' | 'loading' | 'error' | 'onDone' | 'mutate'>

// useConditionalMutation returns dummy return values if the specified query is null.
export function useConditionalMutation<TResult, TVariables extends OperationVariables = any> (query?: DocumentNode, options?: any): ConditionalMutationReturn<TResult, TVariables> {
  if (!query) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mutate: async (vars?: TVariables|null) => ({}),
      loading: ref(false),
      error: ref<ApolloError|null>(null),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onDone: (fn: (param: FetchResult<TResult, Record<string, any>, Record<string, any>>, context: OnDoneContext) => void) => ({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        off: () => {
        }
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (fn: (param: ApolloError, context: OnErrorContext) => void) => ({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        off: () => {
        }
      })
    }
  }
  return useMutation<TResult, TVariables>(query, options)
}

// gqlErrorResponse describes a single error entry in a GraphQL error response.
export interface GqlErrorResponse {
  message: string;
  path: string[];
  extensions?: {
    data?: Record<string, unknown>;
    field: string;
    validation: boolean;
  };
}

