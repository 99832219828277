import { Call, DisplayProfile, Locale } from '@/common/graphql/types'
import { computed, ComputedRef, Ref } from 'vue'
import { Store } from 'vuex'
import { State } from '@/store'
import { RouteLocation } from 'vue-router'
import { DisplayGroupConfiguration } from '@/modules/admin/displaygroup/DisplayGroup.api'
import { CallWithSystem } from '@/store/call.state'

// DisplayConfigFromURLParams is used as key for special display configurations provided via the URL.
export const DisplayConfigFromURLParams = -1

// sortCalls by their urgency, then their type's sort_order, then by their duration (longest first).
export function sortCalls (calls: CallWithSystem[]): CallWithSystem[] {
  const urgency = ['NORMAL', 'URGENT', 'EMERGENCY']
  calls.sort((a, b) => {
    const urgencyA = a?.call_type?.urgency?.toUpperCase() ? urgency.indexOf(a?.call_type?.urgency?.toUpperCase()) : 0
    const urgencyB = b?.call_type?.urgency?.toUpperCase() ? urgency.indexOf(b?.call_type?.urgency?.toUpperCase()) : 0
    const sortA = a?.call_type?.sort_order || 999
    const sortB = b?.call_type?.sort_order || 999

    return urgencyB - urgencyA || sortA - sortB || a.duration - b.duration
  })
  return calls
}

// useCallDisplay provides all logic to display a Call according to a CallDisplayConfig.
export const useCallDisplay = (call: Ref<Call>, config: Ref<CallDisplayConfig>) => {
  const isElevated = computed(() => {
    const urgency = call.value?.call_type?.urgency?.toUpperCase()
    return !urgency ? false : ['EMERGENCY', 'URGENT'].indexOf(urgency) > -1
  })

  const fn = (line: CallDisplayConfigLine) => {
    if (['time', 'position_or_call_source_text', 'section', 'section_and_system'].includes(line)) {
      return true
    }
    return call.value.hasOwnProperty(line) && call.value[line as keyof Call]
  }

  const lines = computed(() => config.value?.lines?.filter(fn))
  const bolds = computed(() => config.value?.bolds?.filter(fn))

  return {
    isElevated,
    lines,
    bolds,
  }
}

// The close call action is visible for all users with the calllog::close_calls permission.
// It is also visible for all logged-in users if:
// - the section has the allow_manual_call_closure flag set to true
// - the device itself has not set the deny_manual_call_closure to true.
export function useShowCloseAction (store: Store<State>, call: Ref<Call>): ComputedRef<boolean> {
  if (store.getters['user/can']('calllog::close_calls')) {
    return computed(() => true)
  }

  if (call.value.system_tree_node?.deny_manual_call_closure) {
    return computed(() => false)
  }

  return computed(() => call.value.section?.allow_manual_call_closure === true && store.state.user.user.id > 0)
}

export type CallDisplayDynamicConfigLineOptions = 'position_or_call_source_text' | 'section' | 'section_and_system'
export type CallDisplayConfigLine = keyof Call | CallDisplayDynamicConfigLineOptions

// CallDisplayConfig contains the display layout for a single call.
export interface CallDisplayConfig {
  title: keyof Call;
  subtitle?: keyof Call;
  bolds: CallDisplayConfigLine[];
  lines: CallDisplayConfigLine[];
  showCallSourceChar: boolean
}

export interface LayoutDisplayConfig {
  calls: CallDisplayConfig;
  presences: CallDisplayConfig;
}

// getDisplayValue returns the value for a given key in a call.
export function getDisplayValue (call: CallWithSystem, key: CallDisplayConfigLine): string {
  if (key === 'call_source_text') {
    return filterCallSourceText(call.call_source_text ?? '')
  }

  if (key == 'position_or_call_source_text') {
    return call.position ? call.position : filterCallSourceText(call.call_source_text)
  }

  if (key == 'section') {
    return `${call.section_name}`
  }

  if (key == 'section_and_system') {
    return `${call.section_name} / ${call.system_name}`
  }

  return call[key] ?? ''
}

// filterCallSourceText makes sure call sources with a * prefix are
// handled as empty strings. The * prefix determines a hidden call
// source in the GEC system, so we hide it as well.
function filterCallSourceText (text: string) {
  return text.substring(0, 1) === '*' ? '' : text
}

// defaultDisplayConfig returns the default display configuration for calls and presences.
export const defaultDisplayConfig = (multiSystemContext: boolean): LayoutDisplayConfig => {
  const sectionKey = multiSystemContext ? 'section_and_system' : 'section'

  return {
    calls: {
      title: 'device_text_short',
      subtitle: 'device_text_long',
      showCallSourceChar: true,
      bolds: [
        'call_type_text',
      ],
      lines: [
        'position_or_call_source_text',
        sectionKey,
      ]
    },
    presences: {
      title: 'device_text_short',
      showCallSourceChar: true,
      bolds: [],
      lines: [
        'position_or_call_source_text',
        sectionKey,
      ]
    }
  }
}

// DisplayParams configures a call display.
export interface DisplayParams {
  sections?: number[];
  ignoreSectionsFilterForCallTypes?: number[];
  fullscreen?: boolean;
  videoStream?: boolean;
  layout?: 'grid' | 'list';
  ignoreSectionsTimeStart?: string;
  ignoreSectionsTimeEnd?: string;
  locale?: Locale;
  system?: string;
  fontSize?: 'normal' | 'larger' | 'largest';
  displayGroupConfig: Record<number, Partial<DisplayGroupConfiguration>>;
}

// routeToDisplayParams returns the display params from a route.
export function routeToDisplayParams (route: RouteLocation) {
  const params: DisplayParams = {
    sections: [],
    videoStream: false,
    system: '',
    ignoreSectionsFilterForCallTypes: [],
    locale: Locale.De,
    layout: 'grid',
    fontSize: 'normal',
    displayGroupConfig: {}
  }
  if (typeof route.query?.sections === 'string') {
    params['sections'] = route.query.sections.split(',').map(s => Number(s.trim()))
  }
  if (typeof route.query?.ignore_sections_time === 'string') {
    const timeParts = route.query.ignore_sections_time.split('-').map(s => s.trim())
    params['ignoreSectionsTimeStart'] = timeParts[0]
    params['ignoreSectionsTimeEnd'] = timeParts[1]
  }
  if (typeof route.query?.fullscreen === 'string') {
    params['fullscreen'] = route.query.fullscreen === '1'
  }
  if (typeof route.query?.layout === 'string') {
    params['layout'] = route.query.layout === 'list' ? 'list' : 'grid'
  }
  if (typeof route.query?.videostream === 'string') {
    params['videoStream'] = route.query.videostream === '1'
  }
  if (typeof route.query?.ignore_sections_filter_for_call_types === 'string') {
    params['ignoreSectionsFilterForCallTypes'] = route.query.ignore_sections_filter_for_call_types.split(',').map(s => Number(s.trim()))
  }
  if (typeof route.query?.system === 'string') {
    params['system'] = route.query.system
  }

  if (typeof route.query?.locale === 'string') {
    switch (route.query.locale) {
    case 'en':
      params['locale'] = Locale.En
      break
    case 'de':
      params['locale'] = Locale.De
      break
    }
  }

  if (typeof route.query?.fontsize === 'string') {
    switch (route.query.fontsize) {
    case 'larger':
      params['fontSize'] = 'larger'
      break
    case 'largest':
      params['fontSize'] = 'largest'
      break
    }
  }

  const handleNullValueDisplayGroups = (value: number) => value === -1 ? null : value
  const handleGroupValues = (value: string) => {
    value = value.trim()
    if (value === null || value === '' || isNaN(Number(value))) {
      return -1
    }
    return Number(value)
  }

  if (typeof route.query?.wd_schedule === 'string' && typeof route.query?.wd_groups === 'string') {
    const weekdayConfig: DisplayGroupConfiguration = {}

    weekdayConfig.weekday_schedule_id = Number(route.query.wd_schedule)
    if (weekdayConfig.weekday_schedule_id === -1 || isNaN(weekdayConfig.weekday_schedule_id)) {
      weekdayConfig.weekday_schedule_id = null
    }

    const weekdayDisplayGroups = (route.query.wd_groups as string)?.split(',').map(handleGroupValues) ?? [-1, -1, -1, -1, -1]
    weekdayConfig.weekday_display_group_1_id = handleNullValueDisplayGroups(weekdayDisplayGroups[0])
    weekdayConfig.weekday_display_group_2_id = handleNullValueDisplayGroups(weekdayDisplayGroups[1])
    weekdayConfig.weekday_display_group_3_id = handleNullValueDisplayGroups(weekdayDisplayGroups[2])
    weekdayConfig.weekday_display_group_4_id = handleNullValueDisplayGroups(weekdayDisplayGroups[3])
    weekdayConfig.weekday_display_group_5_id = handleNullValueDisplayGroups(weekdayDisplayGroups[4])

    if (!params.displayGroupConfig) {
      params.displayGroupConfig = {}
    }

    params.displayGroupConfig[DisplayConfigFromURLParams] = {
      ...params.displayGroupConfig[DisplayConfigFromURLParams],
      ...weekdayConfig,
    }
  }

  if (typeof route.query?.we_schedule === 'string' && typeof route.query?.we_groups === 'string') {
    const weekendConfig: DisplayGroupConfiguration = {}

    weekendConfig.weekend_schedule_id = Number(route.query.we_schedule)
    if (weekendConfig.weekend_schedule_id === -1 || isNaN(weekendConfig.weekend_schedule_id)) {
      weekendConfig.weekend_schedule_id = null
    }

    const weekendDisplayGroups = (route.query.we_groups as string)?.split(',').map(handleGroupValues) ?? [-1, -1, -1, -1, -1]
    weekendConfig.weekend_display_group_1_id = handleNullValueDisplayGroups(weekendDisplayGroups[0])
    weekendConfig.weekend_display_group_2_id = handleNullValueDisplayGroups(weekendDisplayGroups[1])
    weekendConfig.weekend_display_group_3_id = handleNullValueDisplayGroups(weekendDisplayGroups[2])
    weekendConfig.weekend_display_group_4_id = handleNullValueDisplayGroups(weekendDisplayGroups[3])
    weekendConfig.weekend_display_group_5_id = handleNullValueDisplayGroups(weekendDisplayGroups[4])

    if (!params.displayGroupConfig) {
      params.displayGroupConfig = {}
    }

    params.displayGroupConfig[DisplayConfigFromURLParams] = {
      ...params.displayGroupConfig[DisplayConfigFromURLParams],
      ...weekendConfig
    }
  }

  return params
}

// displayParamsToUrl converts a DisplayParams object to a url query string.
export function displayParamsToUrl (base: Location, displayParams: DisplayProfile): URL {
  const url = new URL(base.href)

  url.search = ''

  if (displayParams.fullscreen) {
    url.searchParams.set('fullscreen', '1')
  }

  if (displayParams.show_video_stream) {
    url.searchParams.set('videostream', '1')
  }

  if (displayParams.locale && displayParams.locale !== Locale.De) {
    url.searchParams.set('locale', displayParams.locale.toLocaleLowerCase())
  }

  if (displayParams.font_size && displayParams.font_size.toLowerCase() !== 'normal') {
    url.searchParams.set('fontsize', displayParams.font_size.toLowerCase())
  }

  return url
}