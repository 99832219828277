<template>
  <ViewScaffold title="user.errors.access_denied.title">
    <Callout type="warning" text="user.errors.access_denied.title" class="shadow-md border border-red-400 my-4">
      <div>{{ $t('user.errors.access_denied.body') }}</div>
    </Callout>
  </ViewScaffold>
</template>

<script lang="ts">
  import Callout from '@/common/components/callout/Callout.vue'
  import { defineComponent } from 'vue'
  import ViewScaffold from '@/common/layouts/main/ViewScaffold.vue'

  export default defineComponent({
    name: 'AccessDeniedView',
    components: { Callout, ViewScaffold },
  })
</script>
