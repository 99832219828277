import { SystemMessage as Message } from '@/common/graphql/types'
import { setNow, useNow } from '@/common/services/time'
import { logError, logInfo } from '@/common/utils'
import { Store } from 'vuex'
import { State } from '@/store'

export const SystemMessage = Symbol('systemMessage')

export function systemMessageHandler (message: Message, store: Store<State>) {
  switch (message.subject) {
  case 'time':
    setNow(message.payload * 1000)
    break
  case 'restart':
    logInfo('CS Server restarted', message.payload)
    if (message.payload?.version) {
      store.commit('app/setAvailableUpdate', message.payload.version)
      store.commit('app/serverStart', useNow())
    }
    break
  case 'reload':
    document.location.reload()
    break
  case 'getsconfig.commit':
    store.commit('app/setCommittingGetsConfig', message.payload)
    break
  case 'getsconfig.commit_done':
    store.commit('app/setCommittingGetsConfig', null)
    break
  case 'getsconfig.progress':
    store.commit('app/setGetsConfigImportProgress', message.payload)
    break
  case 'getshttp.start':
    store.commit('app/addGetsHTTPSyncJob', message.payload)
    break
  case 'getshttp.finished':
  case 'getshttp.failed':
    store.commit('app/removeGetsHTTPSyncJob', message.payload)
    break
  case 'onlinebackup.token':
    store.commit('app/setOnlineBackupToken', message.payload.token)
    break
  case 'onlinebackup.result':
    store.commit('app/setOnlineBackupResult', message.payload)
    break
  case 'videostream':
    const url = message.payload.url
    if (message.payload.action === 'start') {
      store.commit('app/addVideoStream', url)
    } else {
      store.commit('app/removeVideoStream', url)
    }
    break
  case 'displaynote.updated':
    if (store.state.app.currentRoute?.name === 'display') {
      document.location.reload()
    }
    break
  case 'schedule.updated':
  case 'displaygroup.updated':
  case 'displayprofile.updated':
    // Update Call display relations.
    if (store.state.app.currentRoute?.name === 'display') {
      store.commit('app/displayConfigUpdated')
    }
    break
  default:
    logError('Unknown system message received:', message)
  }
}

