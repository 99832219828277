<template>
  <div
    class="list flex text-sm p-4 bg-white rounded border border-gray-200 shadow select-none w-full"
    @click="$emit('click', $event)"
  >
    <div class="w-2/3 pr-8">
      <table class="w-full">
        <CallRow
          v-for="call in calls"
          :key="call.id"
          class="border-t border-b border-gray-100"
          :displayConfig="displayConfig.calls"
          :call="call"
          @close="$emit('close', $event)"
        />
      </table>
    </div>
    <div v-if="presences.length" class="w-1/3 pl-8">
      <table class="w-full">
        <CallRow
          v-for="call in presences"
          :key="call.id"
          class="border-t border-b border-gray-100"
          :displayConfig="displayConfig.presences"
          :call="call"
          :condensed="true"
          @close="$emit('close', $event)"
        />
      </table>
    </div>
  </div>
</template>

<script lang="ts">
  import CallRow from '@/modules/display/components/CallRow.vue'
  import { LayoutDisplayConfig } from '@/modules/display/composables/Calls.api'
  import { defineComponent, PropType } from 'vue'
  import { CallWithSystem } from '@/store/call.state'

  export default defineComponent({
    name: 'ListLayout',
    components: { CallRow },
    props: {
      calls: {
        type: Array as PropType<CallWithSystem[]>,
        default: () => ([])
      },
      presences: {
        type: Array as PropType<CallWithSystem[]>,
        default: () => ([])
      },
      displayConfig: {
        type: Object as PropType<LayoutDisplayConfig>,
        required: true,
      },
    },
    emits: ['close', 'click'],
  })
</script>

