<script setup lang="ts">
  import { defaultFormInputProps, useFormProps } from '@/common/components/form/Forms.api'
  import { computed, PropType } from 'vue'

  defineOptions({
    inheritAttrs: false,
  })

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps({
    ...defaultFormInputProps,
    name: {
      required: false,
      type: String,
      default: '',
    },
    inputValue: {
      type: [String, Boolean, Number, Object],
      default: '',
    },
    modelValue: {
      type: [Array, Boolean, String, Object],
      default: undefined,
    },
    trueValue: {
      type: [String, Boolean, Number, Object] as PropType<any>,
      default: true,
    },
    falseValue: {
      type: [String, Boolean, Number, Object] as PropType<any>,
      default: false,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    containerClass: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
  })
  const {
    realId,
    uniqueId,
    realComment,
    mergedContainerClasses: preMergedContainerClasses,
  } = useFormProps(props as any)

  const isChecked = computed(() => {
    return Array.isArray(props.modelValue)
      ? props.modelValue.includes(props.inputValue)
      : props.modelValue == props.trueValue
  })

  function change (e: Event) {
    const isChecked = (e.target as HTMLInputElement)?.checked

    if (Array.isArray(props.modelValue)) {
      const newValue = [...props.modelValue]
      if (isChecked) {
        newValue.push(props.inputValue)
      } else {
        newValue.splice(newValue.indexOf(props.inputValue), 1)
      }
      emit('update:modelValue', newValue)
    } else {
      emit('update:modelValue', isChecked ? props.trueValue : props.falseValue)
    }
  }

  const mergedContainerClasses = computed(() => [...preMergedContainerClasses.value, props.containerClass])
</script>

<template>
  <label
    :for="uniqueId"
    class="flex items-start cursor-pointer select-none cs-form-input cs-form-input--checkbox"
    :class="mergedContainerClasses"
  >
    <div class="flex items-center h-5">
      <input
        v-bind="$attrs"
        :id="uniqueId"
        class="h-4 w-4 text-gray-800 rounded border-gray-400 shadow"
        :class="{'form-checkbox': type === 'checkbox', 'form-radio': type === 'radio'}"
        :type="type"
        :name="name"
        :checked="isChecked"
        :disabled="disabled"
        :value="modelValue"
        @change="change"
      >
    </div>
    <div
      v-if="label || realComment"
      class="form-checkbox-label-container pl-5 text-sm leading-5"
      :class="labelClass"
    >
      <p v-if="label" class="form-checkbox-label text-gray-700">{{ $t(label) }}</p>
      <p v-if="realComment" class="input-comment text-gray-500 mt-1">{{ $t(realComment, { field: realId }) }}</p>
    </div>
  </label>
</template>

<style lang="stylus" scoped>
  &.disabled
    @apply cursor-not-allowed

    .form-checkbox
      @apply text-gray-400 bg-gray-200

    .form-checkbox:checked
      @apply bg-gray-500

    .form-checkbox-label
      @apply text-gray-500

    .input-comment
      @apply text-gray-400

  .danger
    .input-comment
      @apply text-red-500 text-xs

    .form-checkbox
      @apply border-red-700

    .form-checkbox:checked
      @apply text-red-700
</style>
