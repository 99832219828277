<template>
  <td>
    <div
      class="datatable-col-wrapper border-b border-gray-100 border-0 whitespace-nowrap h-10 flex items-center"
      :class="[{'truncate': truncate}, wrapperClasses]"
      style="border-left-width: 0"
    >
      <div class="w-full" :class="innerClasses" :style="styles">
        <slot />
      </div>
    </div>
  </td>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'DataTableCol',
    props: {
      innerClasses: {
        type: String,
        default: 'px-4'
      },
      wrapperClasses: {
        type: String,
        default: '',
      },
      truncate: {
        type: Boolean,
        default: true,
      }
    },
    setup () {
      const highlightColor = ''
      const indent = 0

      const styles = computed(() => {
        const res: any = {}

        if (highlightColor) {
          res['border-top-width'] = '1px'
          res['border-bottom-width'] = '1px'
          res['border-top-color'] = highlightColor
          res['border-bottom-color'] = highlightColor
        }

        if (indent) {
          res['border-left-color'] = highlightColor
          res['border-left-width'] = `${indent * 15}px`
        }

        return res
      })

      return {
        styles
      }
    }
  })
</script>

