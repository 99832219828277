<template>
  <div class="text-center">
    <p class="font-semibold mb-4">
      {{ $t('setup.license.title') }}
    </p>
    <p class="mx-auto max-w-md">
      {{ $t('setup.license.subtitle') }}
    </p>
    <div v-if="state.license && state.license.id > 0" class="p-4 bg-green-50 border border-green-200 rounded shadow-sm mt-8">
      <p class="leading-normal">
        {{ $t('setup.license.licensed_for') }} <br>
        <strong>{{ state.license.name }}</strong> ({{ state.license.id }})
      </p>
      <p v-if="!showForm" class="mt-4">
        <a v-if="!showForm" href="#" class="underline text-xs" @click.prevent="showForm = true">
          {{ $t('setup.license.replace') }}
        </a>
      </p>
    </div>
    <Callout v-if="calloutError" type="error" :text="$t('common.states.error')" class="my-4 text-left">
      <p>{{ calloutError }}</p>
    </Callout>
    <div v-if="showForm" class="flex -mx-2 mt-8">
      <div class="flex-1 px-2">
        <FormInput
          v-model="customer"
          name="customer"
          label="setup.license.license_number"
          maxlength="6"
          :errors="errors"
        />
      </div>
      <div class="flex-1 flex-shrink-0 px-2">
        <FormInput
          v-model="code"
          name="code"
          label="setup.license.activation_code"
          :errors="errors"
        />
      </div>
      <div class="flex-0 flex-grow-0 px-2">
        <ActionButton :loading="loading" type="primary" class="mt-6" @click.prevent="activate">
          {{ $t('setup.license.activate') }}
        </ActionButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, reactive, ref, toRefs, watch } from 'vue'
  import { useMutation } from '@vue/apollo-composable'
  import { ActivateLicenseMutation, ActivateLicenseMutationVariables } from '@/common/graphql/types'
  import { useStore } from '@/store'
  import { transformLicenseErrors } from '@/modules/admin/systemmaintenance/composables/License.api'
  import { useI18n } from 'vue-i18n'

  import MutationActivateLicense from '@/modules/setup/graphql/MutationActivateLicense.gql'
  import { SetupState } from '@/modules/setup/Setup.api'

  export default defineComponent({
    name: 'License',
    components: {},
    props: {
      state: {
        type: Object as PropType<SetupState>,
        default: () => ({})
      }
    },
    emits: ['setState'],
    setup (props, { emit }) {
      const store = useStore()
      const i18n = useI18n()
      const form = reactive({ customer: '', code: '' })
      const calloutError = ref('')
      const {
        mutate,
        loading,
        onError
      } = useMutation<ActivateLicenseMutation, ActivateLicenseMutationVariables>(MutationActivateLicense, {
        fetchPolicy: 'no-cache',
      })

      const errors = ref({})
      onError((response: any) => {
        transformLicenseErrors(i18n, response, errors, calloutError)
      })

      const { state } = toRefs(props)

      const showForm = ref(state.value.license?.id === 0)
      watch(() => props.state.license, newV => {
        if (newV?.id && Number(newV.id) > 0) {
          showForm.value = false
        }
      })

      async function activate () {
        const response = await mutate({ customer: form.customer, code: form.code })
        if (response?.data?.activateLicense) {
          emit('setState', ['license', response.data?.activateLicense])
          store.commit('license/set', response.data?.activateLicense)
          form.customer = ''
          form.code = ''
          errors.value = {}
        }
      }

      return {
        errors,
        activate,
        loading,
        showForm,
        calloutError,
        ...toRefs(form),
      }
    }
  })
</script>
