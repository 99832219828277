<script lang="ts" setup>
  import { PropType } from 'vue'
  import { DisplayNote } from '@/common/graphql/types'
  import { formatDate, parseISO } from '@/common/services/time'
  import IconTime from '@/common/components/svg/IconTime.vue'

  const props = defineProps({
    displayNotes: {
      type: Array as PropType<ReadonlyArray<Partial<DisplayNote>>>,
      required: true,
    }
  })
</script>

<template>
  <div class="w-1/4">
    <div
      v-for="note in props.displayNotes"
      :key="note.id"
      :class="'text-base ml-4 mb-4 p-4 rounded-sm border drop-shadow note--' + note.color"
    >
      <div v-if="note.title" class="text-xl font-semibold mb-2 leading-tight">
        {{ note.title }}
      </div>
      <div>
        {{ note.text }}
      </div>
      <div v-if="note.display_date" class="text-gray-500 text-sm mt-2 flex justify-end">
        <IconTime class="w-4 text-gray-500 mr-1" />
        {{ formatDate(parseISO(note.display_date)) }}
      </div>
    </div>
  </div>
</template>

<style lang="stylus">
  .note

    &--green
      @apply bg-green-50 border-green-300

    &--red
      @apply bg-red-50 border-red-300

    &--blue
      @apply bg-blue-50 border-blue-300

    &--yellow
      @apply bg-yellow-50 border-yellow-300
</style>