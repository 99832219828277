<template>
  <transition name="popup">
    <Popup v-if="visible" :title="$t('changelog.popups.getsCommit.title')">
      <div class="http-error-popup">
        <Loader />
        <div v-if="configStatus.status !== ConfiguratorStatus.Ready && configStatus.progress > 0" class="my-4 text-center font-semibold">
          {{ configStatus.progress }} %
        </div>
        <div class="text-center text-gray-800">
          {{ $t('changelog.popups.getsCommit.text') }}
        </div>
      </div>
      <template #actions>
        <ActionButton @click="navigate">
          {{ $t(`changelog.popups.getsCommit.action`) }}
        </ActionButton>
      </template>
    </Popup>
  </transition>
</template>

<script lang="ts">
  import Loader from '@/common/components/loader/Loader.vue'
  import Popup from '@/common/components/popup/Popup.vue'
  import { useQuery } from '@vue/apollo-composable'
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { ConfigChangelogQuery, ConfiguratorStatus } from '@/common/graphql/types'
  import QueryConfigChangelog from '@/modules/device/graphql/QueryConfigChangelog.gql'

  export default defineComponent({
    name: 'GetsCommitPopup',
    components: { Popup, Loader },
    setup () {
      const visible = ref(false)
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      const isCommitting = computed(() => store.getters['app/isCommittingGetsConfig'])
      const user = computed(() => store.state.user.user)

      const {
        result,
        onError
      } = useQuery<ConfigChangelogQuery>(QueryConfigChangelog, {}, () => ({
        pollInterval: 4000,
        enabled: visible.value,
        fetchPolicy: 'no-cache'
      }))
      onError(err => {
        if (err) {
          router.push({ name: 'sync' })
        }
      })

      const configStatus = computed(() => {
        if (result.value?.configChangelog) {
          return result.value.configChangelog
        }
        return {
          changelog: [],
          error: '',
          status: ConfiguratorStatus.Unknown,
          progress: 0,
        }
      })

      function navigate () {
        router.push({ name: 'sync' })
        visible.value = false
      }

      watch(() => isCommitting.value, commit => {
        if (!commit) {
          return visible.value = false
        }
        if (route.name === 'display' || route.name === 'sync') {
          return visible.value = false
        }
        // If no user is signed in the route should be considered unattended.
        if (Number(user.value?.id) === 0) {
          return visible.value = false
        }
        visible.value = true
      })

      return {
        visible,
        configStatus,
        navigate,
        ConfiguratorStatus,
      }
    }
  })
</script>

<style lang="stylus">
  .http-error-popup .loading-spinner
    margin 2rem 0 !important
</style>
