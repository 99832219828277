import { ref } from 'vue'

export enum CallType {
  Alert = 'alert',
  Presence = 'presence',
  Emergency = 'emergency',
}

// lastReset marks the time of the last demo reset.
// This can be used by child components to reset their own state.
const lastReset = ref(new Date())

export function useDemoState () {
  function reset () {
    lastReset.value = new Date()
  }

  return {
    lastReset,
    reset,
  }
}
