import { computed, markRaw } from 'vue'
import { RouteLocation, useRouter } from 'vue-router'
import { Store } from 'vuex'
import { State, useStore } from '@/store'

// afterEachHook is used to check if the user navigated to a route that has
// the isModal key set to true. If this is the case, the route is pushed
// to the modal stack that the component defined in this file displays.
export function afterEachHook (store: Store<State>) {
  return (to: RouteLocation, from: RouteLocation) => {
    const stack = computed(() => store.state.modalStack.stack)
    // If there is more than one open modal and the user tries to navigate
    // to the previously open modal, simply close the current modal to return
    // instead of opening the previous modal again.
    if (stack.value.length > 1) {
      const previous = stack.value[stack.value.length - 2]
      if (to.path == previous.path) {
        return store.commit('modalStack/close')
      }
    }
    // If the user comes from a modal route and navigates to
    // a non-modal route, close the stack.
    if (from.meta.isModal && !to.meta.isModal) {
      return store.commit('modalStack/close')
    }
    // Certain routes should never be nested in modals.
    const skipModalWhenNavigatingFromAndTo = [
      'alertChannelForm',
      'appManagementClientForm',
      'appManagementUserGroupForm',
    ]
    for (const route of skipModalWhenNavigatingFromAndTo) {
      if (to.name === route && from.name === route) {
        return
      }
    }
    // If the user navigates to a modal route, and it contains
    // a valid sub-route (matched), push the new route to the stack.
    if (to.meta.isModal && to.matched.length > 0) {
      // The last item in the array is the child route we care about.
      const route = to.matched[to.matched.length - 1]
      // Generate a unique key for the modal stack's v-for loop.
      const key = `${String(route.name)}-${JSON.stringify(to.params)}-${+Date.now()}`
      // Push the child route to the modal stack.
      const components = route?.components?.['default']
      if (components) {
        store.commit('modalStack/push', {
          component: markRaw(components),
          props: { ...to.params },
          path: to.path,
          key
        })
      }
    }
  }
}

// Returns true if the user clicked on the backdrop directly and not on a child element of it.
const isBackdropClick = (e: MouseEvent) => e.target instanceof Element && e.target.classList.contains('modal-stack__backdrop')

export function useModalStack () {
  const store = useStore()
  const router = useRouter()
  const close = async () => {
    router.back()

    // If the last item of the stack gets closed, close the modal.
    if (store.state.modalStack.stack.length === 1) {
      store.commit('modalStack/close')
    }
  }

  // onClick handles a click on the backdrop.
  const onClick = async (e: MouseEvent) => {
    if (!isBackdropClick(e)) {
      return
    }
    await close()
  }

  return {
    close,
    onClick,
    stack: store.state.modalStack.stack
  }
}
