<script setup lang="ts">
  import DisplayGroupScheduleSelector from '@/modules/admin/displaygroup/components/DisplayGroupScheduleSelector.vue'
  import { SystemParam } from '@/common/graphql/types'
  import { computed, PropType } from 'vue'
  import QueryRelations from '../graphql/QueryRelations.gql'
  import { useQuery } from '@vue/apollo-composable'
  import { DisplayGroupConfiguration, DisplayGroupOptions } from '@/modules/admin/displaygroup/DisplayGroup.api'
  import { FormDropdownOption } from '@/common/components/form/Forms.api'
  import { useI18n } from 'vue-i18n'

  const props = defineProps({
    system: {
      type: Object as PropType<SystemParam>,
      default: () => ({})
    },
    config: {
      type: Object as PropType<Readonly<DisplayGroupConfiguration>>,
      default: () => ({})
    }
  })

  const i18n = useI18n()

  const { result } = useQuery(
    QueryRelations,
    () => ({ system_id: props.system.id }),
    () => ({
      fetchPolicy: 'no-cache',
      context: { system_id: props.system.id }
    })
  )

  const schedules = computed(() => result.value?.schedules ?? [])
  const displayGroups = computed(() => result.value?.displayGroups ?? [])
  
  const optionsConfig: DisplayGroupOptions = {
    empty_option_name: 'displaygroup.none',
    schedule_options_hook: (options: FormDropdownOption[]) => {
      options.unshift({
        value: null,
        label: '-- ' + i18n.t('displaygroup.all')
      })
    }
  }
</script>

<template>
  <DisplayGroupScheduleSelector
    :modelValue="config"
    :schedules="schedules"
    :displayGroups="displayGroups"
    :optionsConfig="optionsConfig"
    :condensed="true"
  />
</template>