<script setup lang="ts">
  import { PropType } from 'vue'
  import { Maybe } from '@/common/graphql/types'

  const emit = defineEmits(['update:modelValue', 'edit'])
  const props = defineProps({
    times: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [Number, String] as PropType<Maybe<number | string>>,
      default: undefined
    },
    index: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    displayGroups: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showEditActions: {
      type: Boolean,
      default: false,
    }
  })

  function onClickAction () {
    emit('edit', props.modelValue ?? null)
  }
</script>

<template>
  <div class="flex items-center py-2">
    <div v-if="times && times.length === 2" class="flex-grow-0 flex-shrink-0 w-32 mr-12 text-sm">
      {{ times[0] }} &ndash; {{ times[1] }}
    </div>
    <div class="flex-1">
      <FormDropdown
        :name="`weekday_schedule_id_${index}`"
        :modelValue="modelValue"
        :loading="loading"
        :options="displayGroups"
        :disabled="disabled"
        @update:modelValue="$emit('update:modelValue', $event)"
      />
    </div>
    <div v-if="showEditActions" class="ml-2">
      <ActionButton
        type="default"
        :icon="modelValue !== null ? 'edit' : 'plus'"
        displayMode="icon"
        @click="onClickAction"
      />
    </div>
  </div>
</template>
