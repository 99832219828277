<script setup lang="ts">
  import { computed, PropType } from 'vue'
  import { Maybe } from '@/common/graphql/types'
  import { useStore } from '@/store'

  const props = defineProps({
    percentage: {
      type: Number as PropType<Maybe<number>>,
      default: null
    }
  })

  const radius = 7

  const store = useStore()

  const displayValue = computed(() => {
    if (props.percentage === null || props.percentage === -1) {
      return '?'
    }
    switch (true) {
    case props.percentage >= store.state.app.settings.battery_threshold_low:
      return 'OK'
    default:
      return 'LOW'
    }
  })

  const color = computed(() => {
    if (props.percentage === null || props.percentage === -1) {
      return 'text-gray-600'
    }
    switch (true) {
    case props.percentage >= store.state.app.settings.battery_threshold_low:
      return 'text-green-600'
    case props.percentage >= store.state.app.settings.battery_threshold_critical:
      return 'text-yellow-600'
    default:
      return 'text-red-600'
    }
  })
</script>

<template>
  <div class="flex items-center">
    <div class="flex-1 text-2xs mr-1" :class="color">
      <div v-if="displayValue === '?'">
        &#x1F559;
      </div>
      <div v-else>
        {{ displayValue }}
      </div>
    </div>
    <div class="flex-0 w-5">
      <svg>
        <circle
          cx="10"
          cy="10"
          :r="radius"
          stroke-dasharray="42"
          class="stroke-current text-gray-300"
        />
        <circle
          v-if="percentage !== null && percentage !== -1"
          cx="10"
          cy="10"
          :r="radius"
          stroke-dasharray="42"
          class="stroke-current"
          :class="color"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
svg
  position relative
  width 20px
  height 20px

svg circle
  width 100%
  height 100%
  fill none
  stroke-width 2px
  stroke-linecap round
</style>