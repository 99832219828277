import { config } from '@/bootstrap/config'
import { License } from '@/common/graphql/types'
import store from '@/store'
import * as Sentry from '@sentry/vue'
import { watch } from 'vue'
import { Vue } from '@sentry/vue/types/types'

export function setupSentry (app: Vue) {
  if (config.environment === 'development') {
    return
  }
  Sentry.init({
    app: app,
    dsn: 'https://61c046b3365542cfa44bedd6cab1d6a2@o61707.ingest.sentry.io/5378056',
    environment: config.environment,
    release: config.version,
    tracesSampleRate: 0,
    ignoreErrors: [
      /GraphQL error: validation failed/,
      /auth check/,
      /Failed to fetch dynamically imported module/,
      /Unable to preload CSS/,
      /attempting to fetch resource/,
      /Load failed/,
    ]
  })

  watch(() => store.state.license.license, setSentryContext)
}

export function setSentryContext (license: License) {
  Sentry.setUser({ id: license.id.toString(), username: license.name })
  Sentry.setContext('license', license)
  Sentry.setTag('CareSuite.Platform', "CareSuite.Frontend")
}
