<template>
  <svg viewBox="-30 0 512 512" class="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M452 108.898L343.102 0H120v80H0v432h332v-80h120zM351.89 51.215l48.895 48.894h-48.894zM30 482V110h171.89v100.11H302V482zm201.89-350.785l48.895 48.894h-48.894zM332 402V188.898L223.102 80H150V30h171.89v100.11H422V402zm0 0"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'IconCopy',
  })
</script>
