<template>
  <div class="text-center">
    <p class="font-semibold mb-4">
      {{ $t('setup.gets.label') }}
    </p>
    <p class="mx-auto max-w-md">
      {{ $t('setup.gets.subtitle') }}
    </p>
    <div v-if="state.configurator" class="p-4 bg-green-50 border border-green-200 rounded shadow-sm mt-8">
      <i18n-t keypath="setup.gets.status" tag="p" class="leading-normal">
        <strong>{{ state.configurator }}</strong>
      </i18n-t>
    </div>
    <div v-else class="mt-8">
      <div class="flex -mx-2">
        <div class="w-4/5 px-2">
          <FormInput
            v-model="ip"
            name="ip"
            label="systemparam.fields.ip"
            :disabled="loading"
            :errors="errors"
          />
        </div>
        <div class="w-1/5 px-2">
          <ActionButton :loading="loading" type="primary" class="mt-6" @click.prevent="create">
            {{ $t('setup.gets.connect') }}
          </ActionButton>
        </div>
      </div>
      <div class="flex -mx-2 mt-4">
        <div class="w-1/2 px-2">
          <FormInput
            v-model="username"
            name="username"
            label="systemparam.fields.username"
            :disabled="loading"
            :errors="errors"
          />
        </div>
        <div class="w-1/2 px-2">
          <FormInput
            v-model="password"
            name="password"
            label="systemparam.fields.password"
            type="password"
            :disabled="loading"
            :errors="errors"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, reactive, ref, toRefs } from 'vue'
  import { useMutation } from '@vue/apollo-composable'
  import { transformErrors } from '@/common/components/form/Forms.api'
  import { CreateInitialConfiguratorMutation, CreateInitialConfiguratorMutationVariables } from '@/common/graphql/types'
  import { useStore } from '@/store'
  import { GraphQLError } from 'graphql'

  import MutationCreateInitialConfigurator from '../graphql/MutationCreateInitialConfigurator.gql'
  import { SetupState } from '@/modules/setup/Setup.api'

  export default defineComponent({
    name: 'Configurator',
    components: {},
    props: {
      state: {
        type: Object as PropType<SetupState>,
        default: () => ({})
      }
    },
    emits: ['setState'],
    setup (props, { emit }) {
      const store = useStore()
      const form = reactive({ ip: '', username: 'admin', password: '' })
      const {
        mutate,
        loading,
        onError
      } = useMutation<CreateInitialConfiguratorMutation, CreateInitialConfiguratorMutationVariables>(MutationCreateInitialConfigurator, {
        fetchPolicy: 'no-cache',
      })

      const errors = ref({})
      onError((response: any) => {
        let formErrors = {}
        response.graphQLErrors?.some((error: GraphQLError) => {
          if (error.hasOwnProperty('message') && error.message.indexOf('failed to fetch firmware version') > -1) {
            if (error.message.indexOf('Unauthorized') > -1) {
              formErrors = { password: [{ message: 'validation.connection_unauthorized' }] }
            } else {
              formErrors = { ip: [{ message: 'validation.connection_error' }] }
            }
            return false
          }
        })
        errors.value = { ...transformErrors(response.graphQLErrors), ...formErrors }
      })

      async function create () {
        const response = await mutate({
          ip: form.ip,
          username: form.username,
          password: form.password,
        })
        if (response?.data?.createInitialConfigurator) {
          emit('setState', ['configurator', response.data?.createInitialConfigurator.ip])
          store.commit('app/setConfiguratorIP', response.data?.createInitialConfigurator.ip)
          form.ip = ''
          form.username = ''
          form.password = ''
          errors.value = {}
        }
      }

      return {
        errors,
        create,
        loading,
        ...toRefs(form),
      }
    }
  })
</script>
