<template>
  <div class="box border border-gray-300 rounded bg-white shadow relative">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Box',
  })
</script>
