<template>
  <div
    v-if="hasUpdate"
    class="z-50 fixed bottom-0 left-0 w-full h-auto md:h-20 bg-yellow-100 text-yellow-900 flex items-center justify-center text-xs leading-tight shadow-t-md"
  >
    <div class="p-4 text-center">
      <span v-html="$t('common.update_notifier.text', { availableUpdate })" />
      {{ $t('common.update_notifier.countdown', seconds) }}
      <button
        class="border border-green-500 text-green-900 cursor-pointer px-2 py-1 rounded text-xs block mt-3 mx-auto md:inline md:mt-0 md:ml-4"
        @click="reload"
      >
        {{ $t('common.update_notifier.reload') }}
      </button>

      <button
        class="border border-yellow-500 cursor-pointer px-2 py-1 rounded text-xs block mt-3 mx-auto md:inline md:mt-0 md:ml-4"
        @click="cancel"
      >
        {{ $t('common.actions.cancel') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useStore } from '@/store'

  export default defineComponent({
    name: 'UpdateNotifier',
    setup () {
      const store = useStore()
      const seconds = ref(30)
      const availableUpdate = computed<string>(() => store.state.app.availableUpdate)
      const hasUpdate = ref(false)

      watch(() => availableUpdate.value, (newValue, oldValue) => {
        if (newValue !== oldValue && oldValue) {
          hasUpdate.value = true
        }
      })

      let reloadInterval: ReturnType<typeof setInterval>
      watch(() => hasUpdate.value, () => {
        if (!hasUpdate.value) {
          return clearInterval(reloadInterval)
        }
        reloadInterval = setInterval(() => {
          seconds.value--
          if (seconds.value === 0) {
            clearInterval(reloadInterval)
            document.location.reload()
          }
        }, 1000)
      })

      function reload () {
        document.location.reload()
      }

      function cancel () {
        clearInterval(reloadInterval)
        hasUpdate.value = false
        seconds.value = 30
      }

      return {
        availableUpdate,
        hasUpdate,
        seconds,
        cancel,
        reload,
      }
    }
  })
</script>
