<template>
  <div :title="$t(`systemtree.kinds.${kind}`)">
    <SystemTreeIconPerson v-if="kind === 'person'" v-bind="$attrs" />
    <SystemTreeIconFloor v-else-if="kind === 'floor'" v-bind="$attrs" />
    <SystemTreeIconSection v-else-if="kind === 'section'" v-bind="$attrs" />
    <SystemTreeIconRoom v-else-if="kind === 'room'" v-bind="$attrs" />
    <SystemTreeIconBuilding v-else-if="kind === 'building'" v-bind="$attrs" />
    <SystemTreeIconRadio v-else-if="kind === 'radio'" v-bind="$attrs" />
    <SystemTreeIconDevice v-else-if="kind === 'device'" v-bind="$attrs" />
    <SystemTreeIconTech v-else-if="kind === 'tech'" v-bind="$attrs" />
    <SystemTreeIconGroup v-else-if="kind === 'group'" v-bind="$attrs" />
    <SystemTreeIconUnknown v-else v-bind="$attrs" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import SystemTreeIconBuilding from '@/modules/device/components/systemtreeicon/SystemTreeIconBuilding.vue'
  import SystemTreeIconDevice from '@/modules/device/components/systemtreeicon/SystemTreeIconDevice.vue'
  import SystemTreeIconRoom from '@/modules/device/components/systemtreeicon/SystemTreeIconRoom.vue'
  import SystemTreeIconSection from '@/modules/device/components/systemtreeicon/SystemTreeIconSection.vue'
  import SystemTreeIconFloor from '@/modules/device/components/systemtreeicon/SystemTreeIconFloor.vue'
  import SystemTreeIconPerson from '@/modules/device/components/systemtreeicon/SystemTreeIconPerson.vue'
  import SystemTreeIconUnknown from '@/modules/device/components/systemtreeicon/SystemTreeIconUnknown.vue'
  import SystemTreeIconRadio from '@/modules/device/components/systemtreeicon/SystemTreeIconRadio.vue'
  import SystemTreeIconGroup from '@/modules/device/components/systemtreeicon/SystemTreeIconGroup.vue'
  import SystemTreeIconTech from '@/modules/device/components/systemtreeicon/SystemTreeIconTech.vue'

  export default defineComponent({
    name: 'SystemTreeIcon',
    components: {
      SystemTreeIconDevice,
      SystemTreeIconBuilding,
      SystemTreeIconPerson,
      SystemTreeIconSection,
      SystemTreeIconFloor,
      SystemTreeIconUnknown,
      SystemTreeIconRadio,
      SystemTreeIconGroup,
      SystemTreeIconTech,
      SystemTreeIconRoom,
    },
    props: {
      kind: {
        type: String,
        required: true,
      }
    },
  })
</script>
