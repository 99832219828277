<script setup lang="ts">
  import { computed, onMounted, PropType, ref, watch } from 'vue'
  import type { Call, CallDisplayRelationsQuery, DisplayGroup, SystemParam } from '@/common/graphql/types'
  import { CallTypeFlag } from '@/common/graphql/types'
  import { format, timeToInt, useTime } from '@/common/services/time'
  import { useQuery, useSubscription } from '@vue/apollo-composable'
  import QueryOpenCalls from '@/modules/display/graphql/QueryOpenCalls.gql'
  import CallsChangedSubscription from '@/modules/display/graphql/SubscriptionCallsChanged.gql'
  import QueryDisplayRelations from '@/modules/display/graphql/QueryRelations.gql'
  import { logError } from '@/common/utils'
  import { useStore } from '@/store'
  import type { DisplayParams } from '@/modules/display/composables/Calls.api'
  import { DisplayConfigFromURLParams } from '@/modules/display/composables/Calls.api'
  import { useDisplayGroupFilterCallTypeMap } from '@/modules/admin/displaygroup/DisplayGroup.api'

  const emit = defineEmits(['loading', 'error'])
  const props = defineProps({
    system: {
      type: Object as PropType<SystemParam>,
      default: () => ({})
    },
    settings: {
      type: Object as PropType<DisplayParams>,
      default: () => ({})
    }
  })

  const time = useTime()
  const store = useStore()
  const allCalls = ref<Call[]>([])

  const {
    result: relationsResult,
    onError: onRelationsError,
    refetch: refetchRelations,
    loading: relationsLoading,
  } = useQuery<CallDisplayRelationsQuery>(
    QueryDisplayRelations,
    () => ({ system_id: props.system.id }),
    () => ({
      fetchPolicy: 'no-cache',
      context: { system_id: props.system.id }
    })
  )
  onRelationsError(err => {
    logError('failed to fetch call display relations', err)
    emit('error', err)
  })

  const schedules = computed(() => relationsResult.value?.schedules ?? [])
  const displayGroups = computed(() => (relationsResult.value?.displayGroups ?? []) as Partial<DisplayGroup>[])

  const lastDisplayConfigUpdate = computed(() => store.state.app.lastDisplayConfigUpdate)

  onMounted(() => {
    emit('loading', true)
  })

  watch(() => lastDisplayConfigUpdate.value, async () => {
    emit('loading', true)
    await refetchRelations()
  })

  const currentDisplayGroupConfig = computed(() => {
    // If URL params are present, use these.
    if (props.settings.displayGroupConfig.hasOwnProperty(DisplayConfigFromURLParams)) {
      return props.settings.displayGroupConfig[DisplayConfigFromURLParams]
    }

    return props.settings.displayGroupConfig[Number(props.system.id)] ?? {}
  })

  const {
    useDisplayGroupFilter,
    activeDisplayGroupSectionCallTypeMap
  } = useDisplayGroupFilterCallTypeMap(currentDisplayGroupConfig, schedules, displayGroups, {
    start: props.system.weekend_start,
    stop: props.system.weekend_stop,
  })

  const {
    onResult: onOpenCallsResult,
    onError: onOpenCallsError,
    loading: openCallsLoading,
  } = useQuery(
    QueryOpenCalls,
    () => ({
      system_id: props.system.id,
    }),
    () => ({
      fetchPolicy: 'no-cache',
      context: { system_id: props.system.id }
    })
  )
  onOpenCallsResult(result => {
    if (!result || result.loading) {
      return
    }
    const openCalls = result.data?.openCalls
    if (openCalls?.length > 0) {
      allCalls.value = [...openCalls]
    }
  })
  onOpenCallsError(err => {
    logError('failed to fetch open calls', err)
    emit('error', err)
  })

  const { result } = useSubscription(
    CallsChangedSubscription,
    () => ({
      override_system: props.system.id,
    }),
    () => ({
      fetchPolicy: 'no-cache',
    })
  )
  watch(result, data => {
    allCalls.value = data.callsChanged
  })

  // Check if section filters should be ignored at this time.
  const isIgnoredSectionTime = computed(() => {
    if (!props.settings.ignoreSectionsTimeStart || !props.settings.ignoreSectionsTimeEnd) {
      return false
    }
    const intStart = timeToInt(props.settings.ignoreSectionsTimeStart)
    const intEnd = timeToInt(props.settings.ignoreSectionsTimeEnd)
    const now = timeToInt(format(time.value.now, 'HH:mm'))

    // Overlaps midnight.
    if (intStart > intEnd) {
      return now >= intStart || now <= intEnd
    }

    return now >= intStart && now <= intEnd
  })

  const callsOnly = computed(() => allCalls.value.filter(c => {
    // If no display group is set, filter out defects by default. We only show them if explicitly included in the display group.
    let filterOutDefect = false
    if (!useDisplayGroupFilter.value && c?.call_type?.flag === CallTypeFlag.Defect) {
      filterOutDefect = true
    }
    return !filterOutDefect && c?.call_type?.flag !== CallTypeFlag.Presence
  }))

  const presencesOnly = computed(() => allCalls.value.filter(c => c?.call_type?.flag === CallTypeFlag.Presence))

  const applySectionsFilter = computed(() => props.settings.sections && props.settings.sections.length > 0 && !isIgnoredSectionTime.value)

  const calls = computed(() => {
    if (useDisplayGroupFilter.value) {
      return callsOnly.value.filter(call => activeDisplayGroupSectionCallTypeMap.value[call.section_id]?.includes(call.call_type_id))
    }

    if (applySectionsFilter.value) {
      return callsOnly.value.filter(
        call => props.settings.sections?.includes(call.section_id) || props.settings.ignoreSectionsFilterForCallTypes?.includes(call.call_type_id)
      )
    }

    return callsOnly.value
  })

  const presences = computed(() => {
    if (useDisplayGroupFilter.value) {
      return presencesOnly.value.filter(call => activeDisplayGroupSectionCallTypeMap.value[call.section_id]?.includes(call.call_type_id))
    }

    if (applySectionsFilter.value) {
      return presencesOnly.value.filter(
        call => props.settings.sections?.includes(call.section_id) || props.settings.ignoreSectionsFilterForCallTypes?.includes(call.call_type_id)
      )
    }

    return presencesOnly.value
  })

  const anyLoading = computed(() => openCallsLoading.value || relationsLoading)
  watch(anyLoading, (loading) => {
    emit('loading', loading)
  })

  watch([calls, presences], () => {
    store.commit('call/setCallData', {
      system: props.system,
      calls: calls.value,
      presences: presences.value
    })
  })
</script>

<template>
  <slot />
</template>