import type { License } from '@/common/graphql/types'
import { LicenseFeatures } from '@/common/graphql/types'
import storage from '@/bootstrap/storage'
import { State } from '@/store/index'

export interface LicenseState {
  license: License;
}

let initState = {
  id: -1,
  name: '',
  features: {
    analytics: true,
    log: true,
    management: true,
  },
  limits: {
    devices: 0,
    visuals: 0,
    app_clients: 0,
    systems: 1,
  }
}

const cachedLicense = storage.getJSON<License>('license')
if (cachedLicense) {
  initState = cachedLicense
}

const state: LicenseState = {
  license: initState
}

const getters = {
  isLicensed (state: LicenseState) {
    const checkFeature = (feature: keyof LicenseFeatures | 'app') => {
      if (feature === 'app') {
        // The app module is licensed if the license has app clients.
        return state.license.limits.app_clients !== 0
      }
      return state.license.features.hasOwnProperty(feature) ? state.license.features[feature] : false
    }
    return (features: keyof LicenseFeatures | (keyof LicenseFeatures)[]) => {
      return Array.isArray(features) ? features.every(feature => checkFeature(feature)) : checkFeature(features)
    }
  },
  // The app gateway is available if the license has app clients, the app gateway is enabled, and the current system context is ID 1.
  appGatewayAvailable (state: LicenseState, getters: any, rootState: State) {
    return state.license.limits.app_clients !== 0 && rootState.app.appGatewayEnabled && Number(rootState.app.systemContext) == rootState.app.appGatewaySystemId
  }
}

const actions = {}

const mutations = {
  set (state: LicenseState, license: License) {
    state.license = license
    storage.setJSON('license', license)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}