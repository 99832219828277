<script setup lang="ts">
  import CSLogo from '@/common/components/svg/CSLogo.vue'
  import { CheckIcon, CogIcon, DeviceMobileIcon, OfficeBuildingIcon, PhotographIcon, RefreshIcon } from '@heroicons/vue/outline'
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import NavigationButton from '@/modules/demo/components/NavigationButton.vue'
  import { computed, onMounted, ref } from 'vue'

  import { useElementSize, useWindowSize } from '@vueuse/core'
  import AppClient from '@/modules/demo/components/AppClient.vue'
  import GetsButtons from '@/modules/demo/components/GetsButtons.vue'
  import { CallType, useDemoState } from '@/modules/demo/demo.state'
  import GetsMedaillon from '@/modules/demo/components/GetsMedaillon.vue'
  import AppClientLabel from '@/modules/demo/components/AppClientLabel.vue'
  import Loader from '@/common/components/loader/Loader.vue'
  import { config } from '@/bootstrap/config'
  import { useQuery } from '@vue/apollo-composable'
  import QuerySystemTreeNode from '@/modules/device/graphql/systemtreenode/QueryOne.gql'
  import { SystemTreeNodeQuery } from '@/common/graphql/types'

  enum View {
    Home = 'home',
    App = 'app',
    Visuals = 'visuals'
  }

  const view = ref<View>(View.App)
  const controls = ref(null)
  const preview = ref(null)
  const deviceSize = ref({ width: 0, height: 0 })
  const loadingOverlay = ref(false)
  const isLoggedIn = ref(false)

  const { reset } = useDemoState()

  const { width: windowWidth } = useWindowSize()
  const { width: controlsWidth } = useElementSize(controls)
  const { height: previewHeight } = useElementSize(preview)

  const deviceTransform = computed(() => {
    const numberOfDevices = 3
    const devicePadding = 20
    const verticalPadding = 150

    const fullWidth = 400

    const relativeWidth = (windowWidth.value - controlsWidth.value - ((numberOfDevices - 1) * devicePadding)) / numberOfDevices

    // Resize the width by default.
    let factor = Math.min(relativeWidth / fullWidth, 1)

    // Resize the device if it is becoming too high.
    if (deviceSize.value.height >= (previewHeight.value - verticalPadding)) {
      factor = Math.min(previewHeight.value / (deviceSize.value.height + verticalPadding), 1)
    }

    return `scale(${factor})`
  })

  const { result: resultZ101 } = useQuery<SystemTreeNodeQuery>(QuerySystemTreeNode, { id: 23 }, () => ({
    pollInterval: 8000,
    enabled: isLoggedIn.value && !loadingOverlay.value,
  }))
  const { result: resultZ201 } = useQuery<SystemTreeNodeQuery>(QuerySystemTreeNode, { id: 24 }, () => ({
    pollInterval: 8000,
    enabled: isLoggedIn.value && !loadingOverlay.value,
  }))
  const { result: resultMed } = useQuery<SystemTreeNodeQuery>(QuerySystemTreeNode, { id: 10 }, () => ({
    pollInterval: 8000,
    enabled: isLoggedIn.value && !loadingOverlay.value,
  }))

  const z101 = computed(() => resultZ101.value?.systemTreeNode)
  const z201 = computed(() => resultZ201.value?.systemTreeNode)
  const med = computed(() => resultMed.value?.systemTreeNode)

  const callTypes = {
    [CallType.Alert]: 2,
    [CallType.Presence]: 4,
    [CallType.Emergency]: 3,
  }

  async function login () {
    isLoggedIn.value = false
    const response = await fetch(`${config.backend}/login`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        username: 'admin',
        password: 'admin',
      }),
    })
    if (response.ok) {
      isLoggedIn.value = true
    }
  }

  onMounted(async () => {
    await login()
  })

  function request (url: string, body: Record<string, any> = {}) {
    return fetch(`${config.backend}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    })
  }

  async function triggerCall (section: number | null | undefined, device: number | null | undefined, args: {
    type: CallType,
    close?: boolean
  }) {
    if (!section || !device) {
      return
    }

    const payload = {
      call_type_id: callTypes[args.type],
      section_id: section,
      device_id: device,
      close: args.close,
    }

    return await request('/demo/call/trigger', payload)
  }

  async function closeCalls () {
    reset()
    return await request('/demo/call/close')
  }

  async function resetDemo () {
    if (!confirm('Sind Sie sicher, dass Sie die Demo zurücksetzen möchten?')) {
      return
    }

    loadingOverlay.value = true

    await request('/demo/reset')
    await login()

    document.location.reload()
  }

  function openCareSuite () {
    window.open('/')
  }
</script>

<template>
  <div class="min-h-screen max-h-screen flex flex-col">
    <div v-if="loadingOverlay" class="fixed inset-0 bg-white/90 backdrop-blur-[2px] z-50 flex items-center justify-center">
      <Loader text="Setze Demo zurück. Dieser Vorgang dauert einen Moment..." :size="48" />
    </div>
    <header class="p-4 border-b border-gray-300 flex justify-between items-center">
      <CSLogo classs="w-5" />
      <div class="relative">
        <Menu>
          <MenuButton>
            <CogIcon class="w-8 text-gray-600" />
          </MenuButton>
          <MenuItems class="absolute bg-white border rounded shadow min-w-[200px] right-0">
            <MenuItem v-slot="{ active, close }">
              <a
                class="cursor-pointer text-sm flex items-center px-4 py-2"
                :class="{'bg-gray-100': active }"
                @click.prevent="closeCalls(); close()"
              >
                <CheckIcon class="w-4 mr-4" />
                Alle Rufe schliessen
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active, close }">
              <a
                class="cursor-pointer text-sm flex items-center px-4 py-2"
                :class="{'bg-gray-100': active }"
                @click.prevent="resetDemo(); close()"
              >
                <RefreshIcon class="w-4 mr-4" />
                Demo zurücksetzen
              </a>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </header>
    <div class="bg-gray-100 h-40 p-4 flex items-center justify-center">
      <div class="flex space-x-4">
        <NavigationButton :external="true" text="Verwaltung" @click="openCareSuite">
          <template #icon>
            <OfficeBuildingIcon />
          </template>
        </NavigationButton>
        <NavigationButton :active="view === 'app'" text="App" @click="view = View.App">
          <template #icon>
            <DeviceMobileIcon />
          </template>
        </NavigationButton>
        <NavigationButton :active="view === 'visuals'" text="Visuals" @click="view = View.Visuals">
          <template #icon>
            <PhotographIcon />
          </template>
        </NavigationButton>
      </div>
    </div>
    <div class="flex-1 flex border-t-2 min-h-0 border-gray-300 bg-white">
      <div ref="controls" class="w-52 flex-shrink-0 border-r-2 border-gray-300 overflow-y-auto">
        <div class="m-4 mb-2 inline-flex bg-white text-xs font-bold border-2 border-gray-200 rounded px-3 py-1">
          1. OG
        </div>
        <div class="p-4 flex flex-col gap-10">
          <GetsButtons
            v-if="z101"
            :label="z101.name"
            @change="triggerCall(z101.section_id, z101.number, $event)"
          />
          <GetsMedaillon
            v-if="med"
            :label="med.name"
            @change="triggerCall(med.section_id, med.number, $event)"
          />
        </div>
        <div class="border-t mt-2">
          <div class="m-4 mb-2 inline-flex bg-white text-xs font-bold border-2 border-gray-200 rounded px-3 py-1">
            2. OG
          </div>
          <div class="p-4 flex flex-col gap-10">
            <GetsButtons
              v-if="z201"
              :label="z201.name"
              @change="triggerCall(z201.section_id, z201.number, $event)"
            />
          </div>
        </div>
      </div>
      <section class="flex w-full min-w-0 overflow-hidden">
        <iframe v-show="view === View.Visuals" class="w-full" src="/display?fullscreen=1" />
        <div v-show="view === View.App" ref="preview" class="w-full divide-x-2 divide-dashed flex justify-between justify-self-stretch">
          <div class="relative flex justify-between w-1/3 flex-shrink-0">
            <AppClientLabel name="1. OG" label="Petra" />
            <AppClient username="Petra" :transform="deviceTransform" name="Pflege 2" @resize="deviceSize = $event" />
          </div>
          <div class="relative flex justify-between w-1/3 flex-shrink-0">
            <AppClientLabel name="2. OG" label="Max" />
            <AppClient username="Max" :transform="deviceTransform" name="Pflege 3" />
          </div>
          <div class="relative flex justify-between w-1/3 flex-shrink-0">
            <AppClientLabel name="Springer:in" label="Julia" />
            <AppClient username="Julia" :transform="deviceTransform" name="Pflege 1" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
  #demo {
    min-height: 100vh;
  }

  #demo .loading-spinner__text {
    @apply text-base text-gray-600 mt-8;
  }
</style>